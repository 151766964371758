
import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";

export const payrollDetailfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

    const formFields = [
        {
            label: 'Employee',
            key: 'employee_id',
            type: 'select',
            placeholder: 'Select the employee',
            visible: true,
            width: '25%',
            options: response.employees || [],
        },
        {
            label: 'Net Pay',
            key: 'net_pay',
            type: 'number',
            placeholder: 'Enter the net pay',
            visible: true,
            width: '25%',
        },
        {
            label: 'Paid Days',
            key: 'paid_days',
            type: 'number',
            placeholder: 'Enter the paid days',
            visible: true,
            width: '25%',
        },
        {
            label: 'LOP Days',
            key: 'lop_days',
            type: 'number',
            placeholder: 'Enter the LOP days',
            visible: true,
            width: '25%',
        },
        {
            label: 'Employee',
            key: 'employee_id',
            type: 'select',
            placeholder: 'Select the employee',
            visible: true,
            width: '25%',
            options: response.employees || [],
        },
        {
            label: 'Net Pay',
            key: 'net_pay',
            type: 'number',
            placeholder: 'Enter the net pay',
            visible: true,
            width: '25%',
        },
        {
            label: 'Paid Days',
            key: 'paid_days',
            type: 'number',
            placeholder: 'Enter the paid days',
            visible: true,
            width: '25%',
        },
        {
            label: 'LOP Days',
            key: 'lop_days',
            type: 'number',
            placeholder: 'Enter the LOP days',
            visible: true,
            width: '25%',
        },
        {
            label: 'Basic Earnings',
            key: 'basic_earnings',
            type: 'number',
            placeholder: 'Enter the basic earnings',
            visible: true,
            width: '25%',
        },
        {
            label: 'HRA Earnings',
            key: 'hra_earnings',
            type: 'number',
            placeholder: 'Enter the HRA earnings',
            visible: true,
            width: '25%',
        },
        {
            label: 'DA Earnings',
            key: 'da_earnings',
            type: 'number',
            placeholder: 'Enter the DA earnings',
            visible: true,
            width: '25%',
        },
        {
            label: 'Car Perquisites',
            key: 'car_perquisites',
            type: 'number',
            placeholder: 'Enter the car perquisites',
            visible: true,
            width: '25%',
        },
        {
            label: 'Medical Perquisites',
            type: 'number',
            placeholder: 'Enter the medical perquisites',
            visible: true,
            width: '25%',
        },
        {
            label: 'Telephone Perquisites',
            type: 'number',
            placeholder: 'Enter the telephone perquisites',
            visible: true,
            width: '25%',
        },
        {
            label: 'Allowances',
            type: 'array',
            visible: true,
            width: '25%',
            fields: [
                { label: 'Name', type: 'text', field: 'name' },
                { label: 'Amount', type: 'number', field: 'amount' },
            ],
        },
        {
            label: 'Deductions',
            type: 'array',
            visible: true,
            width: '25%',
            fields: [
                { label: 'Name', type: 'text', field: 'name' },
                { label: 'Amount', type: 'number', field: 'amount' },
            ],
        },
        {
            label: 'Other Sources of Income',
            type: 'array',
            visible: true,
            width: '25%',
            fields: [
                { label: 'Name', type: 'text', field: 'name' },
                { label: 'Amount', type: 'number', field: 'amount' },
            ],
        },
        {
            label: 'Total Earnings',
            type: 'number',
            placeholder: 'Enter the total earnings',
            visible: true,
            width: '25%',
        },
        {
            label: 'Total Deductions',
            type: 'number',
            placeholder: 'Enter the total deductions',
            visible: true,
            width: '25%',
        },
        {
            label: 'Total Tax',
            type: 'number',
            placeholder: 'Enter the total tax',
            visible: true,
            width: '25%',
        },

    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                viewField: true,
            });
        });
    }

    return formFields;
}

export const payrollDetailMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const payrollDetailMain = {
    fields: payrollDetailfields,
    mobileCard: null
}