import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";

export const payrollSalaryItemfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

  const formFields = [
    {
      label: 'Name',
      key: 'name',
      type: 'text',
      placeholder: 'Enter the name',
      visible: true,
      width: '25%',
    },
    {
      label: 'Type',
      key: 'type',
      type: 'select',
      placeholder: 'Select the type',
      visible: true,
      width: '25%',
      options: [
        { label: 'Earnings', value: 'Earnings' },
        { label: 'Deductions', value: 'Deductions' },
      ],
      onChange: (props) => {
        props.setObj({
          updateVisiblity: Math.random(),
        })
      }
    },
    {
      label: 'Calculation Type',
      key: 'calculation_type',
      type: 'select',
      placeholder: 'Select the type',
      visible: (obj) => obj.type == 'Earnings',
      width: '25%',
      options: [
        { label: 'Fixed', value: 'Fixed' },
        { label: 'Percentage', value: 'Percentage' },
      ],
      value: 'Fixed',
    },
    {
      label: 'Calculation Value',
      key: 'calculation_value',
      type: 'number',
      placeholder: 'Enter the calculation value',
      visible: (obj) => obj.type == 'Earnings',
      width: '25%',
      value: 0,
    },

    {
      label: 'Post Tax',
      key: 'post_tax',
      type: 'checkbox',
      placeholder: 'Select if pre-tax',
      visible: (obj) => obj.type == 'Deductions',
      width: '25%',
      value: false,
    },

    {
      label: 'Part of Income Tax',
      key: 'part_of_income_tax',
      type: 'checkbox',
      placeholder: 'Select if part of income tax',
      visible: (obj) => obj.type == 'Earnings',
      width: '25%',
      value: true,
    },
    {
      label: 'Part of EPF',
      key: 'part_of_epf',
      type: 'checkbox',
      placeholder: 'Select if part of EPF',
      visible: (obj) => obj.type == 'Earnings',
      width: '25%',
      value: true,
    },
    {
      label: 'Part of ESI',
      key: 'part_of_esi',
      type: 'checkbox',
      placeholder: 'Select if part of ESI',
      visible: (obj) => obj.type == 'Earnings',
      width: '25%',
      value: true,
    },
    {
      label: 'Part of PT',
      key: 'part_of_pt',
      type: 'checkbox',
      placeholder: 'Select if part of PT',
      visible: (obj) => obj.type == 'Earnings',
      width: '25%',
      value: true,
    }
  ];

  return formFields;
}

export const payrollSalaryItemMobileCard = (item, index, getFieldValue) => {
  return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
    <View>
      <Text>TEST</Text>
      <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
    </View>
  </Card>;
}

export const payrollSalaryItemMain = {
  fields: payrollSalaryItemfields,
  mobileCard: null
}

