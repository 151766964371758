import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";

export const expensefields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
    const formFields = [
        {
            label: 'Date',
            key: 'date',
            type: 'date',
            placeholder: 'Enter the date',
            visible: true,
            width: '25%',
            value: new Date()
        },
        {
            label: 'Paid From',
            key: 'cash_account_id',
            type: 'select',
            placeholder: 'Select the Paid From',
            visible: true,
            width: '25%',
            options: response.cashAccounts || [],
            value: response.cashAccounts?.find((el) => el.name.includes("Cash"))?._id
        },
        {
            label: 'Expense Type',
            key: 'account_id',
            type: 'select',
            placeholder: 'Select the account',
            visible: true,
            width: '25%',
            options: response.accounts || [],
            autoFocus: true,
        },
        {
            label: 'Amount',
            key: 'amount',
            type: 'number',
            placeholder: 'Enter the amount',
            visible: true,
            width: '25%',
        },
        {
            label: 'Narration',
            key: 'narration',
            type: 'text',
            placeholder: 'Enter the narration',
            visible: false,
            width: '25%',
            value: "Expense Recorded"
        },
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                viewField: true,
            });
        });
    }

    return formFields;
}

export const expenseMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const expenseMain = {
    fields: expensefields,
    mobileCard: null
}
