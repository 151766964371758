import React, { useEffect, useState } from "react";
import { View, Card, Text } from "react-native-ui-lib";
import { CustomSelectItemForContacts } from "./transaction";
import { api } from "../../services/api";
import { create } from 'zustand'
import DynamicTable from "../../mycomponents/DynamicTable";


export const contactPaymentFields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

    const transactionsColumns = [
        {
            "field": "date",
            "accessor": "date",
            "label": "Date",
            "Header": "Date",
            "type": "date",
            "editable": false,
            "visible": true,
        }, {
            "field": "number",
            "accessor": "number",
            "label": "Voucher No",
            "Header": "Voucher No",
            "editable": false,
            "visible": true,
            "type": "moduleLink",
        }, {
            "field": "balance",
            "accessor": "balance",
            "label": "Old Balance",
            "Header": "Old Balance",
            "type": "number",
            "editable": false,
            "visible": true,
        }, {
            "field": "amount",
            "accessor": "amount",
            "label": "Adjusted Amount",
            "Header": "Adjusted Amount",
            "type": "number",
            "editable": true,
            "visible": true,
            "onChange": ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                const total_amount = getObj().total_amount || 0;
                let new_total = 0;
                const transactions = [...(getObj().transactions ?? [])];

                for (let i = 0; i < transactions.length; i++) {
                    if (transactions[i].amount > 0) {
                        new_total += transactions[i].amount;
                    }
                }

                if (new_total > total_amount) {
                    setObj({
                        total_amount: new_total
                    });
                } else if (!total_amount) {
                    setObj({
                        total_amount: new_total
                    });
                }
            }
        }, {
            "field": "payment_discount_amount",
            "accessor": "payment_discount_amount",
            "label": "Discount Amount",
            "Header": "Discount Amount",
            "type": "number",
            "editable": true,
            "visible": true,
        }
    ];

    const formFields = [
        {
            label: moduleName == "PaymentMade" ? 'Record an expense not linked to a contact?' : 'Record an income not linked to a contact?',
            key: 'income_expense_record',
            type: 'checkbox',
            visible: true,
            width: '100%',
            value: false,
            onChange: ({ value, tKey, tIndex, getObj, setObj, getArrValue, setArrValue }) => {
                setObj({
                    account_id: null,
                    updateVisiblity: Math.random(),
                })
            }
        },
        {
            label: 'Date',
            key: 'date',
            type: 'date',
            placeholder: 'Enter the date',
            visible: response.ModulePrefs.hide_date_field == true ? false : true,
            width: '25%',
            value: new Date(),
        }, {
            label: 'Voucher No',
            key: 'number',
            type: 'text',
            placeholder: 'Enter the voucher no',
            visible: response.ModulePrefs.hide_voucher_no_field == true ? false : true,
            width: '25%',
            value: 'Auto-Generated'
        },
        {
            label: 'Contact',
            key: 'contact_id',
            type: 'select',
            placeholder: 'Select the contact',
            visible: (connectedObject) => (!connectedObject.income_expense_record == true),
            width: '25%',
            options: response.Contacts || [],
            creatableAction: (props) => {
                const params = {
                    ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? {
                        is_default_values: true, phone: props.search,
                        is_supplier: moduleName == "PaymentReceived" ? true : false,
                        is_customer: moduleName == "PaymentMade" ? true : false
                    } : {})
                };
                OpenModalCustom({ newModule: "Contacts", field_key: 'contact_id', params });
            },
            customSelectItem: CustomSelectItemForContacts,
            otherSearchKeys: ['phone', 'email'],
        },
        {
            label: 'Expense Account',
            key: 'account_id',
            type: 'select',
            placeholder: 'Select',
            visible: (connectedObject) => (connectedObject.income_expense_record == true) && moduleName == "PaymentMade",
            width: '25%',
            options: response.ExpenseAccounts || [],
        },
        {
            label: 'Income Account',
            key: 'account_id',
            type: 'select',
            placeholder: 'Select',
            visible: (connectedObject) => (connectedObject.income_expense_record == true) && moduleName == "PaymentReceived",
            width: '25%',
            options: response.IncomeAccounts || [],
        },
        {
            label: moduleName == "PaymentReceived" ? 'Paid To' : 'Paid From',
            key: 'cash_id',
            type: 'select',
            placeholder: 'Select',
            visible: true,
            width: '25%',
            options: response.CashAccounts || [],
            value: globalData?.employee_id ? response.CashAccounts.find((account) => account.value == globalData.employee_id) ? globalData.employee_id : response.CashAccounts[0].value : response.CashAccounts[0].value,
        },
        {
            label: 'Location',
            key: 'location_id',
            type: 'select',
            placeholder: 'Select the location',
            visible: response.ModulePrefs.hide_location_field == true ? false : true,
            width: '25%',
            options: response.Locations || [],
            value: globalData?.location_id ? response.Locations.find((location) => location._id == globalData.location_id) ? globalData.location_id : response.Locations[0].value : response.Locations[0].value,
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                setGlobalData({
                    location_id: value
                });
            }
        },
        {
            label: 'Amount',
            key: 'total_amount',
            type: 'number',
            placeholder: 'Enter the amount',
            visible: true,
            width: '25%',
            onChange: ({ value, tKey, tIndex, getObj, setObj, getArrValue, setArrValue }) => {
                let transactions = [...(getObj().transactions ?? [])];
                let amount = value;
                let sortedTransactions = [...transactions]

                // sortedTransactions.sort((a, b) => {
                //     return new Date(a.date).getTime() - new Date(b.date).getTime();
                // });

                sortedTransactions.forEach((transaction, index) => {
                    if (amount > 0) {
                        if (amount >= transaction.balance) {
                            amount = amount - transaction.balance;
                            transaction.amount = transaction.balance;
                        } else {
                            transaction.amount = amount;
                            amount = 0;
                        }
                    } else {
                        transaction.amount = 0;
                    }
                });

                setObj({
                    transactions: sortedTransactions
                });
            }
        },
        {
            label: '',
            key: 'transactions',
            type: 'table',
            editable: true,
            placeholder: '',
            visible: (connectedObject) => !(connectedObject.income_expense_record == true),
            width: '100%',
            columns: transactionsColumns
        },

        {
            type: 'custom',
            visible: true,
            width: '100%',
            component: ({ field, useForm, tKey, tIndex }) => {
                const contact_id = useForm((state) => state.formObject['contact_id']);
                const contact_payment_id = useForm((state) => state.formObject['_id']);
                const adjusting_transaction = value?.adjusting_transaction;
                const setFormObject = useForm((state) => state.setFormObject);

                useEffect(() => {
                    const getTransactions = async () => {
                        console.log("contact_id", contact_id);
                        console.log("response.Contacts", response.Contacts);
                        const contact = response.Contacts.find((item) => item.value == contact_id);

                        const response2 = await api.customRoute(moduleName + '/action', {
                            action: 'getTransactions',
                            contact_id: contact_id,
                            contact_payment_id: contact_payment_id,
                        });

                        let transactions = response2;

                        if (adjusting_transaction) {
                            transactions.forEach((transaction) => {
                                if (transaction._id == adjusting_transaction._id) {
                                    transaction.amount = adjusting_transaction.amount;
                                }
                            });
                        }

                        if (view) {
                            transactions = transactions.filter((transaction) => {
                                return transaction.amount > 0 || transaction.payment_discount_amount > 0;
                            });
                        }

                        //sort first by amount and then by date
                        transactions.sort((a, b) => {
                            if (a.amount > b.amount) {
                                return -1;
                            } else if (a.amount < b.amount) {
                                return 1;
                            } else {
                                return new Date(a.date).getTime() - new Date(b.date).getTime();
                            }
                        });

                        setFormObject({
                            transactions: transactions,
                            account_id: contact?.account_id,
                        });
                    }
                    getTransactions();
                }, [contact_id]);
                return <></>;
            }
        },
        {
            type: 'custom',
            visible: true,
            width: '100%',
            component: ({ field, useForm, tKey, tIndex }) => {
                const cash_id = useForm((state) => state.formObject['cash_id']);
                const setFormObject = useForm((state) => state.setFormObject);

                useEffect(() => {
                    const account = response.CashAccounts.find((item) => item.value == cash_id);
                    if (!account) return;
                    setFormObject({
                        'cash_account_name': account.label,
                        'cash_account_id': account.account_id,
                        'employee_id': account.employee_id,
                    })
                }, [cash_id]);

                return <></>;
            }
        },
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                viewField: true,
            });
        });
    }

    return formFields;
}

const preferencesFields = (response, view = false, moduleName = null, OpenModalCustom) => {
    const formFields = [{
        label: 'Hide Date Field?',
        key: 'hide_date_field',
        type: 'checkbox',
        placeholder: 'Hide Due Date Field?',
        visible: true,
        width: '50%',
        value: true
    }, {
        label: 'Hide Voucher No Field?',
        key: 'hide_voucher_no_field',
        type: 'checkbox',
        placeholder: 'Hide Due Date Field?',
        visible: true,
        width: '50%',
        value: true
    }, {
        label: 'Hide Location Field?',
        key: 'hide_location_field',
        type: 'checkbox',
        placeholder: 'Hide Location Field?',
        visible: true,
        width: '50%',
        value: true
    }]

    return formFields;
}

export const contactPaymentMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const contactPaymentMain = {
    fields: contactPaymentFields,
    preferencesFields: preferencesFields,
    mobileCard: null
}
