import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";
import { addButton } from "../hrms/shiftModel";
// import { GrapesjsReact } from 'grapesjs-react';
// import 'grapesjs/dist/css/grapes.min.css';
// import 'grapesjs-preset-webpage';
// import webpageplugin from 'grapesjs-preset-webpage';
// import blocksplugin from 'grapesjs-blocks-basic';
import { Dimensions } from "react-native";

export const itemfields = (response, view = false, moduleName = null, OpenModalCustom) => {

    const batchNumbersColumns = [
        {
            "field": "batch_number",
            "label": "Batch Number",
            "editable": true,
            "type": "text",
            "visible": true,
            "order": 1
        },
        {
            "field": "expiry_date",
            "label": "Expiry Date",
            "editable": true,
            "type": "date",
            "visible": true,
            "order": 2
        }, {
            "field": "quantity",
            "label": "Quantity",
            "editable": true,
            "type": "number",
            "visible": true,
            "order": 3
        }
    ];

    batchNumbersColumns && batchNumbersColumns.map((column) => {
        column.Header = column.label;
        column.accessor = column.field;
    });

    const EditorComponent = (props) => {
        const { value, onChange } = props;

        const html = value || '<div class="txt-red">Hello World!</div>';

        return <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            {/* <GrapesjsReact
                id='grapesjs-react'
                width={Dimensions.get('window').width - 380 + 'px'}
                onInit={(editor) => {
                    editor.setComponents(html, { avoidInlineStyle: false });
                }}
                plugins={[
                    webpageplugin,
                    blocksplugin
                ]}
            /> */}
        </View>
    }

    const formFields = [
        {
            label: 'Product Type',
            key: 'product_type',
            type: 'select',
            placeholder: 'Select the product type',
            visible: true,
            width: '25%',
            options: [
                { label: 'Goods', value: 'Goods' },
                { label: 'Service', value: 'Service' },
            ],
            tab: 'General',
            viewField: true
        },
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter the name of the item',
            visible: true,
            width: '25%',
            tab: 'General',
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                const sku = value.replace(/[^a-zA-Z0-9-_]/g, '').toUpperCase();
                setObj({ sku: sku });
            }
        },
        {
            label: 'SKU',
            key: 'sku',
            type: 'text',
            placeholder: 'Enter the SKU of the item',
            visible: true,
            width: '25%',
            tab: 'General',
            onChange: (props) => {
                // if (props.value) {
                //     const valid = /^[a-zA-Z0-9-_]*$/.test(props.value);
                //     if (!valid) {
                //         const error = 'SKU can only contain alphanumeric characters, hyphens and underscores';
                //         const value = {
                //             error: error,
                //             value: props.value
                //         }
                //         props.updateField('sku', value, props.newForm)
                //     } else {
                //         const value = {
                //             error: false,
                //             value: props.value
                //         }
                //         props.updateField('sku', value, props.newForm)
                //     }
                // }
            }
        },
        {
            label: 'Description',
            key: 'description',
            type: 'text',
            placeholder: 'Enter the description of the item',
            visible: true,
            width: '25%',
            tab: 'General'
        },
        {
            label: 'HSN/SAC',
            key: 'hsn_or_sac',
            type: 'text',
            placeholder: 'Enter the HSN/SAC',
            visible: true,
            width: '25%',
            tab: 'General'
        },
        {
            label: 'Unit',
            key: 'unit_id',
            type: 'select',
            placeholder: 'Enter the unit of the item',
            visible: true,
            width: '25%',
            tab: 'General',
            options: response.units || [],
            creatableAction: () => {
                OpenModalCustom({ newModule: "Units", field_key: 'unit_id' })
            }
        },
        {
            label: 'Brand',
            key: 'brand_id',
            type: 'select',
            placeholder: 'Enter the brand of the item',
            visible: true,
            width: '25%',
            tab: 'General',
            options: response.brands || [],
            creatableAction: () => {
                OpenModalCustom({ newModule: "Brands", field_key: 'brand_id' })
            },
        },
        {
            label: 'Item Category',
            key: 'item_category_id',
            type: 'select',
            placeholder: 'Select the item category',
            visible: true,
            width: '25%',
            options: response.itemCategories || [],
            tab: 'General',
            creatableAction: () => {
                OpenModalCustom({ newModule: "Categories", field_key: 'item_category_id' })
            },
        },
        {
            label: 'Is Taxable',
            key: 'is_taxable',
            type: 'switch',
            placeholder: 'Enter the is taxable',
            visible: true,
            width: '25%',
            tab: 'General'
        },
        {
            label: 'Tax Exemption',
            key: 'tax_exemption_id',
            type: 'select',
            placeholder: 'Select the tax exemption',
            visible: (connectedObject) => connectedObject.is_taxable != true,
            width: '25%',
            options: response.taxExemptions || [],
            tab: 'General',
            creatableAction: () => {
                OpenModalCustom({ newModule: "TaxExemptions", field_key: 'tax_exemption_id' })
            }
        },
        {
            label: 'Tax Group',
            key: 'tax_group_id',
            type: 'select',
            placeholder: 'Select the tax group',
            visible: (connectedObject) => connectedObject.is_taxable == true,
            width: '25%',
            options: response.taxGroups || [],
            tab: 'General',
            creatableAction: () => {
                OpenModalCustom({ newModule: "TaxGroups", field_key: 'tax_group_id' })
            }
        },
        {
            label: 'Sales Rate',
            key: 'sales_rate',
            type: 'number',
            placeholder: 'Enter the sales rate',
            visible: true,
            width: '25%',
            tab: 'General'
        },
        {
            label: 'Sales Account',
            key: 'sales_account_id',
            type: 'select',
            placeholder: 'Select the sales account',
            visible: true,
            width: '25%',
            options: response.salesAccounts || [],
            tab: 'General',
            creatableAction: () => {
                const params = { "is_default_values": true, "type": "Sales", "base_type": "Income", "opening_balance": 0 };
                OpenModalCustom({ newModule: "Accounts", field_key: 'sales_account_id', params })
            }
        },
        {
            label: 'Purchase Rate',
            key: 'purchase_rate',
            type: 'number',
            placeholder: 'Enter the purchase rate',
            visible: true,
            width: '25%',
            tab: 'General'
        },
        {
            label: 'Purchase Account',
            key: 'purchase_account_id',
            type: 'select',
            placeholder: 'Select the purchase account',
            visible: true,
            width: '25%',
            options: response.purchasesAccounts || [],
            tab: 'General',
            creatableAction: () => {
                OpenModalCustom({ newModule: "Accounts", field_key: 'purchase_account_id' })
            }
        },
        {
            label: 'Track Inventory',
            key: 'track_inventory',
            type: 'switch',
            placeholder: 'Enter the track inventory',
            visible: true,
            width: '100%',
            tab: 'Inventory Details',
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                setObj({ updateVisiblity: Math.random() })
            }
        },
        {
            label: 'Inventory Account',
            key: 'inventory_account_id',
            type: 'select',
            placeholder: 'Select the inventory account',
            visible: (connectedObject) => connectedObject.track_inventory == true,
            width: '25%',
            options: response.inventoryAccounts || [],
            tab: 'Inventory Details',
            creatableAction: () => {
                OpenModalCustom({ newModule: "Accounts", field_key: 'inventory_account_id' })
            }
        },
        {
            label: 'Initial Stock',
            key: 'initial_stock',
            type: 'number',
            placeholder: 'Enter the initial stock',
            visible: (connectedObject) => connectedObject.track_inventory == true,
            width: '25%',
            tab: 'Inventory Details'
        },
        {
            label: 'Initial Stock Rate',
            key: 'initial_stock_rate',
            type: 'number',
            placeholder: 'Enter the initial stock rate',
            visible: (connectedObject) => connectedObject.track_inventory == true,
            width: '25%',
            tab: 'Inventory Details'
        },
        {
            label: 'Reorder Level',
            key: 'reorder_level',
            type: 'number',
            placeholder: 'Enter the reorder level',
            visible: (connectedObject) => connectedObject.track_inventory == true,
            width: '25%',
            tab: 'Inventory Details'
        },
        {
            label: 'Quantity In Hand',
            key: 'quantity_in_hand',
            type: 'number',
            placeholder: 'Enter the quantity in hand',
            visible: false,
            width: '25%',
            tab: 'Inventory Details'
        },
        {
            label: 'Track Serial Number',
            key: 'track_serial_number',
            type: 'switch',
            placeholder: 'Enter the track serial number',
            visible: (connectedObject) => connectedObject.track_inventory == true,
            width: '100%',
            tab: 'Inventory Details',
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                if (value == true) {
                    setObj({
                        track_batch_number: false,
                        updateVisiblity: Math.random(),
                    })
                } else {
                    setObj({
                        initital_serial_numbers: [],
                        updateVisiblity: Math.random(),
                    })
                }
            }
        },
        {
            label: 'Serial Numbers',
            key: 'initital_serial_numbers',
            type: 'tags_text_area',
            placeholder: 'Enter the track serial number',
            visible: (connectedObject) => connectedObject.track_inventory == true && connectedObject.track_serial_number == true,
            width: '100%',
            tab: 'Inventory Details',
            value: []
        },
        {
            label: 'Track Batch Number',
            key: 'track_batch_number',
            type: 'switch',
            placeholder: 'Enter the track batch number',
            visible: (connectedObject) => connectedObject.track_inventory == true,
            width: '100%',
            tab: 'Inventory Details',
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                if (value == true) {
                    setObj({
                        track_serial_number: false,
                        updateVisiblity: Math.random(),
                    })
                } else {
                    setObj({
                        initital_batches: [],
                        updateVisiblity: Math.random(),
                    })
                }
            }
        },
        {
            label: 'Batch Numbers',
            key: 'initital_batches',
            type: 'table',
            placeholder: 'Enter the batch numbers',
            visible: (connectedObject) => connectedObject.track_inventory == true && connectedObject.track_batch_number == true,
            width: '100%',
            tab: 'Inventory Details',
            editable: true,
            columns: batchNumbersColumns,
            actions: true,
            actionFunctions: (row) => ([{
                label: 'Delete',
                key: 'delete',
                fn: ({ data, updateData, row, row_index }) => {
                    let newData = [...data];
                    newData.splice(row_index, 1);
                    updateData(newData);
                }
            }]),
            value: []
        },
        addButton("Add Batch Number", "add_batch_number", "initital_batches", {}, "Inventory Details", (connectedObject) => connectedObject.track_inventory == true && connectedObject.track_batch_number == true),
        {
            label: 'Is Combo Product',
            key: 'is_combo_product',
            type: 'switch',
            placeholder: 'Enter the is combo product',
            visible: (connectedObject) => connectedObject.track_inventory == true,
            width: '100%',
            tab: 'Inventory Details',
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                if (value == true) {
                    setObj({
                        mapped_items: [],
                        updateVisiblity: Math.random(),
                    })
                } else {
                    setObj({
                        mapped_items: [],
                        updateVisiblity: Math.random(),
                    })
                }
            }
        },
        {
            label: 'Mapped Items',
            key: 'mapped_items',
            type: 'table',
            placeholder: 'Select the mapped items',
            visible: (connectedObject) => connectedObject.track_inventory == true && connectedObject.is_combo_product == true,
            width: '100%',
            tab: 'Inventory Details',
            editable: true,
            columns: [{
                "field": "item_id",
                "label": "Item",
                "editable": true,
                "type": "select",
                "visible": true,
                "order": 1,
                "Header": "Item",
                "accessor": "item_id",
                "placeholder": "Select the item",
                "options": response.other_items || [],
            }, {
                "field": "quantity",
                "label": "Quantity",
                "editable": true,
                "type": "number",
                "visible": true,
                "order": 2,
                "Header": "Quantity",
                "accessor": "quantity",
                "placeholder": "Enter the quantity",
            }],
            actions: true,
            actionFunctions: (row) => ([{
                label: 'Delete',
                key: 'delete',
                fn: ({ data, updateData, row, row_index }) => {
                    let newData = [...data];
                    newData.splice(row_index, 1);
                    updateData(newData);
                }
            }]),
            value: [],
        },
        addButton("Add Mapped Item", "add_mapped_item", "mapped_items", {}, "Inventory Details", (connectedObject) => connectedObject.track_inventory == true && connectedObject.is_combo_product == true),
        {
            label: 'Status',
            key: 'status',
            type: 'select',
            placeholder: 'Select the status',
            visible: true,
            width: '25%',
            options: [
                { label: 'Active', value: 'ACTIVE' },
                { label: 'Inactive', value: 'INACTIVE' },
            ],
            tab: 'General'
        },
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                tab: 'General 2'
            });
        });
    }

    const defaults = response.defaults;

    if (defaults) {
        for (let key in defaults) {
            const field = formFields.find(field => field.key == key);
            if (field) {
                field.value = defaults[key];
            }
        }
    }

    return formFields;
}

export const itemMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View row spread style={{ width: '100%' }}>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
            <View style={{ backgroundColor: 'red', borderRadius: 6, paddingHorizontal: 10, paddingVertical: 5 }}>
                <Text style={{ marginBottom: 5, color: 'white' }}>{getFieldValue(item, 'sku') ? "" + getFieldValue(item, 'sku') : ""}</Text>
            </View>
        </View>
        {/* 1 px dashed line */}
        <View style={{ width: '100%', height: 1, backgroundColor: '#000', borderStyle: 'dashed', borderWidth: 1, borderColor: '#000', marginBottom: 5 }} />
        <View row spread style={{ width: '100%' }}>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'sales_rate') ? "Price333: " + getFieldValue(item, 'sales_rate') : ""}</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'quantity_in_hand') ? "Quantity: " + getFieldValue(item, 'quantity_in_hand') : ""}</Text>
        </View>
    </Card>;
}

export const gypProductsMain = {
    fields: itemfields,
    mobileCard: null,
    tabs: [{
        label: 'All',
        key: 'All',
        value: {}
    }, {
        label: 'Goods',
        key: 'Goods',
        value: {
            product_type: 'Goods',
        }
    }, {
        label: 'Services',
        key: 'Services',
        value: {
            product_type: 'Services',
        }
    }, {
        label: 'Memberships',
        key: 'Memberships',
        value: {
            product_type: 'Memberships',
        }
    }, {
        label: 'Packages',
        key: 'Packages',
        value: {
            product_type: 'Packages',
        }
    }, {
        label: 'Vehicles',
        key: 'Vehicles',
        value: {
            product_type: 'Vehicles',
        }
    }]
}