import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";
import { addButton } from "../hrms/shiftModel";

export const taskfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

    const formFields = [
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter the name',
            visible: true,
            width: '50%',
        }, {
            label: 'Employees',
            key: 'employee_ids',
            type: 'select',
            placeholder: 'Enter the priority',
            visible: true,
            width: '25%',
            options: response.Employees || [],
            multiple: true,
        },
        {
            label: 'Priority',
            key: 'priority',
            type: 'select',
            placeholder: 'Enter the priority',
            visible: true,
            width: '25%',
            options: response.Priorities || [],
        },
        {
            label: 'Start Date',
            key: 'start_date',
            type: 'date',
            placeholder: 'Enter the start date',
            visible: true,
            width: '25%',
            dateTime: true,
        },
        {
            label: 'End Date',
            key: 'due_date',
            type: 'date',
            placeholder: 'Enter the end date',
            visible: true,
            width: '25%',
            dateTime: true,
        },
        {
            label: 'Status',
            key: 'status',
            type: 'select',
            placeholder: 'Enter the status',
            visible: true,
            width: '25%',
            options: response.Statuses || [],
        },
        {
            label: 'Tags',
            key: 'tags',
            type: 'select',
            placeholder: 'Enter the tags',
            visible: true,
            width: '25%',
            options: response.Tags || [],
            multiple: true,
        },
        {
            label: 'Description',
            key: 'description',
            type: 'textarea',
            placeholder: 'Enter the name',
            visible: true,
            width: '100%',
        },
        {
            label: 'Project',
            key: 'project_id',
            type: 'select',
            placeholder: 'Enter the project',
            visible: false,
            width: '25%',
            options: response.Projects || [],
        }, {
            label: 'List',
            key: 'list_id',
            type: 'select',
            placeholder: 'Enter the list',
            visible: false,
            width: '25%',
            options: response.Lists || [],
        },
        {
            label: 'Check List',
            key: 'check_list',
            type: 'table',
            placeholder: 'Enter the check list',
            visible: true,
            width: '100%',
            editable: true,
            columns: [{
                label: '',
                Header: '',
                key: 'checked',
                accessor: 'checked',
                type: 'checkbox',
                visible: true,
                editable: true,
                width: 50,
                minWidth: 50,
            }, {
                label: 'Name',
                Header: 'Name',
                key: 'name',
                accessor: 'name',
                type: 'text',
                visible: true,
                editable: true,

            }],
        },
        addButton('Add Check List', 'add_check_list', 'check_list', {
            _id: "GENERATED_ID",
        }),
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                viewField: true,
            });
        });
    }

    return formFields;
}

export const taskMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const taskMain = {
    fields: taskfields,
    mobileCard: null
}

