import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";

export const taxGroupfields = (response, view = false, moduleName = null, OpenModalCustom) => {

    const formFields = [
        {
            label: 'Tax Group Name',
            key: 'name',
            type: 'text',
            placeholder: ' the tax group name',
            visible: true,
            width: '25%',
        },
        {
            label: 'Tax Percentage',
            key: 'tax_percentage',
            type: 'number',
            placeholder: 'Enter the tax percentage',
            visible: false,
            width: '25%',
        },
        {
            label: 'Taxes',
            key: 'tax_ids',
            type: 'select',
            multiple: true,
            placeholder: 'Enter the tax name',
            visible: true,
            width: '25%',
            options: response.taxes || [],
            creatableAction: (props) => {
                const params = { ...(props.id ? { isEdit: true, id: props.id } : {}) };
                OpenModalCustom({ newModule: "Taxes", field_key: 'tax_ids', array: true, params })
            }
        },
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                viewField: true,
            });
        });
    }

    return formFields;
}

export const taxGroupMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const taxGroupMain = {
    fields: taxGroupfields,
    mobileCard: null
}

