import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";
import { Col, CustomCheckbox, CustomSelect, FiltersComponent, PrimaryButton, Row } from "../../mycomponents/DynamicForm";
// import { QueryBuilderNative } from '@react-querybuilder/native';
// import QueryBuilder, { Field, FieldSelectorProps, Operator, OperatorSelectorProps, OptionGroup, defaultOperators } from "react-querybuilder";
// import { QueryBuilderAntD } from '@react-querybuilder/antd';
import { api } from "../../services/api";

// const FieldSelector = (props: FieldSelectorProps) => {
//   const options = props.options as OptionGroup<Field>[];

//   return (
//     <CustomSelect
//       placeholder="Select a field"
//       value={props.value}
//       onChange={(value) => {
//         props.handleOnChange(value);
//       }}
//       options={options}
//     />
//   );
// };

// const operatorSelector = (props: OperatorSelectorProps) => {
//   const options = props.options as OptionGroup<Operator>[];

//   return (
//     <CustomSelect
//       placeholder="Select an operator"
//       value={props.value}
//       onChange={(value) => {
//         props.handleOnChange(value);
//       }}
//       options={options}
//     />
//   );
// }

export const approvalRulefields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

  const ConditionsComponent = ({ field, useForm, tKey, tIndex }) => {
    let conditions = useForm((state) => state.formObject['conditions']) || [];
    let filters = useForm((state) => state.formObject['filters']) || [];
    let createData = useForm((state) => state.formObject['createData']) || [];
    const setFormObject = useForm((state) => state.setFormObject);
    const module = useForm((state) => state.formObject['module']) || '';

    const [fields, setFields] = React.useState([]);

    useEffect(() => {
      if (filters && filters.length > 0) {
        const fields = [];

        for (let i = 0; i < filters.length; i++) {
          const filter = filters[i];
          let valueEditorType = 'text';
          // let operators:any = defaultOperators;
          let operators = [];

          const obj = {
            name: filter.field,
            label: filter.label,
            operators: operators,
            valueEditorType: valueEditorType,
          };

          switch (filter.type) {
            case 'text':
              obj.valueEditorType = 'text';
              break;
            case 'number':
              obj.valueEditorType = 'number';
              obj['inputType'] = 'number';
              break;
            case 'date':
              obj.valueEditorType = 'date';
              break;
            case 'select':
              obj.valueEditorType = 'multiselect';
              obj['values'] = filter.options || [];
              obj.operators = [
                { name: 'in', label: 'In' },
                { name: 'not_in', label: 'Not In' },
              ];

              break;
            case 'boolean':
              obj.valueEditorType = 'checkbox';
              obj["defaultValue"] = false;

              break;
            case 'checkbox':
              obj.valueEditorType = 'checkbox';
              break;
            case 'radio':
              obj.valueEditorType = 'radio';
              break;
            case 'multiselect':
              obj.valueEditorType = 'multiselect';
              break;
            case 'multicheckbox':
              obj.valueEditorType = 'multicheckbox';
              break;

            default:
              obj.valueEditorType = 'text';
          }

          fields.push(obj);
        }
        setFields(fields);
      }
    }, [filters]);

    useEffect(() => {
      const getCreateData = async () => {
        const response = await api.getCreateData(module, {});
        const { filters
        } = await api.get(module, {
          "page": 1,
          "limit": 1,
          "sortColumn": "",
          "sortDirection": "",
          "filters": [],
          "tabFilter": {},
          "search": "",
          "groupBy": null
        })

        setFormObject({
          createData: response,
          filters: filters
        });
      }

      getCreateData();
    }, [module]);

    return <View style={{ marginBottom: 20 }} flex>
      <View style={{ marginTop: 10 }} />
      <Text tabHeading style={{ marginBottom: 5 }}>Conditions</Text>

      <View style={{
        borderBottomColor: '#d9d9d9',
        borderBottomWidth: 1,
        width: '100%',
        marginBottom: 0,
        marginTop: 10,
        borderStyle: 'dashed',
      }} />

      <View flex style={{ width: '100%' }} marginT-20>
        {conditions && conditions.length > 0 && conditions.map((condition, conditionIndex) => {
          return <View key={conditionIndex.toString()} marginB-20>
            {/* <Card padding-10> */}
            <Text h1 marginB-10>Condition {conditionIndex + 1}</Text>

            <Text formlabel marginB-10>If Record matches any of the below filters</Text>

            <View style={{ marginTop: 10 }} />

            <FiltersComponent filters={filters} selectedFilters={(condition.filters || [])} updateSelectedFilters={(value) => {
              const oldConditions = [...(conditions || [])];
              oldConditions[conditionIndex].filters = value;
              setFormObject({
                conditions: oldConditions
              });
            }} createData={createData} visible={true} autoApply={true} />

            <View marginV-10>
              <Text formlabel>Then the following approval worflow will be assigned.</Text>
            </View>

            <Row>
              <Col width="25%">
                <CustomSelect
                  placeholder="Workflow to run if condition is met"
                  value={condition.approval_workflow_id}
                  onChange={(value) => {
                    const oldConditions = [...(conditions || [])];
                    oldConditions[conditionIndex].approval_workflow_id = value;
                    setFormObject({
                      conditions: oldConditions
                    });
                  }}
                  options={[
                    ...(response.ApprovalWorkflows || []).map((item) => {
                      return { label: item.name, value: item._id }
                    }),
                  ]}
                />
              </Col>
            </Row>
            {/* </Card> */}

            <View style={{
              borderBottomColor: '#d9d9d9',
              borderBottomWidth: 1,
              width: '100%',
              marginBottom: 0,
              marginTop: 10,
              borderStyle: 'dashed',
            }} />

          </View>
        })}
      </View>

      <View left marginT-10>
        <PrimaryButton
          label="Add Contition"
          onPress={() => {
            const oldConditions = [...(conditions || [])];
            const newCondition = {
              field: '',
              operator: '',
              value: '',
            }
            oldConditions.push(newCondition);
            setFormObject({
              conditions: oldConditions
            });
          }}
        />
      </View>
    </View>
  };

  const formFields = [
    {
      type: 'divider',
      heading: 'Approval Rule Details',
      visible: true,
      span: 24,
    },
    {
      label: 'Name',
      key: 'name',
      type: 'text',
      placeholder: 'Enter Name',
      visible: true,
      width: '25%',
      required: true,
    },
    {
      label: 'Module',
      key: 'module',
      type: 'select',
      placeholder: 'Select Module',
      visible: true,
      width: '25%',
      required: true,
      options: [
        { label: 'Money Transfers', value: 'MoneyTransfers' },
      ],
      onChange: ({ value, tKey, tIndex, getObj, setObj, getArrValue, setArrValue }) => {

      }
    },
    {
      label: 'Action Type',
      key: 'type',
      type: 'select',
      placeholder: 'Select Action Type',
      visible: true,
      width: '25%',
      required: true,
      options: [
        { label: 'Create', value: 'New' },
        // { label: 'Update', value: 'Edit' },
        // { label: 'Delete', value: 'Delete' },
      ]
    },
    {
      label: 'Disable editing of records after approval',
      key: 'disable_editing',
      type: 'checkbox',
      visible: true,
      width: '100%',
      value: false
    },
    {
      label: 'Disable deleting of records after approval',
      key: 'disable_deleting',
      type: 'checkbox',
      visible: true,
      width: '100%',
      value: false
    },

    {
      label: '',
      key: 'conditions',
      type: 'custom',
      component: ConditionsComponent,
      visible: true,
      width: '100%',
      value: [{}]
    }
  ];

  const CustomFields = response.CustomFields;

  if (CustomFields && CustomFields.length > 0) {
    CustomFields.forEach((field) => {
      formFields.push({
        label: field.name,
        key: 'cf.' + field.key,
        type: field.type,
        placeholder: field.placeholder,
        visible: true,
        width: '25%'
      });
    });
  }

  return formFields;
}

export const approvalRuleMobileCard = (item, index, getFieldValue) => {
  return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
    <View>
      <Text>TEST</Text>
      <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
    </View>
  </Card>;
}

export const approvalRuleMain = {
  fields: approvalRulefields,
  mobileCard: null
}