import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";

export const shiftSchedulerfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
    const formFields = [
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter the name',
            visible: true,
            width: '25%',
        },
        {
            label: 'Recurrence Time',
            key: 'recurrence_time',
            type: 'text',
            placeholder: 'Enter the recurrence time',
            visible: true,
            width: '25%',
        },
        {
            label: 'Flexibility',
            key: 'flexibility',
            type: 'number',
            placeholder: 'Enter the flexibility',
            visible: true,
            width: '25%',
        },
        {
            label: 'Flexibility Type',
            key: 'flexibility_type',
            type: 'select',
            placeholder: 'Select the flexibility type',
            visible: true,
            width: '25%',
            options: [
                { label: 'Hours', value: 'hours' },
                { label: 'Days', value: 'days' },
                { label: 'Weeks', value: 'weeks' },
            ],
        },
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                viewField: true,
            });
        });
    }

    return formFields;
}

export const shiftSchedulerMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const shiftSchedulerMain = {
    fields: shiftSchedulerfields,
    mobileCard: null
}


