// TODO: write documentation about fonts and typography along with guides on how to add custom fonts in own
// markdown file and add links from here

import { Platform } from "react-native"

import {
  WorkSans_300Light as WorkSansLight,
  WorkSans_400Regular as WorkSansRegular,
  WorkSans_500Medium as WorkSansMedium,
  WorkSans_600SemiBold as WorkSansSemiBold,
  WorkSans_700Bold as WorkSansBold,
} from "@expo-google-fonts/work-sans"

import {
  Poppins_300Light as PoppinsLight,
  Poppins_400Regular as PoppinsRegular,
  Poppins_500Medium as PoppinsMedium,
  Poppins_600SemiBold as PoppinsSemiBold,
  Poppins_700Bold as PoppinsBold,
  Poppins_200ExtraLight as PoppinsExtraLight,
} from "@expo-google-fonts/poppins"

import {
  SourceSansPro_300Light as SourceSansProLight,
  SourceSansPro_400Regular as SourceSansProRegular,
  SourceSansPro_600SemiBold as SourceSansProSemiBold,
  SourceSansPro_700Bold as SourceSansProBold,
} from "@expo-google-fonts/source-sans-pro"

// npm i @expo-google-fonts/jetbrains-mono

import {
  JetBrainsMono_300Light as JetBrainsMonoLight,
  JetBrainsMono_400Regular as JetBrainsMonoRegular,
  JetBrainsMono_500Medium as JetBrainsMonoMedium,
  JetBrainsMono_600SemiBold as JetBrainsMonoSemiBold,
  JetBrainsMono_700Bold as JetBrainsMonoBold,
  JetBrainsMono_800ExtraBold as JetBrainsMonoExtraBold,
} from "@expo-google-fonts/jetbrains-mono"

import {
  CormorantGaramond_300Light,
  CormorantGaramond_300Light_Italic,
  CormorantGaramond_400Regular,
  CormorantGaramond_400Regular_Italic,
  CormorantGaramond_500Medium,
  CormorantGaramond_500Medium_Italic,
  CormorantGaramond_600SemiBold,
  CormorantGaramond_600SemiBold_Italic,
  CormorantGaramond_700Bold,
  CormorantGaramond_700Bold_Italic,
} from '@expo-google-fonts/cormorant-garamond';

import { Sen_400Regular, Sen_700Bold, Sen_800ExtraBold } from '@expo-google-fonts/sen';


export const customFontsToLoad = {
  WorkSansLight,
  WorkSansRegular,
  WorkSansMedium,
  WorkSansSemiBold,
  WorkSansBold,
  PoppinsExtraLight,
  PoppinsLight,
  PoppinsRegular,
  PoppinsMedium,
  PoppinsSemiBold,
  PoppinsBold,
  SourceSansProLight,
  SourceSansProRegular,
  SourceSansProSemiBold,
  SourceSansProBold,
  JetBrainsMonoLight,
  JetBrainsMonoRegular,
  JetBrainsMonoMedium,
  JetBrainsMonoSemiBold,
  JetBrainsMonoBold,
  JetBrainsMonoExtraBold,
  CormorantGaramond_300Light,
  CormorantGaramond_300Light_Italic,
  CormorantGaramond_400Regular,
  CormorantGaramond_400Regular_Italic,
  CormorantGaramond_500Medium,
  CormorantGaramond_500Medium_Italic,
  CormorantGaramond_600SemiBold,
  CormorantGaramond_600SemiBold_Italic,
  CormorantGaramond_700Bold,
  CormorantGaramond_700Bold_Italic,
  Sen_400Regular,
  // Sen_500Medium,
  // Sen_600SemiBold,
  Sen_700Bold,
  Sen_800ExtraBold,
}

const fonts = {
  workSans: {
    // Cross-platform Google font.
    light: "WorkSansLight",
    regular: "WorkSansRegular",
    medium: "WorkSansMedium",
    semiBold: "WorkSansSemiBold",
    bold: "WorkSansBold",
  },
  poppins: {
    // Cross-platform Google font.
    light: "PoppinsLight",
    regular: "PoppinsRegular",
    medium: "PoppinsMedium",
    semiBold: "PoppinsSemiBold",
    bold: "PoppinsBold",
  },
  helveticaNeue: {
    // iOS only font.
    thin: "HelveticaNeue-Thin",
    light: "HelveticaNeue-Light",
    normal: "Helvetica Neue",
    medium: "HelveticaNeue-Medium",
  },
  courier: {
    // iOS only font.
    normal: "Courier",
  },
  sansSerif: {
    // Android only font.
    thin: "sans-serif-thin",
    light: "sans-serif-light",
    normal: "sans-serif",
    medium: "sans-serif-medium",
  },
  monospace: {
    // Android only font.
    normal: "monospace",
  },
}

export const typography = {
  /**
   * The fonts are available to use, but prefer using the semantic name.
   */
  fonts,
  /**
   * The primary font. Used in most places.
   */
  primary: fonts.workSans,
  /**
   * An alternate font used for perhaps titles and stuff.
   */
  secondary: Platform.select({ ios: fonts.helveticaNeue, android: fonts.sansSerif }),
  /**
   * Lets get fancy with a monospace font!
   */
  code: Platform.select({ ios: fonts.courier, android: fonts.monospace }),
}
