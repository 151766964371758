import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";
import { addButton } from "./shiftModel";

export const leaveCategoryfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
    const formFields = [
        {
            label: 'Image',
            key: 'image',
            type: 'upload',
            mimeType: 'image/*',
            moduleName: moduleName,
            visible: true,
            width: '100%',
            tab: 'General',
        },
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter the name',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Type',
            key: 'type',
            type: 'select',
            placeholder: 'Select the type',
            visible: true,
            width: '25%',
            options: [
                { label: 'Paid', value: 'paid' },
                { label: 'Unpaid', value: 'unpaid' },
            ],
            tab: 'General',
        },
        // {
        //     label: 'Unit',
        //     key: 'unit',
        //     type: 'text',
        //     placeholder: 'Enter the unit',
        //     visible: true,
        //     width: '25%',
        //     tab: 'General',
        // },
        // {
        //     label: 'Valid From',
        //     key: 'valid_from',
        //     type: 'date',
        //     placeholder: 'Enter the valid from date',
        //     visible: true,
        //     width: '25%',
        //     tab: 'General',
        // },
        // {
        //     label: 'Valid To',
        //     key: 'valid_to',
        //     type: 'date',
        //     placeholder: 'Enter the valid to date',
        //     visible: true,
        //     width: '25%',
        //     tab: 'General',
        // },

        {
            type: 'divider',
            heading: 'Applicable To',
            visible: true,
            span: 24,
            tab: 'General',
        },

        {
            label: 'Employees',
            key: 'employees',
            type: 'select',
            placeholder: 'Select the employees',
            visible: true,
            width: '100%',
            options: [{ label: 'All', value: 'All' }, ...(response.Employees || [])],
            value: ['All'],
            multiple: true,
            onChange: (props) => {
                const selectedOptions = [...props.value];
                const allIndex = selectedOptions.indexOf('All');

                if (allIndex > -1 && selectedOptions.length > 1) {
                    selectedOptions.splice(allIndex, 1);
                }

                props.setObj({
                    employees: selectedOptions,
                    updateVisiblity: Math.random(),
                })
            },
            tab: 'General',
        },

        {
            label: 'Age (More Than)',
            key: 'age_from',
            type: 'number',
            placeholder: 'Enter the age from',
            visible: (connectedObject) => (connectedObject.employees && connectedObject.employees.length == 1) ? connectedObject.employees[0] == 'All' : false,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Age (Less Than)',
            key: 'age_to',
            type: 'number',
            placeholder: 'Enter the age to',
            visible: (connectedObject) => (connectedObject.employees && connectedObject.employees.length == 1) ? connectedObject.employees[0] == 'All' : false,
            width: '25%',
            tab: 'General',
        },
        {
            width: '25%',
            tab: 'General',
        }, {
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Residency Status',
            key: 'residency_status',
            type: 'select',
            placeholder: 'Select the residency status',
            visible: (connectedObject) => (connectedObject.employees && connectedObject.employees.length == 1) ? connectedObject.employees[0] == 'All' : false,
            width: '25%',
            options: [...(response.ResidencyStatus || [])],
            multiple: true,
            tab: 'General',
        },
        {
            label: 'Religion',
            key: 'religion',
            type: 'select',
            placeholder: 'Select the religion',
            visible: (connectedObject) => (connectedObject.employees && connectedObject.employees.length == 1) ? connectedObject.employees[0] == 'All' : false,
            width: '25%',
            options: [...(response.Religion || [])],
            multiple: true,
            tab: 'General',
        },
        {
            label: 'Gender',
            key: 'gender',
            type: 'select',
            placeholder: 'Select the Gender',
            visible: (connectedObject) => (connectedObject.employees && connectedObject.employees.length == 1) ? connectedObject.employees[0] == 'All' : false,
            width: '25%',
            options: [...([
                { label: 'Male', value: 'Male' },
                { label: 'Female', value: 'Female' },
                { label: 'Other', value: 'Other' },
            ])],
            multiple: true,
            tab: 'General',
        },
        {
            label: 'Marital Status',
            key: 'marital_status',
            type: 'select',
            placeholder: 'Select the marital status',
            visible: (connectedObject) => (connectedObject.employees && connectedObject.employees.length == 1) ? connectedObject.employees[0] == 'All' : false,
            width: '25%',
            options: [...(response.MaritalStatus || [])],
            multiple: true,
            tab: 'General',
        },
        {
            label: 'Departments',
            key: 'departments',
            type: 'select',
            placeholder: 'Select the departments',
            visible: (connectedObject) => (connectedObject.employees && connectedObject.employees.length == 1) ? connectedObject.employees[0] == 'All' : false,
            width: '25%',
            options: [...(response.Departments || [])],
            multiple: true,
            tab: 'General',
        },
        {
            label: 'Designations',
            key: 'designations',
            type: 'select',
            placeholder: 'Select the designations',
            visible: (connectedObject) => (connectedObject.employees && connectedObject.employees.length == 1) ? connectedObject.employees[0] == 'All' : false,
            width: '25%',
            options: [...(response.Designations || [])],
            multiple: true,
            tab: 'General',
        },
        {
            label: 'Locations',
            key: 'locations',
            type: 'select',
            placeholder: 'Select the Locations',
            visible: (connectedObject) => (connectedObject.employees && connectedObject.employees.length == 1) ? connectedObject.employees[0] == 'All' : false,
            width: '25%',
            options: [...(response.Locations || [])],
            multiple: true,
            tab: 'General',
        },
        {
            label: 'Roles',
            key: 'roles',
            type: 'select',
            placeholder: 'Select the roles',
            visible: (connectedObject) => (connectedObject.employees && connectedObject.employees.length == 1) ? connectedObject.employees[0] == 'All' : false,
            width: '25%',
            options: [...(response.Roles || [])],
            multiple: true,
            tab: 'General',
        },

        // {
        //     label: 'Prorate (If prorate is enabled, an employee who has 12 leaves available in a year and can only apply for 1 leave in the month of Jan, 2 leaves in the month of Feb, and so on.)',
        //     key: 'prorate',
        //     type: 'checkbox',
        //     visible: true,
        //     width: '100%',
        //     tab: 'Rules',
        // },

        {
            label: 'Durations Allowed',
            key: 'durations_allowed',
            type: 'select',
            placeholder: 'Select the durations allowed',
            visible: true,
            width: '25%',
            options: [
                { label: 'Full Day', value: 'Full Day' },
                { label: 'Half Day', value: 'Half Day' },
            ],
            multiple: true,
            tab: 'Rules',
            value: ['Full Day', 'Half Day'],
        }, {
            width: '75%',
            tab: 'Rules',
        }, {
            key: 'allow_requests_for_past_dates',
            label: 'Can leave be applied for past dates?',
            type: 'checkbox',
            visible: true,
            width: '25%',
            tab: 'Rules',
            onChange: (props) => {
                props.setObj({ updateVisiblity: Math.random() })
            }
        }, {
            key: 'past_days',
            label: 'How many days in the past can leave be applied?',
            type: 'number',
            placeholder: 'Enter the past days',
            visible: (obj) => obj.allow_requests_for_past_dates,
            width: '25%',
            tab: 'Rules',
        }, {
            width: '25%',
            visible: (obj) => !obj.allow_requests_for_past_dates,
            tab: 'Rules',
        }, {
            width: '50%',
            tab: 'Rules',
        }, {
            key: 'allow_request_for_future_dates',
            label: 'Can leave be applied for current and future dates?',
            type: 'checkbox',
            visible: true,
            width: '25%',
            tab: 'Rules',
            onChange: (props) => {
                props.setObj({ updateVisiblity: Math.random() })
            }
        }, {
            key: 'next_days',
            label: 'How many days in the future can leave be applied?',
            type: 'number',
            placeholder: 'Enter the next days',
            visible: (obj) => obj.allow_request_for_future_dates,
            width: '25%',
            tab: 'Rules',
        }, {
            key: 'to_be_applied_days_in_advance',
            label: 'How many days in advance must leave be applied?',
            type: 'number',
            placeholder: 'Enter the to be applied days in advance',
            visible: (obj) => obj.allow_request_for_future_dates,
            width: '25%',
            tab: 'Rules',
        },
        {
            width: '50%',
            visible: (obj) => !obj.allow_request_for_future_dates,
            tab: 'Rules',
        },
        // {
        //     label: 'Maximum number of consecutive days of leave allowed',
        //     key: 'maximum_number_of_consecutive_days_of_leave_allowed',
        //     type: 'number',
        //     placeholder: 'Enter the maximum number of consecutive days of leave allowed',
        //     visible: true,
        //     width: '50%',
        //     tab: 'Rules',
        // },

        {
            label: 'Leave Documents',
            key: 'leave_documents',
            type: 'table',
            placeholder: 'Select the leave documents',
            visible: true,
            width: '100%',
            options: response.LeaveDocuments || [],
            multiple: true,
            tab: 'Rules',
            columns: [{
                "field": "document_name",
                "label": "Document Name",
                "editable": true,
                "type": "text",
                "visible": true,
                "order": 1,
                "Header": "Document Name",
                "accessor": "document_name",
                "placeholder": "Enter the document name",
            }, {
                "field": "mandatory",
                "label": "Mandatory",
                "editable": true,
                "type": "select",
                "visible": true,
                "order": 2,
                "Header": "Mandatory",
                "accessor": "mandatory",
                "placeholder": "Select the mandatory",
                "options": [
                    { label: 'Yes', value: "Yes" },
                    { label: 'No', value: "No" },
                ],
            }],
            actions: true,
            actionFunctions: (row) => ([{
                label: 'Delete',
                key: 'delete',
                fn: ({ data, updateData, row, row_index }) => {
                    let newData = [...data];
                    newData.splice(row_index, 1);
                    updateData(newData);
                }
            }]),
            value: [],
        },

        addButton("Add Leave Document", "add_leave_document", "leave_documents", {
            "document_name": "",
            "mandatory": "Yes",
        }, "Rules", true),
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                viewField: true,
            });
        });
    }

    return formFields;
}

export const leaveCategoryMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const leaveCategoryMain = {
    fields: leaveCategoryfields,
    mobileCard: null
}