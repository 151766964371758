import React, { useEffect, useState } from "react";
import { View, Card, Text, TouchableOpacity, Button } from "react-native-ui-lib";
import DynamicTable from "../../mycomponents/DynamicTable";
import { CustomModal, CustomSelect, CustomSerialNumberTextArea } from "../../mycomponents/DynamicForm";

import { create } from 'zustand'
import { addButton } from "../hrms/shiftModel";

const useFormObject = create((set, get) => ({
    formObject: {},
    setFormObject: (keyValue) => set((state) => ({ formObject: { ...state.formObject, ...keyValue } })),
    setFormObjectArray: (array_key, array_index, field_key, value) => set((state) => {
        if (typeof field_key === 'object') {
            const newFormObject = { ...state.formObject };
            newFormObject[array_key][array_index] = { ...newFormObject[array_key][array_index], ...field_key };
            return { formObject: newFormObject };
        } else {
            const newFormObject = { ...state.formObject };
            // newFormObject[array_key][array_index][field_key] = value;
            const newArray = newFormObject[array_key];
            newArray[array_index][field_key] = value;
            newFormObject[array_key] = newArray;
            return { formObject: newFormObject };
        }
    }),
    getFormObjectArrayValue: (array_key, array_index, field_key) => get().formObject[array_key][array_index][field_key],
    getFormObject: () => get().formObject,
}))

export const itemAdjustmentfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

    const TrackingComponent = ({ field, useForm, tKey, tIndex }) => {
        const track_serial_number = useForm((state) => state.formObject[tKey][tIndex]['track_serial_number']);
        const serial_numbers = useForm((state) => state.formObject[tKey][tIndex]['serial_numbers']);
        const item_id = useForm((state) => state.formObject[tKey][tIndex]['item_id']);

        const [serialNumberModalVisible, setSerialNumberModalVisible] = useState(false);
        const [tempSerialNumber, setTempSerialNumber] = useState('');

        const track_batch_number = useForm((state) => state.formObject[tKey][tIndex]['track_batch_number']);
        const batch_numbers = useForm((state) => state.formObject[tKey][tIndex]['batch_numbers']);
        const [batchNumberModalVisible, setBatchNumberModalVisible] = useState(false);

        const setFormObjectArray = useForm((state) => state.setFormObjectArray);
        const setFormObject = useForm((state) => state.setFormObject);

        const batchNumbersColumns = [
            {
                "field": "batch_number",
                "label": "Batch Number",
                "editable": true,
                "type": "select",
                "visible": true,
                "order": 1,
                "options": [...(response.AvailableBatchNumbers || [{
                    "label": "Batch Number 1",
                    "value": "Batch Number 1",
                    "item_id": "649bb576e552219bd858b4c7"
                }, {
                    "label": "Batch Number 2",
                    "value": "Batch Number 2",
                    "item_id": "649bb576e552219bd858b4c7"
                }, {
                    "label": "Batch Number 3",
                    "value": "Batch Number 3",
                    "item_id": "649be5b1c10754fc93f3d89a"
                }
                ])].filter((item) => item.item_id === item_id)
            }, {
                "field": "quantity",
                "label": "Quantity",
                "editable": true,
                "type": "number",
                "visible": true,
                "order": 3
            },
            {
                label: (props) => null,
                field: 'actions',
            }
        ];

        batchNumbersColumns && batchNumbersColumns.map((column) => {
            column.Header = column.label;
            column.accessor = column.field;
        });

        const batchFormObject = useFormObject((state) => state.formObject);
        const batchSetFormObjectArray = useFormObject((state) => state.setFormObjectArray);
        const batchSetFormObject = useFormObject((state) => state.setFormObject);

        return <>
            <View>
                <CustomModal title="Serial Numbers" visible={serialNumberModalVisible} onClose={() => setSerialNumberModalVisible(false)}>
                    <CustomSelect
                        value={tempSerialNumber}
                        onChange={(value) => {
                            const arr = [...(serial_numbers || [])];
                            arr.push(value);
                            setFormObjectArray(tKey, tIndex, 'serial_numbers', arr);
                        }}
                        options={response.AvailableSerialNumbers || [
                            { label: '1', value: '1' },
                            { label: '2', value: '2' },
                            { label: '3', value: '3' },
                        ]}
                        placeholder='Select Serial Number'
                    />

                    <View marginT-20>
                        <CustomSerialNumberTextArea
                            value={serial_numbers || []}
                            onChange={(value) => {
                                setFormObjectArray(tKey, tIndex, 'serial_numbers', value);
                            }}
                        ></CustomSerialNumberTextArea>
                    </View>
                </CustomModal>
                {track_serial_number ?
                    <TouchableOpacity onPress={() => setSerialNumberModalVisible(true)}>
                        <Text>{serial_numbers ? serial_numbers.length : 0} Serial Numbers</Text>
                    </TouchableOpacity>
                    : null}

                <CustomModal title="Batch Numbers" visible={batchNumberModalVisible} onClose={() => {
                    if (batchFormObject.batch_numbers.filter((item) => item.quantity == 0) > 0) {
                        alert('Please enter quantity for all batch numbers or delete them');
                        return;
                    }

                    setBatchNumberModalVisible(false)
                    setFormObjectArray(tKey, tIndex, 'batch_numbers', batchFormObject.batch_numbers || [])
                }}>
                    <DynamicTable
                        data={batchFormObject.batch_numbers || []}
                        columns={batchNumbersColumns}
                        editable={true}
                        updateData={(data) => {

                        }}
                        actions={true}
                        actionFunctions={[{
                            label: 'Delete',
                            key: 'delete',
                            fn: ({ data, updateData, row, row_index }) => {
                                let newData = [...data];
                                newData.splice(row_index, 1);
                                batchSetFormObject({
                                    batch_numbers: newData
                                })
                            }
                        }]}
                        useForm={useFormObject}
                        tableKey={'batch_numbers'}
                    />

                    <View marginT-20>
                        <Button
                            label="Add Batch Number"
                            onPress={() => {
                                const arr = [...(batchFormObject.batch_numbers || [])];
                                arr.push({
                                    batch_number: '',
                                    quantity: 0
                                });
                                batchSetFormObject({
                                    batch_numbers: arr
                                })
                            }}
                        />
                    </View>

                </CustomModal>

                {track_batch_number ?
                    <TouchableOpacity onPress={() => setBatchNumberModalVisible(true)}>
                        <Text>From {batch_numbers ? batch_numbers.length : 0} Batches</Text>
                    </TouchableOpacity>
                    : null}
            </View>
        </>
    }

    const adjustedItemColumns = [
        {
            "field": "item_id",
            "label": "Item",
            "editable": true,
            "type": "select",
            "options": response.Items || [],
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {

            },
            "visible": true,
            "order": 3,
            "width": 250,
        }, {
            "field": "track_inventory",
            "label": "Track Inventory",
            "editable": true,
            "type": "boolean",
            "visible": false,
            "order": 8
        }, {
            "field": "track_serial_number",
            "label": "Track Serial Number",
            "editable": true,
            "type": "boolean",
            "visible": false,
            "order": 9
        }, {
            "field": "quantity",
            "label": "Quantity",
            "editable": true,
            "type": "number",
            "visible": true,
            "width": 100,
            "order": 12,
            "onChange": ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {

            },
            "debounce": 500
        }, {
            "field": "item_price",
            "label": "Item Price",
            "editable": true,
            "type": "number",
            "visible": true,
            "order": 16,
            "onChange": ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {

            },
            "debounce": 500
        }, {
            "field": "serial_numbers",
            "label": "Tracking",
            "editable": true,
            "type": "custom",
            "visible": true,
            "order": 20,
            "component": TrackingComponent,
        }, {
            "field": "item_total",
            "label": "Item Total",
            "editable": false,
            "type": "number",
            "visible": false,
            "order": 21,
            "width": 110
        }, {
            "field": "action_column",
            "label": "",
            "editable": true,
            "type": "custom",
            "width": 80,
            "component": ({ field, useForm, tKey, tIndex }) => {
                const getObj = useForm((state) => state.getFormObject);
                const setObj = useForm((state) => state.setFormObject);
                const setArrValue = useForm((state) => state.setFormObjectArray);

                return <View style={{ flexDirection: 'row' }}>
                    <TouchableOpacity onPress={() => {
                        const obj = getObj();
                        const arr = [...obj[tKey]];
                        arr.splice(0, 1);
                        setObj({ [tKey]: arr });
                    }}>
                        <Text>Delete</Text>
                    </TouchableOpacity>
                </View>
            },
            "visible": true,
            "order": 22
        }];

    adjustedItemColumns && adjustedItemColumns.map((column) => {
        column.Header = column.label;
        column.accessor = column.field;
    });

    const formFields = [
        {
            label: 'Date',
            key: 'date',
            type: 'date',
            placeholder: 'Enter the date',
            visible: true,
            width: '25%',
        },
        {
            label: 'Number',
            key: 'number',
            type: 'text',
            placeholder: 'Enter the number',
            visible: true,
            width: '25%',
        },
        {
            label: 'Status',
            key: 'status',
            type: 'select',
            placeholder: 'Select the status',
            visible: true,
            width: '25%',
            options: [
                { label: 'Draft', value: 'draft' },
                { label: 'Sent', value: 'sent' },
                { label: 'Paid', value: 'paid' },
                { label: 'Partial', value: 'partial' },
                { label: 'Void', value: 'void' },
            ],
        },
        {
            label: 'Location',
            key: 'location_id',
            type: 'select',
            placeholder: 'Select the location',
            visible: true,
            width: '25%',
            options: response.locations || [],
        },
        {
            label: 'Account',
            key: 'account_id',
            type: 'select',
            placeholder: 'Select the account',
            visible: true,
            width: '25%',
            options: response.accounts || [],
        },
        {
            label: 'Reason',
            key: 'reason',
            type: 'text',
            placeholder: 'Enter the reason',
            visible: true,
            width: '25%',
        },
        {
            label: '',
            key: 'adjusted_items',
            type: 'table',
            placeholder: '',
            visible: true,
            width: '100%',
            editable: true,
            columns: adjustedItemColumns
        },
        addButton('Add Adjusted Item', 'add_adjusted_item', 'adjusted_items')
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                viewField: true,
            });
        });
    }

    return formFields;
}

export const itemAdjustmentMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const itemAdjustmentMain = {
    fields: itemAdjustmentfields,
    mobileCard: null
}
