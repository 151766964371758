//
import { observer } from "mobx-react-lite";
import React, {
  FC,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  ImageStyle,
  Platform,
  ScrollView,
  TextStyle,
  ViewStyle,
  SafeAreaView,
  FlatList,
  Alert,
  Dimensions,
  RefreshControl,
} from "react-native";
import { api } from "../../../../../services/api";
import { colors, spacing } from "../../../../../theme";
import {
  View,
  Text,
  TextField,
  Card,
  Checkbox,
  DateTimePicker,
  Picker,
  SegmentedControl,
  Assets,
  Spacings,
  Colors,
  BorderRadiuses,
  Timeline,
  GridList,
  GridView,
  Modal,
  SortableList,
  SortableGridList,
  Image,
  Button,
  TabController,
  Avatar,
  TouchableOpacity
} from "react-native-ui-lib";
import DynamicForm, {
  Col,
  CustomModal,
  CustomNumberInput,
  CustomSelect,
  DangerButton,
  Heading,
  Row,
  SecondaryButton,
  CustomTextField,
  Label,
  CustomCheckbox,
} from "../../../../../mycomponents/DynamicForm";
import DynamicTable from "../../../../../mycomponents/DynamicTable";
import { useNavigate, useParams } from "react-router-dom";
import { useStores } from "../../../../../mycomponents/state";
import { useNavigationState, useNavigation } from "@react-navigation/native";
import { GlobalMasterStore } from "../../../../../mycomponents/state/PermissionStore";
import dayjs from "dayjs";
import { Camera, CameraType } from "expo-camera";
import Svg, { G, Path } from "react-native-svg";
import Config from "../../../../../config";
import { Icon } from "react-native-eva-icons";
import { CartComponent, WishlistComponent } from "./Checkout";
const isDesktop = Dimensions.get("window").width >= 768;

const cartIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="#000000"
    height="18px"
    width="18px"
    version="1.1"
    id="Capa_1"
    viewBox="0 0 483.1 483.1"
    {...props}
  >
    <G>
      <Path d="M434.55,418.7l-27.8-313.3c-0.5-6.2-5.7-10.9-12-10.9h-58.6c-0.1-52.1-42.5-94.5-94.6-94.5s-94.5,42.4-94.6,94.5h-58.6   c-6.2,0-11.4,4.7-12,10.9l-27.8,313.3c0,0.4,0,0.7,0,1.1c0,34.9,32.1,63.3,71.5,63.3h243c39.4,0,71.5-28.4,71.5-63.3   C434.55,419.4,434.55,419.1,434.55,418.7z M241.55,24c38.9,0,70.5,31.6,70.6,70.5h-141.2C171.05,55.6,202.65,24,241.55,24z    M363.05,459h-243c-26,0-47.2-17.3-47.5-38.8l26.8-301.7h47.6v42.1c0,6.6,5.4,12,12,12s12-5.4,12-12v-42.1h141.2v42.1   c0,6.6,5.4,12,12,12s12-5.4,12-12v-42.1h47.6l26.8,301.8C410.25,441.7,389.05,459,363.05,459z" />
    </G>
  </Svg>
);

const CartIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="0 0 25 25"
    fill="none"
    {...props}
  >
    <Path
      d="M2.083 3.125l.277.092c1.375.458 2.062.687 2.455 1.233.393.546.393 1.27.393 2.72v2.726c0 2.946 0 4.42.916 5.334.915.916 2.389.916 5.335.916h2.083m6.25 0h-2.084"
      stroke={props.color ? props.color : "#000"}
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <Path
      d="M7.813 18.75a1.563 1.563 0 110 3.125 1.563 1.563 0 010-3.125zm9.375 0a1.563 1.563 0 110 3.126 1.563 1.563 0 010-3.126z"
      stroke={props.color ? props.color : "#000"}
      strokeWidth={1.5}
    />
    <Path
      d="M5.208 6.25h3.126m-2.605 7.292h10.96c1 0 1.5 0 1.891-.259.391-.258.588-.716.982-1.635l.447-1.042c.843-1.969 1.264-2.95.802-3.654-.464-.702-1.535-.702-3.675-.702H12.5"
      stroke={props.color ? props.color : "#000"}
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </Svg>
);

const WishListIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="0 0 25 25"
    fill="none"
    {...props}
  >
    <Path
      d="M12.5 5.73l-.562.54a.78.78 0 001.125 0l-.563-.54zM7.293 17.103a.782.782 0 10-.994 1.209l.994-1.209zM2.44 13.97a.78.78 0 001.37-.75l-1.37.75zm.425-4.452c0-2.24 1.265-4.12 2.994-4.91 1.679-.767 3.935-.564 6.079 1.663l1.125-1.082c-2.542-2.643-5.496-3.08-7.855-2.002-2.306 1.056-3.906 3.506-3.906 6.33h1.563zM8.85 20.312c.535.421 1.107.87 1.688 1.209.58.338 1.242.614 1.961.614v-1.562c-.323 0-.702-.125-1.173-.401-.472-.275-.96-.654-1.508-1.087l-.968 1.227zm7.298 0c1.485-1.171 3.386-2.513 4.875-4.191 1.518-1.708 2.674-3.826 2.674-6.603h-1.562c0 2.29-.938 4.053-2.28 5.565-1.37 1.542-3.095 2.758-4.675 4.002l.968 1.227zM23.7 9.518c0-2.825-1.6-5.275-3.907-6.33-2.359-1.079-5.31-.642-7.854 2l1.125 1.083c2.143-2.226 4.4-2.43 6.079-1.663 1.728.79 2.994 2.67 2.994 4.91h1.562zm-8.518 9.567c-.547.433-1.036.812-1.508 1.087-.47.275-.85.4-1.173.4v1.563c.719 0 1.381-.276 1.962-.614.58-.34 1.153-.788 1.687-1.209l-.968-1.227zm-5.362 0c-.83-.653-1.672-1.278-2.526-1.98l-.994 1.207c.865.712 1.78 1.391 2.552 2l.969-1.227h-.001zM3.81 13.221a7.554 7.554 0 01-.946-3.703H1.302c0 1.706.438 3.172 1.138 4.452l1.37-.75z"
      fill={props.color ? props.color : "#000"}
    />
  </Svg>
);

const UserIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="0 0 25 25"
    fill="none"
    {...props}
  >
    <Path
      d="M12.5 10.417a4.167 4.167 0 100-8.333 4.167 4.167 0 000 8.333z"
      stroke={props.color ? props.color : "#000"}
      strokeWidth={1.5}
    />
    <Path
      d="M20.831 18.75c.002-.17.002-.345.002-.52 0-2.59-3.731-4.688-8.333-4.688s-8.334 2.099-8.334 4.687c0 2.589 0 4.688 8.334 4.688 2.324 0 4-.164 5.208-.456"
      stroke={props.color ? props.color : "#000"}
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </Svg>
);

const Header = ({ theme = 'transparent' }) => {
  // const name = Platform.OS != "web" ? Config.HOST_URL : window?.location?.host.includes("localhost") ? Config.HOST_URL : window?.location?.host;

  const name = "ikaki.inkapps.io";

  const cart = GlobalMasterStore((state) => state.cart);
  const setCart = GlobalMasterStore((state) => state.setCart);

  const wishList = GlobalMasterStore((state) => state.wishList);
  const setWishList = GlobalMasterStore((state) => state.setWishList);

  const [cartModal, setCartModal] = useState(false);
  const [wishListModal, setWishListModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);


  const menu = [
    { name: "Lehengas", path: "shop/products", params: { category_name: "Lehengas" } },
    { name: "Kurta Sets", path: "shop/products", params: { category_name: "Kurta Sets" } },
    { name: "Coords", path: "shop/products", params: { category_name: "Coords" } },
    { name: "Dresses", path: "shop/products", params: { category_name: "Dresses" } },
  ]

  let navigation = useNavigation();
  let navigate = null;

  if (Platform.OS === "web") {
    navigate = useNavigate();
  }

  const navigationFn = (path, params) => {
    if (Platform.OS === "web") {
      let paramsString = "";
      if (params) {
        paramsString =
          "?" +
          Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&");
      }
      navigate("/" + path + paramsString);
    } else {
      if (path.includes("report/")) {
        navigation.navigate("report", {
          ...params,
          name: path.replace("report/", ""),
        });
      } else {
        navigation.navigate(path, params);
      }
    }
  };

  const MenuItems = <View>
    <View style={{ width: "100%", alignItems: "center" }}>
      <View style={{ flexDirection: isDesktop ? "row" : "column" }}>
        {menu.map((item, index) => {
          return <TouchableOpacity
            key={index}
            onPress={() => {
              navigationFn(item.path, item.params);
            }}
            style={{
              paddingHorizontal: 15,
              paddingVertical: isDesktop ? 0 : 10,
            }}
          >
            <Text
              style={{
                color: isDesktop ? theme == 'white_bg' ? "#1c1c1c" : "white" : "#1c1c1c",
                fontFamily: "Sen_700Bold",
              }}
            >
              {item.name}
            </Text>
          </TouchableOpacity>;
        })}
        {!isDesktop && <TouchableOpacity
          onPress={() => {
            setWishListModal(true);
          }}
          style={{
            paddingHorizontal: 15,
            paddingVertical: isDesktop ? 0 : 10,
          }}
        >
          <Text
            style={{
              color: isDesktop ? theme == 'white_bg' ? "#1c1c1c" : "white" : "#1c1c1c",
              fontFamily: "Sen_700Bold",
            }}
          >
            Wish List
          </Text>
        </TouchableOpacity>}

        {!isDesktop && <TouchableOpacity
          onPress={() => {
            setCartModal(true);
          }}
          style={{
            paddingHorizontal: 15,
            paddingVertical: isDesktop ? 0 : 10,
          }}
        >
          <Text
            style={{
              color: isDesktop ? theme == 'white_bg' ? "#1c1c1c" : "white" : "#1c1c1c",
              fontFamily: "Sen_700Bold",
            }}
          >
            My Cart
          </Text>
        </TouchableOpacity>}


      </View>
    </View>
  </View>;

  return (
    <>
      <View
        style={{
          width: "100%",
          backgroundColor: "transparent",
          paddingVertical: 10,
          paddingTop: 20,
          alignItems: "center",
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 1000
        }}
      >
        <Row>
          <Col width="100%">
            <View
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {isDesktop ? MenuItems : <>
                <TouchableOpacity onPress={() => {
                  setOpenDrawer(true);
                }}>
                  <Icon name="menu-outline" width={25} height={25} fill={theme == 'white_bg' ? "#1c1c1c" : "white"} />
                </TouchableOpacity>
                <CustomModal
                  visible={openDrawer}
                  onClose={() => {
                    setOpenDrawer(false);
                  }}
                  title="Menu"
                  width={isDesktop ? 500 : "100%"}
                  drawer={true}
                  placement="left"
                >
                  <View padding-20>
                    {MenuItems}
                  </View>
                </CustomModal>
              </>}

              <View
                style={{
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <TouchableOpacity onPress={() => {
                  navigationFn("");
                }}>

                  <View row>
                    <Image source={{
                      uri: require('./Images/navbar-logo.png')
                    }} style={{ width: 150, height: 55, marginRight: 5 }} />
                    {/* <Text
                      style={{
                        color: theme == 'white_bg' ? "#1c1c1c" : "white",
                        fontSize: 30,
                      }}
                    >
                      IKAKI
                    </Text> */}
                  </View>

                </TouchableOpacity>
              </View>

              <View row right>
                <TouchableOpacity
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                  onPress={() => {
                    navigationFn("shop/products", {
                      show_search: true,
                    });
                  }}
                >
                  <View style={{ marginRight: 10 }}>
                    <Icon name="search-outline" width={25} height={25} fill={theme == 'white_bg' ? "tan" : "white"} />
                  </View>
                  {isDesktop && <View style={{ marginRight: 10 }}>
                    <Text style={{
                      color: theme == 'white_bg' ? "#1c1c1c" : "white",
                      fontFamily: "Sen_700Bold",
                    }}>Search</Text>
                  </View>}
                </TouchableOpacity>

                <CustomModal
                  visible={wishListModal}
                  onClose={() => {
                    setWishListModal(false);
                  }}
                  title="Cart"
                  width={isDesktop ? 500 : "100%"}
                  drawer={true}
                >
                  <View padding-20>
                    <WishlistComponent wishList={wishList} setWishList={setWishList} cart={cart} setCart={setCart} />
                  </View>
                </CustomModal>

                {isDesktop && <TouchableOpacity
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                  onPress={() => {
                    setWishListModal(true);
                  }}
                >
                  {/* <View style={{ marginRight: 10 }}>
                    <Icon
                      name="shopping-bag-outline"
                      width={25}
                      height={25}
                      fill="#000000"
                    />
                  </View> */}

                  <WishListIcon color={theme == 'white_bg' ? "tan" : "white"} style={{ marginRight: 10 }} />
                  <View style={{ marginRight: 28 }}>
                    <Text style={{
                      color: theme == 'white_bg' ? "#1c1c1c" : "white",
                      fontFamily: "Sen_700Bold",
                    }}>Wish List</Text>
                  </View>

                  <View
                    style={{
                      backgroundColor: "tan",
                      padding: 3,
                      paddingHorizontal: 8,
                      marginRight: 15,
                      borderRadius: 5,
                      position: "absolute",
                      top: -8,
                      right: -8,
                    }}
                  >
                    <Text style={{ color: "white", fontSize: 9 }}>
                      {wishList ? wishList.length : 0}
                    </Text>
                  </View>
                </TouchableOpacity>}

                <CustomModal
                  visible={cartModal}
                  onClose={() => {
                    setCartModal(false);
                  }}
                  title="Cart"
                  width={isDesktop ? 500 : "100%"}
                  drawer={true}
                >
                  <View padding-20>
                    <CartComponent cart={cart} setCart={setCart} />
                  </View>
                </CustomModal>

                {isDesktop && <TouchableOpacity
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                  onPress={() => {
                    setCartModal(true);
                  }}
                >
                  <View style={{ marginRight: 10 }}>
                    {/* <Icon
                      name="shopping-cart-outline"
                      width={25}
                      height={25}
                      fill="#000000"
                    /> */}
                    {/* <img src={user} style={{ width: 25, height: 25 }} /> */}
                    {/* {} */}
                    <CartIcon color={theme == 'white_bg' ? "tan" : "white"} />
                  </View>
                  <View style={{ marginRight: 20 }}>
                    <Text style={{
                      color: theme == 'white_bg' ? "#1c1c1c" : "white",
                      fontFamily: "Sen_700Bold",
                    }}>My Cart</Text>
                  </View>

                  <View
                    style={{
                      backgroundColor: "tan",
                      padding: 3,
                      paddingHorizontal: 8,
                      marginRight: 15,
                      borderRadius: 5,
                      position: "absolute",
                      top: -8,
                      right: -16,
                    }}
                  >
                    <Text style={{ color: "white", fontSize: 9 }}>
                      {cart ? cart.length : 0}
                    </Text>
                  </View>
                </TouchableOpacity>}
              </View>
            </View>
          </Col>
        </Row>
      </View>
    </>
  );
};

export default Header;
