// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, TouchableOpacity } from 'react-native-ui-lib';
import DynamicForm, { Col, CustomModal, CustomNumberInput, CustomSelect, DangerButton, PrimaryButton, Row, SecondaryButton } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../mycomponents/state"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import LayoutScreen from "../../../../../mycomponents/CRUD/LayoutScreen"
import FormScreen from "../../../../../mycomponents/CRUD/FormScreen"
import {
    LineChart,
    BarChart,
    PieChart,
    ProgressChart,
    ContributionGraph,
    StackedBarChart
} from "react-native-chart-kit";
import { QueryBuilder } from 'react-querybuilder';
import { QueryBuilderNative } from '@react-querybuilder/native';
import { Icon } from "react-native-eva-icons"
import { GlobalModalStore } from "../../../../../mycomponents/state/ModalStore"
import { GlobalMasterStore } from "../../../../../mycomponents/state/PermissionStore"
import { App } from "antd"

const FieldSelector = (props) => {
    const options = props.options;

    return (
        <CustomSelect
            placeholder="Select a field"
            value={props.value}
            onChange={(value) => {
                props.handleOnChange(value);
            }}
            options={options}
        />
    );
};

const operatorSelector = (props) => {
    const options = props.options;
    return (
        <CustomSelect
            placeholder="Select an operator"
            value={props.value}
            onChange={(value) => {
                props.handleOnChange(value);
            }}
            options={options}
        />
    );
}


const ApprovalRequestScreen = observer(function ApprovalRequestScreen(props) {
    const setOpenModal = GlobalModalStore((state) => state.setOpenModal);
    const permissions = GlobalMasterStore((state) => state.permissions);

    const { message, modal } = App.useApp();

    let navigation = useNavigation();
    let navigate = null;

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.navigate("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.navigate(path, params);
            }
        }
    };

    const ApprovalRequestCard = (props) => {
        const radius = 10;
        const { data } = props;

        const readableModuleName = data.module_name?.replace(/([A-Z])/g, ' $1').trim();

        return (<Card flex className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap', borderRadius: radius, width: '100%' }}>
            <View padding-10 flex>
                <View marginB-10 row spread style={{ width: '100%' }}>
                    <View center backgroundColor="#FF9595" style={{ width: 60, height: 60, borderRadius: 10 }}>
                        <Text style={{ color: 'white', fontSize: 20 }}>{data.module_name[0]}</Text>
                    </View>

                    <View flex marginL-10>
                        <Text style={{ marginBottom: 5, fontSize: 16, fontFamily: 'SourceSansProBold' }}>
                            {readableModuleName} - {data.type}
                        </Text>
                        <View>
                            <Text lightCardTitle>Created by: </Text>
                            <Text darkCardTitle>
                                {data.created_by_employee?.name}
                            </Text>
                        </View>
                    </View>
                </View>

                <View marginB-10 style={{ width: '100%', height: 4, backgroundColor: '#EFF5FF', borderRadius: 4 }} />

                <View row spread style={{ width: '100%' }}>
                    <View>
                        <View row marginR-10>
                            <SecondaryButton label="View" onPress={() => {
                                setOpenModal({
                                    [data.module_name]: {
                                        isVisible: true,
                                        moduleName: data.module_name,
                                        id: data.module_id,
                                        isEdit: false,
                                        viewOnly: true,
                                    }
                                })
                            }} />
                        </View>
                    </View>
                    <View row>
                        <View row marginR-10>
                            <SecondaryButton label="Approve" onPress={() => {
                                approveFn();

                                async function approveFn() {
                                    const response = await api.customRoute("ApprovalRequests/action", {
                                        action: "approve",
                                        _id: data._id,
                                    });

                                    if (response.success) {
                                        message.success("Approved successfully");
                                        getPendingApprovalRequests();
                                    } else {
                                        message.error("Error in approving");
                                    }
                                }
                            }} />
                        </View>
                        <View row>
                            <DangerButton label="Reject" onPress={() => {
                                rejectFn()

                                async function rejectFn() {
                                    const response = await api.customRoute("ApprovalRequests/action", {
                                        action: "reject",
                                        _id: data._id,
                                    })

                                    if (response.success) {
                                        message.success("Rejected successfully")
                                        getPendingApprovalRequests()
                                    } else {
                                        message.error("Error in rejecting")
                                    }
                                }
                            }} />
                        </View>
                    </View>
                </View>
            </View>
        </Card>);
    };

    const [approvalRequests, setApprovalRequests] = useState([]);

    const getPendingApprovalRequests = async () => {
        const response = await api.customRoute("ApprovalRequests/action", {
            action: "getPendingApprovalRequests",
        });
        setApprovalRequests(response.data);
    };

    useEffect(() => {
        getPendingApprovalRequests();
    }, []);

    return (
        <LayoutScreen>
            <View flex>
                <ScrollView>
                    <Card margin-20>
                        <View padding-15 margin-10 marginB-0 style={{ borderRadius: 10, borderBottomEndRadius: 0, borderBottomStartRadius: 0 }}>
                            <View spread row>
                                <Text h1>Approval Requests</Text>
                            </View>
                        </View>

                        {approvalRequests.length > 0 ? <View marginL-10 paddingH-15 paddingT-5>
                            <Row style={{ width: '100%' }}>
                                {approvalRequests.map((approvalRequest, index) => <Col key={index.toString()} width="50%">
                                    <ApprovalRequestCard data={approvalRequest} />
                                </Col>)}
                            </Row>
                        </View> : <View center padding-20>
                            <Text>No pending approval requests</Text>
                        </View>}
                    </Card>
                </ScrollView>
            </View>
        </LayoutScreen>
    )
})

export default ApprovalRequestScreen

const $container = {
    flex: 1,
}