"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LayoutStoreModel = void 0;
var _mobxStateTree = require("mobx-state-tree");
var LayoutStoreModel = exports.LayoutStoreModel = _mobxStateTree.types.model("LayoutStore").props({
  sideBarCollapsed: _mobxStateTree.types.optional(_mobxStateTree.types["boolean"], false),
  sideBarOpen: _mobxStateTree.types.optional(_mobxStateTree.types["boolean"], false),
  sideBarPosition: _mobxStateTree.types.optional(_mobxStateTree.types.string, "left"),
  currentTab: _mobxStateTree.types.optional(_mobxStateTree.types.string, "none"),
  darkBg: _mobxStateTree.types.optional(_mobxStateTree.types["boolean"], false)
}).views(function (store) {
  return {
    get isSideBarCollapsed() {
      return store.sideBarCollapsed;
    },
    get isSideBarOpen() {
      return store.sideBarOpen;
    },
    get getSideBarPosition() {
      return store.sideBarPosition;
    },
    get getCurrentTab() {
      return store.currentTab;
    },
    get isDarkBg() {
      return store.darkBg;
    }
  };
}).actions(function (store) {
  return {
    toggleSideBarCollapsed: function toggleSideBarCollapsed() {
      store.sideBarCollapsed = !store.sideBarCollapsed;
    },
    toggleSideBarOpen: function toggleSideBarOpen() {
      store.sideBarOpen = !store.sideBarOpen;
    },
    closeSideBar: function closeSideBar() {
      store.sideBarOpen = false;
    },
    setSideBarPosition: function setSideBarPosition(position) {
      store.sideBarPosition = position;
    },
    setCurrentTab: function setCurrentTab(tab) {
      store.currentTab = tab;
    },
    setDarkBg: function setDarkBg(darkBg) {
      store.darkBg = darkBg;
    }
  };
});