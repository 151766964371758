import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";

export const tcsfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
    const formFields = [
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter the name',
            visible: true,
            width: '25%',
        },
        {
            label: 'Type',
            key: 'type',
            type: 'select',
            placeholder: 'Select the type',
            visible: true,
            width: '25%',
            options: [
                { label: 'TCS', value: 'TCS' },
                { label: 'TDS', value: 'TDS' },
            ],
        },
        {
            label: 'Percentage',
            key: 'percentage',
            type: 'number',
            placeholder: 'Enter the percentage',
            visible: true,
            width: '25%',
        },
        {
            label: 'Input Account',
            key: 'input_tcs_id',
            type: 'select',
            placeholder: 'Select the input tcs',
            visible: true,
            width: '25%',
            options: response.input_accounts || [],
        },
        {
            label: 'Output Account',
            key: 'output_tcs_id',
            type: 'select',
            placeholder: 'Select the output tcs',
            visible: true,
            width: '25%',
            options: response.output_accounts || [],
        },
        {
            label: 'Nature of Collection',
            key: 'nature_of_collection',
            type: 'select',
            placeholder: 'Enter the nature of collection',
            visible: true,
            width: '25%',
            options: [],
        },
        {
            label: 'Is Default',
            key: 'is_default',
            type: 'checkbox',
            placeholder: 'Select if default',
            visible: true,
            width: '25%',
        },
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                viewField: true,
            });
        });
    }

    return formFields;
}

export const tcsMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const tcsMain = {
    fields: tcsfields,
    mobileCard: null
}




