import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";

export const contactCategoryfields = (response) => {
    const formFields = [
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Name',
            visible: true,
            width: '25%'
        },
        {
            label: 'Type',
            key: 'type',
            type: 'select',
            placeholder: 'Type',
            visible: true,
            width: '25%',
            options: [
                { label: 'Item', value: 'item' },
                { label: 'Contact', value: 'contact' }
            ],
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                setObj({
                    updateVisiblity: Math.random(),
                })
            }
        },
        {
            label: 'Image',
            key: 'image_url',
            type: 'upload',
            visible: (connectedObj) => connectedObj.type === 'item',
            width: '100%',
            mimeType: "image/*",
            moduleName: "Categories",
        },
    ]

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%'
            });
        });
    }

    return formFields;
}

export const contactCategoryMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const contactCategoryMain = {
    fields: contactCategoryfields,
    mobileCard: null
}