import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";
import { addButton } from "../hrms/shiftModel";

export const projectFields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
    const formFields = [
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter the name',
            visible: true,
            width: '25%',
            viewField: true
        },
        {
            label: 'Avatar',
            key: 'avatar',
            type: 'picture',
            placeholder: 'Enter the avatar',
            visible: true,
            width: '25%',
        },
        {
            label: 'Start Date',
            key: 'start_date',
            type: 'date',
            placeholder: 'Enter the start date',
            visible: true,
            width: '25%',
            viewField: true
        },
        {
            label: 'Due Date',
            key: 'due_date',
            type: 'date',
            placeholder: 'Enter the due date',
            visible: true,
            width: '25%',
            viewField: true
        },
        {
            label: 'Is Active',
            key: 'is_active',
            type: 'checkbox',
            placeholder: 'Enter the is active',
            visible: true,
            width: '25%',
        },
        {
            label: 'Statuses',
            key: 'statuses',
            type: 'table',
            placeholder: 'Enter the statuses',
            visible: true,
            width: '100%',
            editable: true,
            columns: [
                {
                    label: 'Order',
                    Header: 'Order',
                    key: 'order',
                    accessor: 'order',
                    type: 'text',
                    placeholder: 'Enter the name',
                    visible: true,
                    editable: true,
                },
                {
                    label: 'Name',
                    Header: 'Name',
                    key: 'name',
                    accessor: 'name',
                    type: 'text',
                    placeholder: 'Enter the name',
                    visible: true,
                    editable: true,
                },
                {
                    label: 'Color',
                    Header: 'Color',
                    key: 'color',
                    accessor: 'color',
                    type: 'text',
                    placeholder: 'Enter the color',
                    visible: true,
                    editable: true,
                },
                {
                    label: 'Type',
                    Header: 'Type',
                    key: 'type',
                    accessor: 'type',
                    type: 'select',
                    placeholder: 'Enter the type',
                    visible: true,
                    editable: true,
                    options: [
                        {
                            label: 'Open',
                            value: 'open',
                        },
                        {
                            label: 'Completed',
                            value: 'completed',
                        },
                        {
                            label: 'Closed',
                            value: 'closed',
                        },
                    ]
                }
            ]
        },
        addButton('Add Status', 'add_status', 'statuses'),
        {
            label: 'Lists',
            key: 'lists',
            type: 'table',
            placeholder: 'Enter the lists',
            visible: true,
            width: '100%',
            viewField: true,
            editable: true,
            columns: [
                {
                    label: 'Name',
                    Header: 'Name',
                    key: 'name',
                    accessor: 'name',
                    type: 'text',
                    editable: view ? false : true,
                    placeholder: 'Enter the name',
                    visible: true,
                },
                {
                    'label': 'Order',
                    'Header': 'Order',
                    'key': 'order',
                    'accessor': 'order',
                    'type': 'number',
                    'editable': true,
                    'visible': view ? false : true,
                }
            ]
        },
        addButton('Add List', 'add_list', 'lists'),
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                viewField: true,
            });
        });
    }

    return formFields;
}

export const projectMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const projectMain = {
    fields: projectFields,
    mobileCard: null
}

