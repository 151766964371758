import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";

export const bankTransactionfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
    const formFields = [
        {
            label: 'bankTransaction',
            key: 'bankTransaction_id',
            type: 'select',
            placeholder: 'Select the bankTransaction',
            visible: true,
            width: '25%',
            options: response.bankTransactions || [],
        },
        {
            label: 'Date',
            key: 'date',
            type: 'date',
            placeholder: 'Enter the date',
            visible: true,
            width: '25%',
        },
        {
            label: 'Description',
            key: 'description',
            type: 'text',
            placeholder: 'Enter the description',
            visible: true,
            width: '25%',
        },
        {
            label: 'Reference Number',
            key: 'reference_number',
            type: 'text',
            placeholder: 'Enter the reference number',
            visible: true,
            width: '25%',
        },
        {
            label: 'Debit or Credit',
            key: 'debit_or_credit',
            type: 'select',
            placeholder: 'Select debit or credit',
            visible: true,
            width: '25%',
            options: [
                { label: 'Debit', value: 'Debit' },
                { label: 'Credit', value: 'Credit' },
            ],
        },
        {
            label: 'Debit',
            key: 'debit',
            type: 'number',
            placeholder: 'Enter the debit amount',
            visible: true,
            width: '25%',
        },
        {
            label: 'Credit',
            key: 'credit',
            type: 'number',
            placeholder: 'Enter the credit amount',
            visible: true,
            width: '25%',
        },
        {
            label: 'Matched',
            key: 'matched',
            type: 'switch',
            placeholder: 'Enter the matched status',
            visible: true,
            width: '25%',
        },
        {
            label: 'Match History',
            key: 'match_history',
            type: 'text',
            placeholder: 'Enter the match history',
            visible: true,
            width: '25%',
        },
    ];

    const account_card_items = {
        heading: {
            key: 'account_id',
        },
        image: {
            key: 'image',
        },
        left_first: {
            key: 'date',
            label: 'Date'
        },
        right_first: {
            key: 'debit_or_credit',
            label: 'Debit or Credit'
        },
        // left_second: {
        //     key: 'product_type',
        //     label: 'Type'
        // },
        // right_second: {
        //     key: 'hsn_or_sac',
        //     label: 'HSN or SAC'
        // },
        left_third: {
            key: 'debit',
            label: 'Debit'
        },
        right_third: {
            key: 'credit',
            label: 'Credit'
        },
        status: {
            label: 'Stock Required',
            color: '#dc3f4d',
        }
    };


    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                viewField: true,
            });
        });
    }

    return formFields;
}

export const bankTransactionMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const bankTransactionMain = {
    fields: bankTransactionfields,
    mobileCard: null
}