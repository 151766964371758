import React, { useEffect } from "react";
import { View, Card, Text, Image, TouchableOpacity } from "react-native-ui-lib";
import { Col, CustomCheckbox, Row } from "../../mycomponents/DynamicForm";
import { Platform } from "react-native";
import ImageView from "react-native-image-viewing";

export const reimbursementClaimsfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

  const CustomImageView = (props) => {
    const [visible, setIsVisible] = React.useState(false);

    const images = [{
      uri: props.value,
    }];

    return <View row spread style={{
      flexWrap: 'wrap',
    }}>
      <TouchableOpacity
        onPress={() => setIsVisible(true)}
        marginR-5 marginB-5>
        <Image source={{ uri: props.value }} style={{
          width: 100,
          height: 100,
          resizeMode: 'cover',
        }} />
      </TouchableOpacity>
      <ImageView
        images={images}
        imageIndex={0}
        visible={visible}
        onRequestClose={() => setIsVisible(false)}
      />
    </View>
  }

  const formFields = [
    {
      type: 'divider',
      heading: 'Claim Details',
      visible: true,
      span: 24,
    },
    {
      label: 'Date',
      key: 'date',
      type: 'date',
      placeholder: 'Enter Date',
      visible: true,
      width: '25%',
      value: new Date()
    },
    {
      label: 'Employee',
      key: 'employee_id',
      type: 'select',
      placeholder: 'Enter Employee Id',
      visible: false,
      width: '25%',
      options: (response.Employees || []).map((item) => {
        return {
          ...item,
          label: item.name,
          value: item._id
        }
      }),
      value: globalData.employee_id ? response.Employees.find((item) => item._id == globalData.employee_id)?._id : null
    },
    {
      label: 'Category',
      key: 'reimbursement_category_id',
      type: 'select',
      placeholder: 'Enter Reimbursement Category',
      visible: true,
      width: '25%',
      options: (response.ReimbursementCategories || []).map((item) => {
        return {
          ...item,
          label: item.name,
          value: item._id
        }
      })
    },
    {
      label: 'Amount',
      key: 'amount',
      type: 'number',
      placeholder: 'Enter Amount',
      visible: true,
      width: '25%',
    },
    {
      label: 'Remarks',
      key: 'remarks',
      type: 'text',
      placeholder: 'Enter Remarks',
      visible: true,
      width: '25%',
    }, {
      label: 'Attachment',
      key: 'attachment',
      type: 'upload',
      placeholder: 'Enter Attachment',
      visible: true,
      width: '25%',
      mimeType: 'image/*',
      customView: CustomImageView,
      moduleName: 'ReimbursementClaims'
    }
  ];

  const CustomFields = response.CustomFields;

  if (CustomFields && CustomFields.length > 0) {
    CustomFields.forEach((field) => {
      formFields.push({
        label: field.name,
        key: 'cf.' + field.key,
        type: field.type,
        placeholder: field.placeholder,
        visible: true,
        width: '25%'
      });
    });
  }

  return formFields;
}

export const reimbursementClaimsMobileCard = (item, index, getFieldValue) => {
  return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
    <View>
      <Text>TEST</Text>
      <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
    </View>
  </Card>;
}

export const reimbursementClaimsMain = {
  fields: reimbursementClaimsfields,
  mobileCard: null
}