"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RootStoreModel = void 0;
var _ModalStore = require("./ModalStore");
var _mobxStateTree = require("mobx-state-tree");
var _AuthenticationStore = require("./AuthenticationStore");
var _FormStore = require("./FormStore");
var _LayoutStore = require("./LayoutStore");
var _FormObjectStore = require("./FormObjectStore");
/**
 * A RootStore model.
 */
var RootStoreModel = exports.RootStoreModel = _mobxStateTree.types.model("RootStore").props({
  authenticationStore: _mobxStateTree.types.optional(_AuthenticationStore.AuthenticationStoreModel, {}),
  modalStore: _mobxStateTree.types.optional(_ModalStore.ModalStoreModel, {}),
  formStore: _mobxStateTree.types.optional(_FormStore.FormStoreModel, {}),
  formObject: _mobxStateTree.types.optional(_FormObjectStore.FormObjectModel, {}),
  layoutStore: _mobxStateTree.types.optional(_LayoutStore.LayoutStoreModel, {})
});