import React, { useEffect } from "react";
import { View, Card, Text, TouchableOpacity } from "react-native-ui-lib";
import DynamicTable from "../../mycomponents/DynamicTable";
import { Icon } from "react-native-eva-icons";
import { create } from 'zustand'
import { Col, CustomSelect, CustomTextField, Label, Row } from "../../mycomponents/DynamicForm";

export const workoutPlanfields = (response) => {

  const WorkoutPlanExercises = ({ field, useForm }) => {
    const days = useForm((state) => state.formObject['days']);

    const setFormObject = useForm((state) => state.setFormObject);
    const setFormObjectArray = useForm((state) => state.setFormObjectArray);
    const getFormObject = useForm((state) => state.getFormObject);

    const getDayTitleByIndex = (index) => {
      let week = Math.floor(index / 7) + 1;
      let day = index % 7;

      let dayName = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

      return `Week ${week} - ${dayName[day]}`;
    }

    return (<>
      <View w-100 flex>
        <View w-100 flexG flex>
          {days && days.length > 0 && days.map((day, index) => {
            return <View w-100 key={index} style={{ marginBottom: 20 }}>

              <View style={{ marginTop: 10 }} />

              <Text tabHeading style={{ marginBottom: 5 }}>{getDayTitleByIndex(index)}</Text>

              <View style={{
                borderBottomColor: '#d9d9d9',
                borderBottomWidth: 1,
                width: '100%',
                marginBottom: 0,
                marginTop: 10,
                borderStyle: 'dashed',
              }} />

              <View w-100>
                {day.exercises && day.exercises.length > 0 && day.exercises.map((exercise, exerciseIndex) => {
                  return <View w-100 key={exerciseIndex}>
                    <Row w-100 style={{ alignItems: 'center' }} >
                      <Col width="22%">
                        {Label({ label: 'Exercise' })}
                        <CustomSelect label="Exercise" value={exercise.exercise} options={response.exercises || []}
                          onChange={(value) => {
                            let days = [...(getFormObject().days || [])];

                            days[index].exercises[exerciseIndex].exercise = value;
                            setFormObject({
                              days
                            })
                          }} />
                      </Col>
                      <Col width="22%">
                        {Label({ label: 'Sets' })}
                        <CustomTextField label="Sets" value={exercise.sets} onChange={(value) => {
                          let days = [...(getFormObject().days || [])];

                          days[index].exercises[exerciseIndex].sets = value;
                          setFormObject({
                            days
                          })
                        }} />
                      </Col>
                      <Col width="22%">
                        {Label({ label: 'Reps' })}
                        <CustomTextField label="Reps" value={exercise.reps} onChange={(value) => {
                          let days = [...(getFormObject().days || [])];

                          days[index].exercises[exerciseIndex].reps = value;
                          setFormObject({
                            days
                          })
                        }} />
                      </Col>
                      <Col width="22%">
                        {Label({ label: 'Rest' })}
                        <CustomTextField label="Rest" value={exercise.rest} onChange={(value) => {
                          let days = [...(getFormObject().days || [])];

                          days[index].exercises[exerciseIndex].rest = value;
                          setFormObject({
                            days
                          })
                        }} />
                      </Col>
                      <Col width="12%">
                        <TouchableOpacity
                          onPress={() => {
                            let days = [...(getFormObject().days || [])];

                            days[index].exercises.splice(exerciseIndex, 1);
                            setFormObject({
                              days
                            })
                          }}
                          style={{
                            padding: 9,
                            paddingLeft: 25,
                            paddingRight: 25,
                            borderWidth: 1,
                            borderColor: '#dcdcdc75',
                            borderStyle: 'solid',
                            borderRadius: 4,
                            marginTop: 25
                          }}
                        >
                          <Text style={{ color: '#b60505', fontFamily: 'SourceSansProSemiBold' }}>Remove</Text>
                        </TouchableOpacity>
                      </Col>
                    </Row>
                  </View>
                })}
              </View>

              <View row marginT-10>
                <TouchableOpacity
                  onPress={() => {
                    let days = [...(getFormObject().days || [])];

                    days[index].exercises = [...(days[index].exercises || []), {}];
                    setFormObject({
                      days
                    })
                  }}
                  style={{
                    marginRight: 10,
                    padding: 9,
                    paddingLeft: 25,
                    paddingRight: 25,
                    borderWidth: 1,
                    borderColor: '#dcdcdc75',
                    borderStyle: 'solid',
                    borderRadius: 4
                  }}
                >
                  <Text style={{ color: '#055eb6', fontFamily: 'SourceSansProSemiBold' }}>Add Exercise</Text>
                </TouchableOpacity>

                <TouchableOpacity
                  onPress={() => {
                    let days = [...(getFormObject().days || [])];
                    days.splice(index, 1);
                    setFormObject({
                      days
                    })
                  }}
                  style={{
                    padding: 9,
                    paddingLeft: 25,
                    paddingRight: 25,
                    border: '1px solid #dcdcdc75',
                    borderRadius: 4
                  }}
                >
                  <Text style={{ color: '#b60505', fontFamily: 'SourceSansProSemiBold' }}>Remove Day</Text>
                </TouchableOpacity>
              </View>
            </View>
          })}
        </View>
        <View>
          <View row>
            <TouchableOpacity
              row
              center
              style={{
                marginRight: 10,
                padding: 9,
                paddingLeft: 25,
                paddingRight: 25,
                borderWidth: 1,
                borderColor: '#dcdcdc75',
                borderStyle: 'solid',
                borderRadius: 4
              }}
              onPress={() => {
                let days = [...(getFormObject().days || [])];

                days.push({
                  exercises: [{

                  }]
                });
                setFormObject({
                  days
                })
              }}>
              <Icon name="plus-outline" height={13} fill="#055eb6" />
              <View marginL-5>
                <Text style={{ color: '#055eb6', fontFamily: 'SourceSansProSemiBold' }}>Add Day</Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </>)
  }


  const formFields = [
    {
      type: 'divider',
      heading: 'Workout Plan Details',
      visible: true,
      span: 24,
    },
    {
      label: 'Name',
      key: 'name',
      type: 'text',
      placeholder: 'Enter Workout Plan Name',
      visible: true,
      width: '25%',
      required: true,
    },
    {
      label: 'Ideal For',
      key: 'ideal_for',
      type: 'select',
      placeholder: 'Select Ideal For',
      visible: true,
      width: '25%',
      options: [
        { label: 'Beginner', value: 'Beginner' },
        { label: 'Intermediate', value: 'Intermediate' },
        { label: 'Advanced', value: 'Advanced' },
      ],
      multiple: true,
    },
    {
      label: 'Plan Type',
      key: 'plan_type',
      type: 'select',
      placeholder: 'Select Plan Type',
      visible: true,
      width: '25%',
      options: [
        { label: 'Weight Loss', value: 'Weight Loss' },
        { label: 'Weight Gain', value: 'Weight Gain' },
        { label: 'Muscle Gain', value: 'Muscle Gain' },
        { label: 'Strength Gain', value: 'Strength Gain' },
        { label: 'Endurance Gain', value: 'Endurance Gain' },
      ],
      multiple: true,
    },

    {
      type: 'divider',
      heading: 'Day Details',
      visible: true,
      width: '100%',
    },

    {
      type: 'custom',
      key: 'workout_plan_exercises',
      visible: true,
      width: '100%',
      component: WorkoutPlanExercises,
    }
  ];

  const CustomFields = response.CustomFields;

  if (CustomFields && CustomFields.length > 0) {
    CustomFields.forEach((field) => {
      formFields.push({
        label: field.name,
        key: 'cf.' + field.key,
        type: field.type,
        placeholder: field.placeholder,
        visible: true,
        width: '25%'
      });
    });
  }

  return formFields;
}

export const workoutPlanMobileCard = (item, index, getFieldValue) => {
  return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
    <View>
      <Text>TEST</Text>
      <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
    </View>
  </Card>;
}

export const workoutPlanMain = {
  fields: workoutPlanfields,
  mobileCard: null
}