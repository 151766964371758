import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";

export const gymClassfields = (response) => {
  const formFields = [
    {
      type: 'divider',
      heading: 'Class Details',
      visible: true,
      span: 24,
    },
    {
      label: 'Name',
      key: 'name',
      type: 'text',
      placeholder: 'Enter Name',
      visible: true,
      width: '25%',
    },

    {
      label: 'Start Time',
      key: 'start_time',
      type: 'time',
      placeholder: 'Enter Start Time',
      visible: true,
      width: '25%',
    },

    {
      label: 'End Time',
      key: 'end_time',
      type: 'time',
      placeholder: 'Enter End Time',
      visible: true,
      width: '25%',
    },

    {
      label: 'Trainer',
      key: 'trainer_id',
      type: 'select',
      placeholder: 'Enter Trainer',
      visible: true,
      width: '25%',
      options: response.Employees ? response.Employees.map((item) => {
        return { value: item._id, label: item.name }
      }) : []
    },

    {
      label: 'Days',
      key: 'days',
      type: 'select',
      placeholder: 'Enter Days',
      visible: true,
      width: '25%',
      options: [
        { value: 'Monday', label: 'Monday' },
        { value: 'Tuesday', label: 'Tuesday' },
        { value: 'Wednesday', label: 'Wednesday' },
        { value: 'Thursday', label: 'Thursday' },
        { value: 'Friday', label: 'Friday' },
        { value: 'Saturday', label: 'Saturday' },
        { value: 'Sunday', label: 'Sunday' },
      ],
      multiple: true
    },

    {
      label: 'Capacity',
      key: 'capacity',
      type: 'number',
      placeholder: 'Enter Capacity',
      visible: true,
      width: '25%',
    }

  ];

  const CustomFields = response.CustomFields;

  if (CustomFields && CustomFields.length > 0) {
    CustomFields.forEach((field) => {
      formFields.push({
        label: field.name,
        key: 'cf.' + field.key,
        type: field.type,
        placeholder: field.placeholder,
        visible: true,
        width: '25%'
      });
    });
  }

  return formFields;
}

export const gymClassMobileCard = (item, index, getFieldValue) => {
  return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
    <View>
      <Text>TEST</Text>
      <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
    </View>
  </Card>;
}

export const gymClassMain = {
  fields: gymClassfields,
  mobileCard: null
}