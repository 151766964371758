import React, { useEffect, useRef, useState } from "react";
import { View, Card, Text, TouchableOpacity, Button, Image, Colors } from "react-native-ui-lib";
import { CustomDatePicker, CustomDropDown, CustomModal, CustomNumberInput, CustomSelect, CustomSerialNumberTextArea, CustomTable, CustomTextField, DangerButton, Heading, PrimaryButton, SecondaryButton } from "../../mycomponents/DynamicForm";
import { ActivityIndicator, DeviceEventEmitter, Dimensions, Platform } from "react-native";
import WebView from "react-native-webview";
import { roundTo, roundToUp, roundToDown } from "round-to";
import DynamicTable from "../../mycomponents/DynamicTable";
import { create } from 'zustand'
import { addButton } from "../hrms/shiftModel";
import { Icon } from "react-native-eva-icons";
import * as FileSystem from 'expo-file-system';
import * as Sharing from "expo-sharing";
import * as Print from 'expo-print';
import { useNavigate } from "react-router-dom";
import { useNavigation } from "@react-navigation/native";
import { GlobalFormStore } from "../../mycomponents/state/FormStore";
import { GlobalModalStore } from "../../mycomponents/state/ModalStore";
import { api } from "../../services/api";
import PDFReader from 'rn-pdf-reader-js'
import moment from "moment";

const WhatsAppForm = (props) => {
    return <View>
        <Text>Whatsapp</Text>
    </View>
}

const useFormObject = create((set, get) => ({
    formObject: {},
    setFormObject: (keyValue) => set((state) => ({ formObject: { ...state.formObject, ...keyValue } })),
    setFormObjectArray: (array_key, array_index, field_key, value) => set((state) => {
        if (typeof field_key === 'object') {
            const newFormObject = { ...state.formObject };
            newFormObject[array_key][array_index] = { ...newFormObject[array_key][array_index], ...field_key };
            return { formObject: newFormObject };
        } else {
            const newFormObject = { ...state.formObject };
            // newFormObject[array_key][array_index][field_key] = value;
            const newArray = newFormObject[array_key];
            newArray[array_index][field_key] = value;
            newFormObject[array_key] = newArray;
            return { formObject: newFormObject };
        }
    }),
    getFormObjectArrayValue: (array_key, array_index, field_key) => get().formObject[array_key][array_index][field_key],
    getFormObject: () => get().formObject,
}))

export const CustomSelectItemForContacts = (props) => {
    return <>
        <View row centerV spread>
            <Text>{props.label}</Text>
            <View marginL-5>
                <Text tabHeading>{props.phone}</Text>
            </View>
        </View>
    </>
}

export const CustomSelectItemForItems = (props) => {
    return <>
        <View row centerV spread>
            <Text>{props.label}</Text>
            <View marginL-5>
                <Text tabHeading>{props.sku}</Text>
            </View>
        </View>
    </>
}

export function RND(value) {
    return value ? +(+(value).toFixed(2)) : +(value);
}

export const transactionfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

    const LocationComponent = (props) => {
        return <Text>WA</Text>
    }

    const SummaryItem = ({ label = '', keyName, value = '', setFn, hideField = false, hideValue = false, onChange = null, field_type = 'number', options = [] }) => {

        const EditingFields = (!hideField && !view) && <View
            style={{
                marginHorizontal: Platform.OS == 'web' ? 20 : 0,
            }}
        >
            {field_type == 'number' && <CustomNumberInput value={value} onChange={(value) => {
                setFn({ [keyName]: value });
                onChange && onChange(value);
            }} />}
            {field_type == 'select' && <CustomSelect value={value} onChange={(value) => {
                setFn({ [keyName]: value });
            }} options={options || []} />}
        </View>;

        return <View row centerV spread marginB-10>
            <View row={
                Platform.OS == 'web' ? true : false
            } style={Platform.OS == 'web' ? { width: '70%', flexBasis: '70%' } : { width: '50%', flexBasis: '50%' }}
                centerV={Platform.OS == 'web' ? true : false}
                spread={Platform.OS == 'web' ? true : false}
            >
                <View>
                    <Text darkCardTitle h1={label == 'Total' ? true : false}>{label}</Text>
                </View>
                {EditingFields}
            </View>
            <View style={Platform.OS == 'web' ? { width: '30%', flexBasis: '30%' } : { width: '50%', flexBasis: '50%' }} centerV right>
                {label == 'Total' ?
                    <Text darkCardTitle tabHeading h1>{hideValue ? '' : value}</Text> :
                    <Text darkCardTitle tabHeading>{hideValue ? '' : value}</Text>
                }
            </View>
        </View>;
    }

    const SummaryComponent = ({ field, useForm }) => {
        const _id = useForm((state) => state.formObject['_id']);
        const number = useForm((state) => state.formObject['number']);
        const tax_map = useForm((state) => state.formObject['tax_map']);
        const sub_total = useForm((state) => state.formObject['sub_total']);
        const tcs_id = useForm((state) => state.formObject['tcs_id']);
        const tcs_amount = useForm((state) => state.formObject['tcs_amount']);
        const total_before_tax_and_discounts = useForm((state) => state.formObject['total_before_tax_and_discounts']);
        const discount_percentage = useForm((state) => state.formObject['discount_percentage']);
        const discount_amount = useForm((state) => state.formObject['discount_amount']);
        const total = useForm((state) => state.formObject['total']);
        const adjustment = useForm((state) => state.formObject['adjustment']);
        const payment_details = useForm((state) => state.formObject['payment_details']) || [];
        const payment_history = useForm((state) => state.formObject['payment_history']) || [];

        const setFormObject = useForm((state) => state.setFormObject);
        const formObject = useForm((state) => state.formObject);
        const setFormObjectArray = useForm((state) => state.setFormObjectArray);
        const getFormObject = useForm((state) => state.getFormObject);

        const [bankAccounts, setBankAccounts] = useState([]);
        const [cashAccountId, setcashAccountId] = useState('');
        const [cash_received, setCashReceived] = useState(0);

        useEffect(() => {
            const tempBankAccounts = response?.Accounts.filter((account) => account.type == "Cash") || [];
            const index = tempBankAccounts.findIndex((account) => account.name == "Cash");

            if (index > -1) {
                setcashAccountId(tempBankAccounts[index]._id);
                tempBankAccounts.splice(index, 1);
            }

            setBankAccounts(tempBankAccounts)
        }, []);

        useEffect(() => {
            let new_payment_details = [];

            if (cash_received > 0) {
                new_payment_details.push({
                    account_id: cashAccountId,
                    amount: cash_received - ((cash_received + bankAccounts.reduce((a, b) => a + (b.amount_received || 0), 0)) > formObject.balance ? (cash_received + bankAccounts.reduce((a, b) => a + (b.amount_received || 0), 0)) - formObject.balance : 0),
                })
            }

            for (let i = 0; i < bankAccounts.length; i++) {
                const account = bankAccounts[i]
                if (account.amount_received > 0) {
                    new_payment_details.push({
                        account_id: account._id,
                        amount: account.amount_received - ((cash_received + bankAccounts.reduce((a, b) => a + (b.amount_received || 0), 0)) > formObject.balance ? (cash_received + bankAccounts.reduce((a, b) => a + (b.amount_received || 0), 0)) - formObject.balance : 0),
                    })
                }
            }

            new_payment_details = new_payment_details.filter((payment) => payment.amount > 0);

            setFormObject({
                payment_details: new_payment_details,
                savePaymentDetails: true,
                generatePdf: false,
            });
        }, [cash_received, bankAccounts]);


        const actualCash = (+cash_received) - ((+cash_received + bankAccounts.reduce((a, b) => a + (+(b.amount_received || 0)), 0)) > total ? (+cash_received + bankAccounts.reduce((a, b) => a + (+(b.amount_received || 0)), 0)) - +total : 0)

        return <>
            <View flex={Platform.OS == 'web'} row={Platform.OS == 'web'} spread={Platform.OS == 'web'} style={{
                marginTop: Platform.OS == 'web' ? 0 : 20,
            }}>
                <View flex={Platform.OS == 'web' ? '50%' : '100%'} style={
                    Platform.OS == 'web' ? { minWidth: 550 } : {}
                }>
                    {(moduleName == "Invoices" || moduleName == "Bills") && <View style={{
                        padding: Platform.OS == 'web' ? 0 : 20
                    }}>
                        <View marginB-2 row spread>
                            <View>
                                <Text darkCardTitle style={{ lineHeight: 38 }}>{moduleName == "Invoices" ? "Invoice" : "Bill"} Total</Text>
                            </View>
                            <View>
                                <Text darkCardTitle style={{ lineHeight: 38 }}>₹ {total || 0}</Text>
                            </View>
                        </View>

                        <View style={{
                            borderBottomColor: '#e0e3f2bf',
                            borderBottomWidth: 1,
                            marginBottom: 10,
                            marginTop: 10,
                            borderStyle: 'solid'
                        }} />

                        <View marginB-5>
                            <Text darkCardTitle style={{ fontSize: 14 }}>Record New Payment Details</Text>
                        </View>

                        <View style={{
                            borderBottomColor: '#e0e3f2bf',
                            borderBottomWidth: 1,
                            marginBottom: 10,
                            marginTop: 10,
                            borderStyle: 'solid'
                        }} />

                        <View marginB-2 centerV row spread>
                            <View>
                                <Text>Cash</Text>
                            </View>
                            <View>
                                <CustomNumberInput placeholder="Amount Received"
                                    value={cash_received}
                                    onChange={(e) => {
                                        setCashReceived(e);
                                    }} />
                            </View>
                        </View>
                        {bankAccounts.map((account) => <View key={account._id} marginB-2 centerV row spread>
                            <View>
                                <Text>{account.name}</Text>
                            </View>
                            <View>
                                <CustomNumberInput placeholder="Amount Received"
                                    value={account.amount_received}
                                    onChange={(e) => {
                                        const newBankAccounts = [...bankAccounts];
                                        const index = newBankAccounts.findIndex((cashAccount) => cashAccount._id == account._id);
                                        newBankAccounts[index].amount_received = e;
                                        setBankAccounts(newBankAccounts);
                                    }} />
                            </View>
                        </View>)}

                        <View style={{
                            borderBottomColor: '#e0e3f2bf',
                            borderBottomWidth: 1,
                            marginBottom: 10,
                            marginTop: 10,
                            borderStyle: 'solid'
                        }} />

                        {payment_history?.length > 0 && <>
                            <View style={{
                                borderBottomColor: '#e0e3f2bf',
                                borderBottomWidth: 1,
                                marginBottom: 10,
                                marginTop: 10,
                                borderStyle: 'solid'
                            }} />

                            <View marginB-5>
                                <Text darkCardTitle style={{ fontSize: 14 }}>
                                    {moduleName == "Invoices" ? "Invoice Payment History" : "Bill Payment History"}
                                </Text>
                            </View>

                            <View style={{
                                borderBottomColor: '#e0e3f2bf',
                                borderBottomWidth: 1,
                                marginBottom: 10,
                                marginTop: 10,
                                borderStyle: 'solid'
                            }} />
                        </>}

                        {payment_history?.map((payment) => {
                            let actualTransactionPaymentAmount = payment.transactions.find((transaction) => transaction._id == formObject._id).amount;

                            return <View key={payment._id} marginB-2 centerV row spread>
                                <View>
                                    <Text>{moment(payment.date).format('DD MMM YYYY')} (₹ {payment.total_amount})</Text>
                                </View>
                                <View>
                                    <Text darkCardTitle>₹ ({actualTransactionPaymentAmount})</Text>
                                </View>
                            </View>
                        })}

                        {payment_history?.length > 0 && <>
                            <View style={{
                                borderBottomColor: '#e0e3f2bf',
                                borderBottomWidth: 1,
                                marginBottom: 10,
                                marginTop: 10,
                                borderStyle: 'solid'
                            }} />
                        </>}

                        {(+cash_received + +(bankAccounts.reduce((a, b) => a + (b.amount_received || 0), 0))) > +(
                            total - (payment_history || []).reduce((a, b) => a + b.total_amount, 0)
                        ) ? <View marginB-2 centerV row spread>
                            <View>
                                <Text style={{ lineHeight: 38 }} darkCardTitle>Cash To Return</Text>
                            </View>
                            <View>
                                <Text darkCardTitle style={{ lineHeight: 38 }}>₹ {(+cash_received + +(bankAccounts.reduce((a, b) => a + (b.amount_received || 0), 0))) > +(total - (payment_history || []).reduce((a, b) => a + b.total_amount, 0)) ? +(+cash_received + +(bankAccounts.reduce((a, b) => a + (b.amount_received || 0), 0))) - (total - (payment_history || []).reduce((a, b) => a + b.total_amount, 0)) : 0}</Text>
                            </View>
                        </View> : <View marginB-2 centerV row spread>
                            <View>
                                <Text style={{ lineHeight: 38 }} darkCardTitle>Balance Due</Text>
                            </View>
                            <View>
                                <Text darkCardTitle style={{ lineHeight: 38 }}>₹ {(total - (payment_history || []).reduce((a, b) => a + b.total_amount, 0)) - (+cash_received + +(bankAccounts.reduce((a, b) => a + (b.amount_received || 0), 0)))}</Text>
                            </View>
                        </View>}

                        <View style={{
                            borderBottomColor: '#e0e3f2bf',
                            borderBottomWidth: 1,
                            marginBottom: 10,
                            marginTop: 10,
                            borderStyle: 'solid'
                        }} />
                    </View>}
                </View>
                <View flex={Platform.OS == 'web' ? '50%' : '100%'} style={Platform.OS == 'web' ? { minWidth: 550 } : {}}>
                    <SummaryItem label="Sub Total" keyName="sub_total" value={sub_total} setFn={setFormObject} hideField={true} hideValue={false} />

                    <SummaryItem label="Total Before Tax" keyName="total_before_tax_and_discounts" value={total_before_tax_and_discounts} setFn={setFormObject} hideField={true} />

                    {/* <SummaryItem label="Discount Percentage" keyName="discount_percentage" value={discount_percentage} setFn={setFormObject} onChange={(value) => {
                        const newDiscountAmount = RND(sub_total * (value / 100));
                        setFormObject({ discount_amount: newDiscountAmount });
                        invoiceItemAdjustment(getFormObject(), setFormObject, setFormObjectArray, response)
                    }} />

                    <SummaryItem label="Discount" keyName="discount_amount" value={discount_amount} setFn={setFormObject} onChange={(value) => {
                        setFormObject({
                            discount_percentage: null,
                            discount_amount: value ? RND(value) : 0
                        });
                        invoiceItemAdjustment(getFormObject(), setFormObject, setFormObjectArray, response)
                    }} /> */}

                    {tax_map ? Object.keys(tax_map).map((key, index) => {
                        return <View key={index.toString()} row spread marginB-10>
                            <View><Text darkCardTitle>{key}</Text></View>
                            <View><Text darkCardTitle>{tax_map[key]}</Text></View>
                        </View>
                    }) : null}

                    <View row spread marginB-10>
                        <View><Text darkCardTitle>{"Round Off"}</Text></View>
                        <View><Text darkCardTitle>{adjustment}</Text></View>
                    </View>

                    {/* <View row centerV spread marginB-10>
                        <View row style={{ width: '70%', flexBasis: '70%' }} centerV spread>
                            <View>
                                <Text darkCardTitle>{"TCS"}</Text>
                            </View>
                            <View marginH-20 style={{ width: 192 }}>
                                <CustomSelect
                                    value={tcs_id} onChange={(value) => {
                                        setFormObject({ tcs_id: value });
                                        invoiceItemAdjustment(getFormObject(), setFormObject, setFormObjectArray, response)
                                    }} options={response.Tcs || []} />
                            </View>
                        </View>
                        <View style={{ width: '30%', flexBasis: '30%' }} centerV right>
                            <Text darkCardTitle tabHeading>{tcs_amount}</Text>
                        </View>
                    </View> */}

                    <View style={{
                        borderBottomColor: '#e0e3f2bf',
                        borderBottomWidth: 1,
                        marginBottom: 10,
                        marginTop: 10,
                        borderStyle: 'solid'
                    }} />

                    <SummaryItem label="Total" keyName="total" value={total} setFn={setFormObject} hideField={true} />

                    <View style={{
                        borderBottomColor: '#e0e3f2bf',
                        borderBottomWidth: 1,
                        marginBottom: 10,
                        borderStyle: 'solid'
                    }} />
                </View>
            </View>
        </>
    }

    const CustomMobileCard = ({ item, index, getFieldValue, valueText, columns, DeleteCard }) => {
        const [open, setOpen] = useState(false);

        return <View padding-10>
            <Card flex style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                <TouchableOpacity padding-15 paddingT-10 paddingB-10 flex onPress={() => {
                    // setEditFieldModalData(item);
                    // setEditFieldModalIndex(index);
                    // setEditFieldModalVisible(true);
                }}>
                    <View marginB-10 row spread style={{ width: '100%' }}>
                        <View flex>
                            <Text style={{ marginBottom: 5, fontSize: 16, fontFamily: 'SourceSansProBold' }}>
                                {columns[0] ? getFieldValue(item, columns[0].field, columns[0]) : ""}
                            </Text>
                            <View>
                                <View flex>
                                    {columns.length > 2 ? <View row marginB-6 flex>
                                        <View>
                                            <Text lightCardTitle>{columns[1] ? columns[1].label : ""}: </Text>
                                        </View>
                                        <View flex>
                                            {valueText(columns[1])}
                                        </View>
                                    </View> : null}
                                    {columns.length > 3 ? <View row marginB-6 flex>
                                        <View>
                                            <Text lightCardTitle>{columns[2] ? columns[2].label : ""}: </Text>
                                        </View>
                                        <View flex>
                                            {valueText(columns[2])}
                                        </View>
                                    </View> : null}
                                </View>
                            </View>
                        </View>
                    </View>

                    {columns.length > 4 ? <View marginB-10 style={{ width: '100%', height: 4, backgroundColor: '#EFF5FF', borderRadius: 4 }} /> : null}
                    {columns.length > 4 ? <View row spread style={{ width: '100%' }}>
                        {columns.length > 4 ? <View row flex>
                            <View>
                                <Text lightCardTitle>{columns[3] ? columns[3].label : ""}: </Text>
                            </View>
                            <View flex>
                                {valueText(columns[3])}
                            </View>
                        </View> : null}
                        {columns.length > 5 ? <View row flex>
                            <View>
                                <Text lightCardTitle>{columns[4] ? columns[4].label : ""}: </Text>
                            </View>
                            <View flex>
                                {valueText(columns[4])}
                            </View>
                        </View> : null}
                    </View> : null}

                    {columns.length > 6 ? <View marginB-10 style={{ width: '100%', height: 4, backgroundColor: '#EFF5FF', borderRadius: 4 }} /> : null}
                    {columns.length > 6 ? <View row spread style={{ width: '100%' }}>
                        {columns.length > 6 ? <View row flex>
                            <View>
                                <Text lightCardTitle>{columns[5] ? columns[5].label : ""}: </Text>
                            </View>
                            <View flex>
                                {valueText(columns[5])}
                            </View>
                        </View> : null}
                        {columns.length > 7 ? <View row flex>
                            <View>
                                <Text lightCardTitle>{columns[6] ? columns[6].label : ""}: </Text>
                            </View>
                            <View flex>
                                {valueText(columns[6])}
                            </View>
                        </View> : null}
                    </View> : null}

                    {columns.length > 8 ? <View marginB-10 style={{ width: '100%', height: 4, backgroundColor: '#EFF5FF', borderRadius: 4 }} /> : null}
                    {columns.length > 8 ? <View row spread style={{ width: '100%' }}>
                        {columns.length > 8 ? <View row flex>
                            <View>
                                <Text lightCardTitle>{columns[7] ? columns[7].label : ""}: </Text>
                            </View>
                            <View flex>
                                {valueText(columns[7])}
                            </View>
                        </View> : null}
                        {columns.length > 9 ? <View row flex>
                            <View>
                                <Text lightCardTitle>{columns[8] ? columns[8].label : ""}: </Text>
                            </View>
                            <View flex>
                                {valueText(columns[8])}
                            </View>
                        </View> : null}
                    </View> : null}
                </TouchableOpacity>

                <TouchableOpacity onPress={() => {
                    Alert.alert("Delete", "Are you sure you want to delete this record?", [
                        {
                            text: "Cancel",
                            onPress: () => {

                            },
                            style: "cancel"
                        },
                        {
                            text: "Delete",
                            onPress: () => {
                                updateData && updateData(data.filter((d, i) => i != index));
                            }
                        }
                    ]);
                }}>
                    <DeleteCard color={Colors.red30}
                        text={"Delete"}
                        radius={10}
                    />
                </TouchableOpacity>
            </Card>
        </View>
    }

    const EditItemModalComponent = (props) => {
        const { field, connectedObject } = props;
        const [visible, setVisible] = useState(false);
        return <>
            <View></View>
        </>
    }

    const TrackingComponent = ({ field, useForm, tKey, tIndex }) => {
        const track_serial_number = useForm((state) => state.formObject?.[tKey]?.[tIndex]?.['track_serial_number']);
        const serial_numbers = useForm((state) => state.formObject?.[tKey]?.[tIndex]?.['serial_numbers']);
        const old_serial_numbers = useForm((state) => state.formObject?.[tKey]?.[tIndex]?.['old_serial_numbers']);
        const _id = useForm((state) => state.formObject?.['_id']);

        const item_id = useForm((state) => state.formObject?.[tKey]?.[tIndex]?.['item_id']);
        const item = response.Items.find((item) => item._id == item_id);

        let available_serial_numbers = item?.serial_numbers || [];

        if (_id) {
            available_serial_numbers = [...new Set([...(available_serial_numbers || []), ...(old_serial_numbers || [])])];
        }

        const [serialNumberModalVisible, setSerialNumberModalVisible] = useState(false);
        const [tempSerialNumber, setTempSerialNumber] = useState('');

        const track_batch_number = useForm((state) => state.formObject?.[tKey]?.[tIndex]?.['track_batch_number']);
        const batch_numbers = useForm((state) => state.formObject?.[tKey]?.[tIndex]?.['batch_numbers']);
        const [batchNumberModalVisible, setBatchNumberModalVisible] = useState(false);

        const setFormObjectArray = useForm((state) => state.setFormObjectArray);
        const setFormObject = useForm((state) => state.setFormObject);
        const getFormObject = useForm((state) => state.getFormObject);

        const batchNumbersColumns = [
            {
                "field": "batch_number",
                "label": "Batch Number",
                "editable": true,
                "type": "select",
                "visible": true,
                "order": 1,
                "options": [...(response.AvailableBatchNumbers || [{
                    "label": "Batch Number 1",
                    "value": "Batch Number 1",
                    "item_id": "649bb576e552219bd858b4c7"
                }, {
                    "label": "Batch Number 2",
                    "value": "Batch Number 2",
                    "item_id": "649bb576e552219bd858b4c7"
                }, {
                    "label": "Batch Number 3",
                    "value": "Batch Number 3",
                    "item_id": "649be5b1c10754fc93f3d89a"
                }
                ])].filter((item) => item.item_id === item_id)
            }, {
                "field": "quantity",
                "label": "Quantity",
                "editable": true,
                "type": "number",
                "visible": true,
                "order": 3
            },
            {
                label: (props) => null,
                field: 'actions',
            }
        ];

        batchNumbersColumns && batchNumbersColumns.map((column) => {
            column.Header = column.label;
            column.accessor = column.field;
        });

        const batchFormObject = useFormObject((state) => state.formObject);
        const batchSetFormObjectArray = useFormObject((state) => state.setFormObjectArray);
        const batchSetFormObject = useFormObject((state) => state.setFormObject);

        const [newQuantity, setNewQuantity] = useState(1);

        const deleteForm = GlobalFormStore((state) => state.deleteForm);
        const setOpenModal = GlobalModalStore((state) => state.setOpenModal);

        let navigation = useNavigation();

        let navigate = null;

        if (Platform.OS === 'web') {
            navigate = useNavigate();
        }

        const navigationFn = (path, params) => {
            if (Platform.OS === 'web') {
                let paramsString = "";
                if (params) {
                    paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
                }
                navigate("/" + path + paramsString);
            } else {
                if (path.includes("report/")) {
                    navigation.push("report", {
                        ...params,
                        name: path.replace("report/", ""),
                    });
                } else {
                    navigation.push(path, params);
                }
            }
        };

        return <>
            <View>
                <CustomModal title="Serial Numbers" visible={serialNumberModalVisible} onClose={() => setSerialNumberModalVisible(false)}>
                    {!view && <View>
                        {moduleName == "Invoices" && <CustomSelect
                            value={tempSerialNumber}
                            onChange={(value) => {
                                let arr = [...(serial_numbers || [])];
                                arr.push(value);
                                arr = [...new Set(arr)]
                                setFormObjectArray(tKey, tIndex, 'serial_numbers', arr);
                                setFormObjectArray(tKey, tIndex, 'quantity', arr.length);
                                invoiceItemAdjustment(getFormObject(), setFormObject, setFormObjectArray, response);
                            }}
                            options={available_serial_numbers?.map((el) => {
                                return {
                                    label: el,
                                    value: el
                                }
                            }) || []}
                            placeholder='Select Serial Number'
                        />}

                        {moduleName == 'Bills' && <View row left>
                            <View marginR-10>
                                <CustomNumberInput
                                    min={1}
                                    max={1000}
                                    value={newQuantity}
                                    onChange={(value) => {
                                        setNewQuantity(value);
                                    }}
                                    placeholder='Enter Quantity'
                                />
                            </View>

                            <PrimaryButton label={"Generate" + (newQuantity > 1 ? ` ${newQuantity} Serial Numbers` : ` ${newQuantity} Serial Number`)}
                                onPress={() => {
                                    let arr = [...(serial_numbers || [])];
                                    for (let i = 0; i < newQuantity; i++) {
                                        const newDate = new Date();
                                        // generate 10 digit number based on date
                                        const newSerialNumber = `${item.sku}${newDate.getMonth()}${newDate.getDate()}${newDate.getFullYear() - 2000}${newDate.getHours()}${newDate.getMinutes()}${newDate.getSeconds()}${newDate.getMilliseconds()}${i}`;

                                        arr.push(newSerialNumber);
                                    }
                                    arr = [...new Set(arr)]
                                    setFormObjectArray(tKey, tIndex, 'serial_numbers', arr);
                                    setFormObjectArray(tKey, tIndex, 'quantity', arr.length);
                                    invoiceItemAdjustment(getFormObject(), setFormObject, setFormObjectArray, response);
                                }} />

                        </View>}

                        <View marginT-20>
                            <CustomSerialNumberTextArea
                                value={serial_numbers || []}
                                onChange={(value) => {
                                    let arr = [...(value || [])];
                                    if (moduleName == "Invoices") {
                                        arr = arr.filter((item) => available_serial_numbers.includes(item));
                                    }
                                    arr = [...new Set(arr)]
                                    setFormObjectArray(tKey, tIndex, 'quantity', arr.length);
                                    setFormObjectArray(tKey, tIndex, 'serial_numbers', arr);
                                    invoiceItemAdjustment(getFormObject(), setFormObject, setFormObjectArray, response);
                                }}
                            ></CustomSerialNumberTextArea>
                        </View>
                    </View>}
                    {view && <View>
                        {serial_numbers && serial_numbers.map((serial_number, index) => {
                            return <TouchableOpacity
                                onPress={() => {
                                    setOpenModal({
                                        [moduleName]: null
                                    });

                                    if (Platform.OS === 'web') {
                                        const params = {
                                            quickFilterKey: "serial_number",
                                            quickFilterValue: serial_number,
                                        }
                                        let paramsString = "";
                                        if (params) {
                                            paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
                                        }
                                        let path = "report/" + "SerialNumberDetail";
                                        navigate("/" + path + paramsString);
                                    } else {
                                        navigationFn('report', {
                                            moduleName: "SerialNumberDetail",
                                            quickFilterKey: "serial_number",
                                            quickFilterValue: serial_number,
                                        });
                                    }
                                }}
                                key={index.toString()}
                            ><View row centerV spread marginB-10>
                                    <View row style={{ width: '70%', flexBasis: '70%' }} centerV spread>
                                        <View>
                                            <Text darkCardTitle>{serial_number}</Text>
                                        </View>
                                    </View>
                                    <View style={{ width: '30%', flexBasis: '30%' }} centerV right>
                                        <Text darkCardTitle tabHeading>{1}</Text>
                                    </View>
                                </View>
                            </TouchableOpacity>
                        })}
                    </View>}
                </CustomModal>

                {track_serial_number ?
                    <TouchableOpacity onPress={() => setSerialNumberModalVisible(true)}>
                        <Text link>{serial_numbers ? serial_numbers.length : 0} Serial Numbers</Text>
                    </TouchableOpacity>
                    : null}

                <CustomModal title="Batch Numbers" visible={batchNumberModalVisible} onClose={() => {
                    if (batchFormObject.batch_numbers.filter((item) => item.quantity == 0) > 0) {
                        alert('Please enter quantity for all batch numbers or delete them');
                        return;
                    }

                    setBatchNumberModalVisible(false)
                    setFormObjectArray(tKey, tIndex, 'batch_numbers', batchFormObject.batch_numbers || [])
                }}>
                    <DynamicTable
                        data={batchFormObject.batch_numbers || []}
                        columns={batchNumbersColumns}
                        editable={true}
                        updateData={(data) => {

                        }}
                        actions={true}
                        actionFunctions={[{
                            label: 'Delete',
                            key: 'delete',
                            fn: ({ data, updateData, row, row_index }) => {
                                let newData = [...data];
                                newData.splice(row_index, 1);
                                batchSetFormObject({
                                    batch_numbers: newData
                                })
                            }
                        }]}
                        useForm={useFormObject}
                        tableKey={'batch_numbers'}
                    />

                    <View marginT-20>
                        <Button
                            label="Add Batch Number"
                            onPress={() => {
                                const arr = [...(batchFormObject.batch_numbers || [])];
                                arr.push({
                                    batch_number: '',
                                    quantity: 0
                                });
                                batchSetFormObject({
                                    batch_numbers: arr
                                })
                            }}
                        />
                    </View>

                </CustomModal>

                {track_batch_number ?
                    <TouchableOpacity onPress={() => setBatchNumberModalVisible(true)}>
                        <Text>From {batch_numbers ? batch_numbers.length : 0} Batches</Text>
                    </TouchableOpacity>
                    : null}
            </View>
        </>
    }

    const DiscountComponent = ({ field, useForm, tKey, tIndex }) => {
        const getFormObject = useForm((state) => state.getFormObject);
        const setFormObjectArray = useForm((state) => state.setFormObjectArray);
        const setFormObject = useForm((state) => state.setFormObject);
        const discount_value = useForm((state) => state.formObject?.[tKey]?.[tIndex]?.['discount_value']);

        return <View row center marginH-25>
            {!view && <>
                <CustomNumberInput independent label="Discount" value={getFormObject()[tKey][tIndex]['discount_value']}
                    onChange={(value) => {
                        setFormObjectArray(tKey, tIndex, 'discount_value', value);
                        invoiceItemAdjustment(getFormObject(), setFormObject, setFormObjectArray, response);
                    }}></CustomNumberInput>
                <View>
                    <TouchableOpacity center onPress={() => {
                        const oldType = getFormObject()[tKey][tIndex].discount_type;
                        const newType = oldType === 'percent' ? 'amount' : 'percent';
                        setFormObjectArray(tKey, tIndex, 'discount_type', newType);
                        invoiceItemAdjustment(getFormObject(), setFormObject, setFormObjectArray, response);
                    }} style={[{ borderRadius: 4, marginLeft: 5, borderWidth: 1, borderColor: '#edf2f9', height: 38, width: 38 }]}>
                        <Text>{getFormObject()[tKey][tIndex].discount_type === 'percent' ? '%' : '₹'}</Text>
                    </TouchableOpacity>
                </View>
            </>}
            {view && <Text value style={{
                lineHeight: 39
            }}>
                {getFormObject()[tKey][tIndex].discount_type === 'percent' ? '%' : '₹'} {getFormObject()[tKey][tIndex].discount_value || 0}
            </Text>}
        </View>;
    }

    const lineItemColumns = [{
        "field": "account_id",
        "label": "Account",
        "editable": true,
        "type": "select",
        "options": response.Accounts || [],
        "visible": false,
        "order": 1,
    }, {
        "field": "item_id",
        "label": "Item",
        "editable": true,
        "type": "select",
        "options": response.Items || [],
        onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
            itemChangeFn(value, response, moduleName, getObj, setObj, setArrValue, tKey, tIndex, isEdit);
        },
        extraRenderForSelectedOption: (props) => {
            const { field, useForm, tKey, tIndex } = props;
            const value = props.value;
            const item = response.Items.find((item) => item._id === value);
            const showTracking = moduleName == 'Invoices' || moduleName == 'Bills';
            const setFormObjectArray = useForm((state) => state.setFormObjectArray);
            const getFormObject = useForm((state) => state.getFormObject);
            const setFormObject = useForm((state) => state.setFormObject);

            return item ? <View>
                <View marginT-10 style={{ width: '100%' }}>
                    {showTracking && <TrackingComponent field={props.field} useForm={props.useForm} tKey={props.tKey} tIndex={props.tIndex} />}

                    {item.product_type == "Subscription" && <View marginT-20 row>
                        <View marginR-3>
                            <View marginB-4>
                                <Text style={{ fontSize: 12, color: '#000' }}>{"Start Date"}</Text>
                            </View>
                            <CustomDatePicker
                                label="Start Date"
                                value={getFormObject()[tKey][tIndex]['start_date']}
                                onChange={(value) => {
                                    setFormObjectArray(tKey, tIndex, 'start_date', value);
                                    const duration_string = "" + item.subscription_duration;
                                    const endsWithOne = duration_string[duration_string.length - 1] == 1;
                                    const endsWithTwo = duration_string[duration_string.length - 1] == 2;

                                    if (endsWithOne) {
                                        const subscription_duration = item.subscription_duration - 1;
                                        const end_date = new Date(value);
                                        const days = subscription_duration / 86400000;
                                        const months = days / 30;
                                        end_date.setMonth(end_date.getMonth() + months);
                                        setFormObjectArray(tKey, tIndex, 'end_date', end_date.toISOString());
                                    } else if (endsWithTwo) {
                                        // this is for years
                                        const subscription_duration = item.subscription_duration - 2;
                                        const end_date = new Date(value);
                                        const days = subscription_duration / 86400000;
                                        const years = days / 365;
                                        end_date.setFullYear(end_date.getFullYear() + years);
                                        setFormObjectArray(tKey, tIndex, 'end_date', end_date.toISOString());
                                    } else {
                                        const subscription_duration = item.subscription_duration;
                                        const end_date = new Date(value);
                                        // const days = subscription_duration / 86400000;
                                        end_date.setTime(end_date.getTime() + subscription_duration);
                                        setFormObjectArray(tKey, tIndex, 'end_date', end_date.toISOString());
                                    }
                                }}
                            />
                        </View>
                        <View marginL-3>
                            <View marginB-4>
                                <Text style={{ fontSize: 12, color: '#000' }}>{"End Date"}</Text>
                            </View>
                            <CustomDatePicker
                                label="End Date"
                                value={getFormObject()[tKey][tIndex]['end_date']}
                                onChange={(value) => {
                                    setFormObjectArray(tKey, tIndex, 'end_date', value);
                                }}
                            />
                        </View>
                    </View>}
                </View>
            </View> : <></>;
        },
        extraViewRender: ({
            value,
            tKey,
            tIndex,
            getObj,
            setObj,
            getArrValue,
            setArrValue
        }) => {
            const item = response.Items.find((item) => item._id === value);

            return <View>
                <View marginT-10 style={{ width: '100%' }}>
                    {item.product_type == "Subscription" && <View marginT-20 row>
                        <View marginR-3>
                            <View marginB-4>
                                <Text style={{ fontSize: 12, color: '#000' }}>{"Start Date"}</Text>
                            </View>
                            <View>
                                <Text style={{ fontSize: 12, color: '#000' }}>{moment(getObj()[tKey][tIndex]['start_date']).format('DD/MM/YYYY')}</Text>
                            </View>
                        </View>
                        <View marginL-3>
                            <View marginB-4>
                                <Text style={{ fontSize: 12, color: '#000' }}>{"End Date"}</Text>
                            </View>
                            <View>
                                <Text style={{ fontSize: 12, color: '#000' }}>{moment(getObj()[tKey][tIndex]['end_date']).format('DD/MM/YYYY')}</Text>
                            </View>
                        </View>
                    </View>}

                </View>
            </View>
        },
        "visible": true,
        "order": 3,
        "width": 250,
        "otherSearchKeys": ['sku', 'serial_numbers'],
        customSelectItem: CustomSelectItemForItems,
        creatableAction: (props) => {
            let paramOption = {};

            paramOption = {
                is_default_values: true,
                name: props.search,
            }

            const params = {
                ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? paramOption : {})
            };
            OpenModalCustom({ newModule: "Items", field_key: 'line_items.item_id', params });
        },
    }, {
        "field": "track_inventory",
        "label": "Track Inventory",
        "editable": true,
        "type": "boolean",
        "visible": false,
        "order": 8
    }, {
        "field": "track_serial_number",
        "label": "Track Serial Number",
        "editable": true,
        "type": "boolean",
        "visible": false,
        "order": 9
    }, {
        "field": "hsn_or_sac",
        "label": "HSN/SAC",
        "editable": true,
        "type": "text",
        "visible": false,
        "order": 10
    }, {
        "field": "description",
        "label": "Item Description",
        "editable": true,
        "type": "text",
        "visible": false,
        "order": 11
    },
    // {
    //     "field": "status",
    //     "label": "Status",
    //     "editable": true,
    //     "type": "custom",
    //     "visible": (moduleName == 'Invoices' || moduleName == 'Bills') ? true : false,
    //     "width": 100,
    //     "order": 12,
    //     "component": ({ field, useForm, tKey, tIndex }) => {
    //         const getObj = useForm((state) => state.getFormObject);
    //         const status = useForm((state) => state.formObject?.[tKey]?.[tIndex]?.['status']);
    //         const setObj = useForm((state) => state.setFormObject);
    //         const setArrValue = useForm((state) => state.setFormObjectArray);

    //         let options = [
    //             { label: 'Pending', value: 'Pending', },
    //             { label: 'Delivered', value: 'Delivered' },
    //         ];

    //         if (moduleName == 'Bills') {
    //             options = [
    //                 { label: 'Pending', value: 'Pending', },
    //                 { label: 'Received', value: 'Received' },
    //             ];
    //         }

    //         return <CustomDropDown value={status} onChange={(option) => {
    //             setArrValue(tKey, tIndex, "status", option.value);
    //         }} options={options} />
    //     }
    // },
    {
        "field": "quantity",
        "label": "Quantity",
        "editable": true,
        "type": "number",
        "visible": true,
        "width": 100,
        "order": 12,
        "onChange": ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
            const item_price = getObj()[tKey][tIndex]["item_price"];
            const item_total = (+value * +item_price);
            // setArrValue(tKey, tIndex, "item_gross_total", item_total);
            invoiceItemAdjustment(getObj(), setObj, setArrValue, response)
        },
        "debounce": 500
    }, {
        "field": "usage_unit",
        "label": "Usage Unit",
        "editable": true,
        "type": "text",
        "visible": false,
        "order": 13
    }, {
        "field": "item_price",
        "label": "Item Price",
        "editable": true,
        "type": "number",
        "visible": true,
        "width": 100,
        "order": 16,
        "onChange": ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
            const quantity = getObj()[tKey][tIndex]["quantity"];
            const item_total = (+value * +quantity);
            // setArrValue(tKey, tIndex, "item_gross_total", item_total);
            invoiceItemAdjustment(getObj(), setObj, setArrValue, response)
        },
        "debounce": 500
    }, {
        "field": "item_tax",
        "label": "Tax",
        "editable": true,
        "type": "text",
        "visible": false,
        "order": 17
    }, {
        "field": "item_tax_percentage",
        "label": "Item Tax Percentage",
        "editable": true,
        "type": "number",
        "visible": false,
        "order": 18
    }, {
        "field": "item_tax_amount",
        "label": "Item Tax Amount",
        "editable": false,
        "type": "number",
        "visible": false,
        "order": 19
    },
    {
        "field": "discount",
        "label": "Discount",
        "editable": true,
        "type": "custom",
        "visible": response.ModulePrefs.give_discount_on_item_level || false,
        "width": 130,
        "order": 20,
        "component": DiscountComponent,
    },
    {
        "field": "item_total",
        "label": "Item Total",
        "editable": false,
        "type": "number",
        "visible": false,
        "order": 21,
        "width": 100
    }, {
        "field": "item_gross_total",
        "label": "Item Total",
        "editable": false,
        "type": "number",
        "visible": true,
        "order": 21,
        "width": 110
    }, {
        "field": "taxable_amount",
        "label": "Taxable Amount",
        "editable": true,
        "type": "number",
        "visible": false,
        "order": 22
    }, {
        "field": "action_column",
        "label": "",
        "editable": true,
        "type": "custom",
        "width": 80,
        "component": ({ field, useForm, tKey, tIndex }) => {
            const getObj = useForm((state) => state.getFormObject);
            const setObj = useForm((state) => state.setFormObject);
            const setArrValue = useForm((state) => state.setFormObjectArray);
            const line_items = getObj()[tKey];

            return (Platform.OS == 'web' && (line_items.length - 1) != tIndex) ? <View row centerV style={{
                height: 40
            }}>
                <button className="focusable" style={{
                    paddingTop: 5,
                    paddingBottom: 5,
                }} onClick={(e) => {
                    const obj = getObj();
                    const arr = [...obj[tKey]];
                    arr.splice(tIndex, 1);
                    setObj({
                        [tKey]: arr
                    });
                    invoiceItemAdjustment(getObj(), setObj, setArrValue, response)
                }}>
                    <Image source={{ uri: `https://inkapps.pages.dev/icons/delete.png` }}
                        style={{ width: 16, height: 16, marginLeft: 10, marginRight: 8 }} />
                </button>
            </View> : <></>
        },
        "visible": view ? false : true,
        "order": 22
    }];

    lineItemColumns && lineItemColumns.map((column) => {
        column.Header = column.label;
        column.accessor = column.field;
    });

    const formFields = [
        {
            label: 'Contact',
            key: 'contact_id',
            type: 'select',
            placeholder: 'Select the contact',
            visible: true,
            width: '25%',
            options: response.Contacts || [],
            tab: 'General',
            creatableAction: (props) => {
                const isPhone = /^\d+$/.test(props.search);
                let paramOption = {};

                if (!isPhone) {
                    paramOption = {
                        is_default_values: true,
                        name: props.search,
                        is_supplier: (moduleName == "Bills" || moduleName == "PurchaseReturns" || moduleName == "PurchaseOrders") ? true : false,
                    }
                } else {
                    paramOption = {
                        is_default_values: true,
                        phone: props.search,
                        is_supplier: (moduleName == "Bills" || moduleName == "PurchaseReturns" || moduleName == "PurchaseOrders") ? true : false,
                    }
                }

                const params = {
                    ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? paramOption : {})
                };
                OpenModalCustom({ newModule: "Contacts", field_key: 'contact_id', params });
            },
            customSelectItem: CustomSelectItemForContacts,
            otherSearchKeys: ['phone', 'email'],
            onChange: ({ value, tKey, tIndex, getObj, setObj, getArrValue, setArrValue }) => {
                const contact = response.Contacts.find((contact) => contact._id === value);
                const gst_numbers = contact.gst_numbers || [];
                const addresses = contact.addresses || [];
                const primary_gst_number = gst_numbers.find((gst_number) => gst_number.primary == "Yes");
                const primary_billing_address = addresses.find((address) => address.primary_billing == "Yes");
                const primary_shipping_address = addresses.find((address) => address.primary_shipping == "Yes");
                setObj({
                    contact_id: value,
                    addresses: addresses,
                    gst_treatment: contact.gst_treatment,
                    gst_number: primary_gst_number ? primary_gst_number.gst_number : '',
                    billing_address_line_1: primary_billing_address ? primary_billing_address.address_line_1 : '',
                    billing_address_line_2: primary_billing_address ? primary_billing_address.address_line_2 : '',
                    billing_city: primary_billing_address ? primary_billing_address.city : '',
                    billing_state: primary_billing_address ? primary_billing_address.state : '',
                    billing_pincode: primary_billing_address ? primary_billing_address.pincode : '',
                    billing_country: primary_billing_address ? primary_billing_address.country : '',
                    shipping_address_line_1: primary_shipping_address ? primary_shipping_address.address_line_1 : '',
                    shipping_address_line_2: primary_shipping_address ? primary_shipping_address.address_line_2 : '',
                    shipping_city: primary_shipping_address ? primary_shipping_address.city : '',
                    shipping_state: primary_shipping_address ? primary_shipping_address.state : '',
                    shipping_pincode: primary_shipping_address ? primary_shipping_address.pincode : '',
                    shipping_country: primary_shipping_address ? primary_shipping_address.country : '',
                });
            },
            autoFocus: value?.contact_id ? false : true,
        },
        {
            label: 'Date',
            key: 'date',
            type: 'date',
            placeholder: 'Select the date',
            visible: true,
            width: '25%',
            tab: 'General'
        },
        {
            label: 'Due Date',
            key: 'due_date',
            type: 'date',
            placeholder: 'Select the due date',
            visible: moduleName == 'Invoices' ? response.ModulePrefs.hide_due_date_field == true ? false : true : false,
            width: '25%',
            tab: 'General'
        },
        {
            label: 'Number',
            key: 'number',
            type: 'text',
            placeholder: 'Enter the number',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            type: 'divider',
            heading: 'GST Details',
            visible: true,
            span: 24,
            tab: 'Customer Details'
        },
        {
            label: 'Place of Supply',
            key: 'place_of_supply',
            type: 'text',
            placeholder: 'Enter the place of supply',
            visible: true,
            width: '25%',
            tab: 'Customer Details'
        },
        {
            label: 'GST Number',
            key: 'gst_number',
            type: 'text',
            placeholder: 'Enter the GST number',
            visible: true,
            width: '25%',
            tab: 'Customer Details'
        },
        {
            label: 'GST Treatment',
            key: 'gst_treatment',
            type: 'select',
            placeholder: 'Enter the GST treatment',
            visible: true,
            width: '25%',
            tab: 'Customer Details',
            options: [
                { label: 'Registered Business - Regular', value: 'Registered Business - Regular' },
                { label: 'Registered Business - Composition', value: 'Registered Business - Composition' },
                { label: 'Unregistered Business', value: 'Unregistered Business' },
                { label: 'Consumer', value: 'Consumer' },
            ]
        },
        // {
        //     label: 'ITC Treatment',
        //     key: 'itc_treatment',
        //     type: 'text',
        //     placeholder: 'Enter the ITC treatment',
        //     visible: true,
        //     width: '25%',
        //     tab: 'Customer Details'
        // },
        // {
        //     label: 'Is Reverse Charge Applied',
        //     key: 'is_reverse_charge_applied',
        //     type: 'switch',
        //     placeholder: 'Enter if reverse charge is applied',
        //     visible: moduleName == 'Bills' ? true : false,
        //     width: '25%',
        //     tab: 'General'
        // },
        // {
        //     label: 'Is Cash',
        //     key: 'is_cash',
        //     type: 'switch',
        //     placeholder: 'Select if this is a cash transaction',
        //     visible: true,
        //     width: '25%',
        //     tab: 'General'
        // },
        // {
        //     label: 'Is Tax Inclusive',
        //     key: 'is_tax_inclusive',
        //     type: 'switch',
        //     placeholder: 'Select if tax is included',
        //     visible: true,
        //     width: '25%',
        //     tab: 'General'
        // },
        // {
        //     label: 'Reference Number',
        //     key: 'reference_number',
        //     type: 'text',
        //     placeholder: 'Enter the reference number',
        //     visible: true,
        //     width: '25%',
        //     tab: 'General'
        // },
        {
            label: 'Sales Person',
            key: 'sales_person',
            type: 'select',
            placeholder: 'Select the sales person',
            visible: (moduleName == 'Invoices' || moduleName == 'Estimates' || moduleName == 'Sales Orders' || moduleName == 'Credit Notes' || moduleName == 'Delivery Challans') ? true : false,
            width: '25%',
            tab: 'General',
            options: (response.Employees || []).map((employee) => {
                return {
                    label: employee.name,
                    value: employee._id
                }
            })
        },
        {
            label: 'Location',
            key: 'location_id',
            type: 'select',
            placeholder: 'Select the location',
            visible: response.ModulePrefs.hide_location_field == true ? false : true,
            width: '25%',
            tab: 'General',
            options: response.Locations || [],
            onChange: ({ value, tKey, tIndex, getObj, setObj, getArrValue, setArrValue }) => {
                invoiceItemAdjustment(getObj(), setObj, setArrValue, response);
                setGlobalData({
                    location_id: value
                })
            },
            value: globalData?.location_id ? response.Locations.find((location) => location._id == globalData.location_id) ? globalData.location_id : response.Locations[0].value : response.Locations[0].value
        },
        {
            label: 'Delivery Status',
            key: 'status',
            type: 'select',
            placeholder: 'Select the delivery status',
            visible: response.ModulePrefs.hide_delivery_status_field == true ? false : (moduleName == "Invoices" || moduleName == "PurchaseReturns" || moduleName == "Bills" || moduleName == "SalesReturns") ? true : false,
            width: '25%',
            tab: 'General',
            options: (moduleName == "Invoices" || moduleName == "PurchaseReturns") ? [
                { label: 'Pending', value: 'Pending', },
                { label: 'Delivered', value: 'Delivered' },
            ] : [
                { label: 'Pending', value: 'Pending', },
                { label: 'Received', value: 'Received' },
            ],
            onChange: ({ value, tKey, tIndex, getObj, setObj, getArrValue, setArrValue }) => {
                invoiceItemAdjustment(getObj(), setObj, setArrValue, response);
            },
            value: !(response.ModulePrefs.turn_off_auto_mark_as_delivered_or_received == true) ? (moduleName == "Invoices" || moduleName == "PurchaseReturns") ? 'Delivered' : 'Received' : 'Pending'
        },
        {
            label: 'Invoice',
            key: 'invoice_id',
            type: 'select',
            placeholder: 'Select the invoice',
            visible: moduleName == 'SalesReturns' ? true : false,
            width: '25%',
            tab: 'General',
            options: response.PendingInvoices || [],
        }, {
            label: 'Bill',
            key: 'bill_id',
            type: 'select',
            placeholder: 'Select the bill',
            visible: moduleName == 'PurchaseReturns' ? true : false,
            width: '25%',
            tab: 'General',
            options: response.PendingBills || [],
        },
        {
            label: 'Created By',
            key: 'created_by.name',
            type: 'text',
            placeholder: 'Enter the created by',
            visible: view ? true : false,
            width: '25%',
            tab: 'General',
        },
        // {
        //     label: 'Price List',
        //     key: 'price_list_id',
        //     type: 'select',
        //     placeholder: 'Select the price list',
        //     visible: true,
        //     width: '25%',
        //     tab: 'General',
        //     options: response.PriceLists || [],
        //     onChange: ({ value, tKey, tIndex, getObj, setObj, getArrValue, setArrValue }) => {
        //         invoiceItemAdjustment(getObj(), setObj, setArrValue, response);
        //     },
        // },
        {
            label: '',
            key: 'line_items',
            type: 'table',
            placeholder: 'Enter items',
            visible: true,
            editable: true,
            width: '100%',
            tab: 'General',
            columns: lineItemColumns,
            value: (Platform.OS == 'web' && !value?.disable_auto_line_item) ? [{}] : [],
            // CustomMobileCard: CustomMobileCard,
        },

        // addButton("Add Line Item", "add_line_item", "line_items", {
        //     auto_open: true,
        // }, "General", view ? false : true),

        {
            label: '',
            key: 'edit_item_modal',
            type: 'custom',
            component: EditItemModalComponent,
            visible: true,
            width: '100%',
            tab: 'General'
        },
        {
            label: '',
            key: 'summary',
            type: 'custom',
            component: SummaryComponent,
            visible: true,
            width: '100%',
            tab: 'General'
        },
        {
            label: 'Sub Total',
            key: 'sub_total',
            type: 'number',
            placeholder: 'Enter the sub total',
            visible: false,
            width: '25%',
            tab: 'General'
        },
        {
            label: 'Total',
            key: 'total_before_tax_and_discounts',
            type: 'number',
            placeholder: 'Enter the total',
            visible: false,
            width: '25%',
            tab: 'General'
        },
        {
            label: 'Total',
            key: 'total',
            type: 'number',
            placeholder: 'Enter the total',
            visible: false,
            width: '25%',
            tab: 'General'
        },
        {
            label: 'Total In Words',
            key: 'total_in_words',
            type: 'text',
            placeholder: 'Enter the total in words',
            visible: false,
            width: '25%',
            tab: 'General'
        },
        {
            label: 'TCS',
            key: 'tcs_id',
            type: 'select',
            placeholder: 'Enter the TCS name',
            visible: false,
            width: '25%',
            tab: 'General',
            options: response.tcs || [],
        },
        {
            label: 'TCS Amount',
            key: 'tcs_amount',
            type: 'number',
            placeholder: 'Enter the TCS amount',
            visible: false,
            width: '25%',
            tab: 'General'
        },
        {
            label: 'Total Before TCS',
            key: 'total_before_tcs',
            type: 'number',
            placeholder: 'Enter the total before TCS',
            visible: false,
            width: '25%',
            tab: 'General'
        },
        {
            label: 'Shipping Charge',
            key: 'shipping_charge',
            type: 'number',
            placeholder: 'Enter the shipping charge',
            visible: false,
            width: '25%',
            tab: 'General'
        },
        {
            label: 'Discount Amount',
            key: 'discount_amount',
            type: 'number',
            placeholder: 'Enter the discount amount',
            visible: false,
            width: '25%',
            tab: 'General'
        },
        {
            label: 'Discount Percentage',
            key: 'discount_percentage',
            type: 'number',
            placeholder: 'Enter the discount amount',
            visible: false,
            width: '25%',
            tab: 'General'
        },
        {
            label: 'Adjustment',
            key: 'adjustment',
            type: 'number',
            placeholder: 'Enter the adjustment',
            visible: false,
            width: '25%',
            tab: 'General'
        },
        {
            label: 'Roundoff Value',
            key: 'roundoff_value',
            type: 'number',
            placeholder: 'Enter the roundoff value',
            visible: false,
            width: '25%',
            tab: 'General'
        },
        {
            label: 'Adjustment Description',
            key: 'adjustment_description',
            type: 'text',
            placeholder: 'Enter the adjustment description',
            visible: false,
            width: '25%',
            tab: 'General'
        },
        // {
        //     label: 'Balance',
        //     key: 'balance',
        //     type: 'number',
        //     placeholder: 'Enter the balance',
        //     visible: true,
        //     width: '25%',
        //     tab: 'General'
        // },
        {
            label: 'Internal Notes',
            key: 'notes',
            type: 'textarea',
            placeholder: 'Enter notes',
            visible: true,
            width: '50%',
            tab: 'General'
        },
        {
            label: 'Terms & Conditions',
            key: 'terms_conditions',
            type: 'textarea',
            placeholder: 'Enter terms & conditions',
            visible: true,
            width: '50%',
            tab: 'General'
        },
        {
            type: 'divider',
            heading: 'Billing Address',
            visible: true,
            span: 24,
            tab: 'Customer Details',
        },
        {
            label: 'Country',
            key: 'billing_country',
            type: 'select',
            placeholder: 'Select country',
            visible: true,
            width: '25%',
            tab: 'Customer Details',
            options: response.Countries || [],
            value: 'India'
        },
        {
            label: 'State',
            key: 'billing_state',
            type: 'text',
            placeholder: 'Enter state',
            visible: true,
            width: '25%',
            tab: 'Customer Details',
            value: 'Madhya Pradesh'
        },
        {
            label: 'Pincode',
            key: 'billing_pincode',
            type: 'text',
            placeholder: 'Enter pincode',
            visible: true,
            width: '25%',
            tab: 'Customer Details'
        },
        {
            label: 'City',
            key: 'billing_city',
            type: 'text',
            placeholder: 'Enter city',
            visible: true,
            width: '25%',
            tab: 'Customer Details'
        },
        {
            label: 'Address Line 1',
            key: 'billing_address_line_1',
            type: 'text',
            placeholder: 'Enter address line 1',
            visible: true,
            width: '25%',
            tab: 'Customer Details'
        },
        {
            label: 'Address Line 2',
            key: 'billing_address_line_2',
            type: 'text',
            placeholder: 'Enter address line 2',
            visible: true,
            width: '25%',
            tab: 'Customer Details'
        },
        {
            type: 'divider',
            heading: 'Shipping Address',
            visible: true,
            span: 24,
            tab: 'Customer Details'
        },
        {
            label: 'Country',
            key: 'shipping_country',
            type: 'select',
            placeholder: 'Select country',
            visible: true,
            width: '25%',
            tab: 'Customer Details',
            options: response.Countries || [],
            value: 'India'
        },
        {
            label: 'State',
            key: 'shipping_state',
            type: 'text',
            placeholder: 'Enter state',
            visible: true,
            width: '25%',
            tab: 'Customer Details',
            value: 'Madhya Pradesh'
        },
        {
            label: 'Pincode',
            key: 'shipping_pincode',
            type: 'text',
            placeholder: 'Enter pincode',
            visible: true,
            width: '25%',
            tab: 'Customer Details'
        }, {
            label: 'City',
            key: 'shipping_city',
            type: 'text',
            placeholder: 'Enter city',
            visible: true,
            width: '25%',
            tab: 'Customer Details'
        },
        {
            label: 'Address Line 1',
            key: 'shipping_address_line_1',
            type: 'text',
            placeholder: 'Enter address line 1',
            visible: true,
            width: '25%',
            tab: 'Customer Details'
        },
        {
            label: 'Address Line 2',
            key: 'shipping_address_line_2',
            type: 'text',
            placeholder: 'Enter address line 2',
            visible: true,
            width: '25%',
            tab: 'Customer Details'
        }
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                tab: 'General'
            });
        });
    }

    const defaults = response.defaults;

    if (defaults) {
        for (let key in defaults) {
            const field = formFields.find(field => field.key == key);
            if (field) {
                field.value = defaults[key];
            }
        }
    }

    return formFields;
}

const preferencesFields = (response, view = false, moduleName = null, OpenModalCustom) => {
    const formFields = [{
        type: 'divider',
        heading: moduleName + ' Numbering',
        visible: true,
        span: 24,
    }, {
        label: 'Prefix',
        key: 'number_prefix',
        type: 'text',
        placeholder: 'Enter the prefix',
        visible: true,
        width: '25%'
    },
    {
        label: 'Number',
        key: 'next_number',
        type: 'number',
        placeholder: 'Enter the number',
        visible: true,
        width: '25%'
    }, {
        label: 'Suffix',
        key: 'number_suffix',
        type: 'text',
        placeholder: 'Enter the suffix',
        visible: true,
        width: '25%'
    }, {
        type: 'divider',
        heading: 'General Settings',
        visible: true,
        span: 24,
    }, {
        label: 'Due Date will be X days from the ' + moduleName + ' Date',
        key: 'invoice_due_date',
        type: 'number',
        placeholder: 'Enter the due date',
        visible: moduleName == 'Invoices' ? true : false,
        width: '50%',
        value: 0
    }, {
        label: 'Sending auto emails on save?',
        key: 'send_auto_emails_on_save',
        type: 'checkbox',
        placeholder: 'Allow editing of Sent ' + moduleName + '?',
        visible: true,
        width: '50%',
        value: false
    }, {
        label: 'Allow editing of Sent ' + moduleName + '?',
        key: 'allow_editing_of_sent_' + moduleName.toLowerCase(),
        type: 'checkbox',
        placeholder: 'Allow editing of Sent ' + moduleName + '?',
        visible: true,
        width: '50%',
        value: true,
    }, {
        label: 'Allow 0 value ' + moduleName + '?',
        key: 'allow_zero_value_' + moduleName.toLowerCase(),
        type: 'checkbox',
        placeholder: 'Allow editing of Sent ' + moduleName + '?',
        visible: true,
        width: '50%',
        value: true
    }, {
        label: 'Allow negative total for ' + moduleName + '?',
        key: 'allow_negative_total_for_' + moduleName.toLowerCase(),
        type: 'checkbox',
        placeholder: 'Allow editing of Sent ' + moduleName + '?',
        visible: true,
        width: '50%',
        value: true
    }, {
        label: 'Turn off auto mark as delivered/received?',
        key: 'turn_off_auto_mark_as_delivered_or_received',
        type: 'checkbox',
        placeholder: 'Allow editing of Sent ' + moduleName + '?',
        visible: true,
        width: '50%',
        value: false
    }, {
        label: 'Hide Due Date Field?',
        key: 'hide_due_date_field',
        type: 'checkbox',
        placeholder: 'Hide Due Date Field?',
        visible: true,
        width: '50%',
        value: false
    }, {
        label: 'Hide Location Field?',
        key: 'hide_location_field',
        type: 'checkbox',
        placeholder: 'Hide Location Field?',
        visible: true,
        width: '50%',
        value: false
    }, {
        label: 'Hide Delivery Status Field?',
        key: 'hide_delivery_status_field',
        type: 'checkbox',
        placeholder: 'Hide Delivery Status Field?',
        visible: true,
        width: '50%',
        value: false
    }, {
        label: 'Give Discount on Item Level?',
        key: 'give_discount_on_item_level',
        type: 'checkbox',
        placeholder: 'Give Discount on Item Level',
        visible: true,
        width: '50%',
        value: false
    }]

    return formFields;
}

export const TransactionPDFPreview = ({ moduleName, obj }) => {
    const [pdfUrl, setPdfUrl] = useState(obj?.pdf_url);

    const [loading, setLoading] = useState(true);
    const intervalRef = useRef(null);

    useEffect(() => {
        if (pdfUrl) {
            setLoading(false); //stop loading
        } else {
            intervalRef.current = setInterval(async () => {
                if (obj._id) {
                    const { data } = await api.getSingle(moduleName, {
                        _id: obj._id
                    });
                    if (data.pdf_url) {
                        setPdfUrl(data.pdf_url);
                        setLoading(false);
                        clearInterval(intervalRef.current);
                    }
                }
            }, 1000);
        }
    }, [])

    if (loading) {
        return <View flex>
            <ActivityIndicator size="large" />
        </View>
    }

    return <View flex>
        {Platform.OS == 'web' ?
            <iframe src={pdfUrl} width="100%" style={{
                height: Dimensions.get('window').height - 200,
                maxWidth: '100%'
            }} />
            : <>
                <View row>
                    <PrimaryButton label="Print" onPress={async () => {
                        const html = obj?.html;

                        await Print.printAsync({
                            html,
                            width: 612,
                            height: 100,
                        });

                        // FileSystem.downloadAsync(pdfUrl, FileSystem.documentDirectory + 'invoice.pdf')
                        //     .then(({ uri }) => {
                        //         Print.printAsync({ uri: uri });
                        //     })
                        //     .catch(error => {
                        //         console.error(error);
                        //     });
                    }} />
                    <SecondaryButton label="Share" style={{ marginLeft: 10 }} onPress={async () => {
                        FileSystem.downloadAsync(pdfUrl, FileSystem.documentDirectory + 'invoice.pdf')
                            .then(({ uri }) => {
                                Sharing.shareAsync(uri);
                            })
                            .catch(error => {
                                console.error(error);
                            });
                    }} />
                </View>

                <View flex marginT-20>
                    {pdfUrl && <PDFReader
                        style={{
                            height: Dimensions.get('window').height - 200,
                            width: Dimensions.get('window').width - 40,
                        }}
                        source={{
                            uri: pdfUrl,
                        }}
                        //no cache
                        webviewProps={{
                            cacheEnabled: false,
                        }}
                    />}

                    {/* <WebView
                        source={{ html: obj?.html }}
                        style={{
                            height: Dimensions.get('window').height - 200,
                            width: Dimensions.get('window').width - 40,
                        }}
                    /> */}
                </View>
                {/* <WebView
                    source={{
                        uri: Platform.OS === 'android' ? `https://drive.google.com/viewerng/viewer?embedded=true&url=${pdfUrl}` : pdfUrl
                    }}
                    style={{
                        height: Dimensions.get('window').height - 200,
                        width: Dimensions.get('window').width - 40,
                    }}
                /> */}
            </>
        }
    </View>
}

const ExtraSaveComponent = (props) => {
    const { setFormObject, saveFnInternal } = props;

    return;

    // return <SecondaryButton
    //     style={{ marginLeft: 10 }}
    //     onPress={() => {
    //         setFormObject({ status: 'Delivered' })
    //         saveFnInternal();
    //     }} label={"Mark As Delivered"}></SecondaryButton>
}

export const transactionMobileCard = (item, index, getFieldValue, onRowClick) => {
    const radius = 10;


    return <Card flex className="hh" style={{ marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap', borderRadius: radius }}>
        <TouchableOpacity padding-10 flex onPress={() => {
            onRowClick(item);
        }}>
            <View marginB-10 row spread style={{ width: '100%' }}>
                <View center backgroundColor="#FF9595" style={{ width: 60, height: 60, borderRadius: 10 }}>
                    <Text style={{ color: 'white', fontSize: 20 }}>C</Text>
                </View>

                <View flex marginL-10>
                    <Text style={{ marginBottom: 5, fontSize: 16, fontFamily: 'SourceSansProBold' }}>
                        {getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}
                    </Text>
                    <View row spread>
                        <View flex>
                            <View row>
                                <Text lightCardTitle>Price: </Text>
                                <Text darkCardTitle>{getFieldValue(item, 'sales_rate') ? "" + getFieldValue(item, 'sales_rate') : ""}</Text>
                            </View>
                            <View row>
                                <Text lightCardTitle>Price: </Text>
                                <Text darkCardTitle>{getFieldValue(item, 'sales_rate') ? "" + getFieldValue(item, 'sales_rate') : ""}</Text>
                            </View>
                        </View>
                        <View flex>
                            <View row>
                                <Text lightCardTitle>Price: </Text>
                                <Text darkCardTitle>{getFieldValue(item, 'sales_rate') ? "" + getFieldValue(item, 'sales_rate') : ""}</Text>
                            </View>
                            <View row>
                                <Text lightCardTitle>Price: </Text>
                                <Text darkCardTitle>{getFieldValue(item, 'sales_rate') ? "" + getFieldValue(item, 'sales_rate') : ""}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>

            <View marginB-10 style={{ width: '100%', height: 4, backgroundColor: '#EFF5FF', borderRadius: 4 }} />

            <View row spread style={{ width: '100%' }}>
                <View row>
                    <Text lightCardTitle>Price: </Text>
                    <Text darkCardTitle>{getFieldValue(item, 'sales_rate') ? "" + getFieldValue(item, 'sales_rate') : ""}</Text>
                </View>
                <View row>
                    <Text lightCardTitle>Price: </Text>
                    <Text darkCardTitle>{getFieldValue(item, 'sales_rate') ? "" + getFieldValue(item, 'sales_rate') : ""}</Text>
                </View>
            </View>
        </TouchableOpacity>
        <TouchableOpacity>
            <View spread backgroundColor="#397CF2" style={{ width: 32, height: '100%', borderBottomEndRadius: radius, borderTopEndRadius: radius }}>
                <View center>
                    <Icon name="more-vertical-outline" fill={'white'} style={{ height: 16, width: 16, marginTop: 10 }} />
                </View>
                <View flex centerH style={{ justifyContent: 'flex-end' }}>
                    <Text style={{
                        color: 'white', fontSize: 10, transform: [
                            { rotate: "90deg" },
                            { translateX: -18 },
                        ],
                        letterSpacing: 1
                    }}>Sent</Text>
                </View>
            </View>
        </TouchableOpacity>
    </Card>;
}

export const transactionMain = {
    fields: transactionfields,
    mobileCard: null,
    tabs: [{
        label: 'All',
        key: 'All',
        value: {}
    }, {
        label: 'Draft',
        key: 'Draft',
        value: {
            status: 'Draft',
        }
    }],
    dataTabs: [{
        label: 'Preview',
        icon: 'printer-outline',
        component: TransactionPDFPreview
    }],
    preferencesFields: preferencesFields,
    ExtraSaveComponent: ExtraSaveComponent,
    customActions: (moduleName, navigationFn, route, row, setOpenModal, openCustomModal) => {
        let options = [
            // {
            //     label: 'Send via email',
            //     key: 'send',
            //     fn: (props) => {
            //         openCustomModal(<WhatsAppForm />)
            //     }
            // },
            // {
            //     label: 'Send via whatsapp',
            //     key: 'send',
            //     fn: (props) => {
            //     }
            // },
        ]

        if (moduleName == 'Invoices') {
            if (row.status == 'Draft' || row.status == 'Sent' || row.status == 'Pending' || !row.status) {
                options = [...options, {
                    label: 'Mark As Delivered',
                    key: 'mark_as_delivered',
                    fn: (props) => {
                        const row = props.row;
                        row.status = 'Delivered';
                        api.update(moduleName, row, row._id).then((res) => {
                            DeviceEventEmitter.emit('reloadListing');
                        })
                    }
                }]
            }

            if (row.balance > 0) {
                options.push({
                    label: 'Receive Payment',
                    key: 'receive_payment',
                    fn: (props) => {
                        if (Platform.OS === 'web') {
                            setOpenModal({
                                ["PaymentReceived"]: {
                                    isVisible: true,
                                    moduleName: "PaymentReceived",
                                    afterSaveEvent: 'reloadListing',
                                    is_default_values: true,
                                    contact_id: row.contact_id,
                                    adjusting_transaction: {
                                        _id: row._id,
                                        amount: row.balance,
                                    },
                                    total_amount: row.balance
                                }
                            })
                        } else {
                            navigationFn('New', {
                                moduleName: "PaymentReceived",
                                goBackAfterSave: true,
                                is_default_values: true,
                                contact_id: row.contact_id,
                                total_amount: row.balance
                            });
                        }
                    }
                })
            }

            options = [
                ...options,
                // {
                //     label: 'Generate E-Way Bill',
                //     key: 'generate_eway_bill',
                //     fn: (props) => {
                //     }
                // },
                // {
                //     label: 'Generate eInvoice (IRN) with eWay Bill',
                //     key: 'generate_einvoice',
                //     fn: (props) => {
                //     }
                // },
                // {
                //     label: 'Convert to Credit Note',
                //     key: 'convert_to_credit_note',
                //     fn: (props) => {
                //     }
                // }
            ]
        }

        if (moduleName == 'Bills') {
            if (row.status == 'Draft' || row.status == 'Sent' || row.status == 'Pending' || !row.status) {
                options = [...options, {
                    label: 'Mark As Received',
                    key: 'mark_as_received',
                    fn: (props) => {
                        const row = props.row;
                        row.status = 'Received';
                        api.update(moduleName, row, row._id).then((res) => {
                            DeviceEventEmitter.emit('reloadListing');
                        })
                    }
                }]
            }

            if (row.balance > 0) {
                options.push({
                    label: 'Payment Made',
                    key: 'payment_made',
                    fn: (props) => {
                        if (Platform.OS === 'web') {
                            setOpenModal({
                                ["PaymentMade"]: {
                                    isVisible: true,
                                    moduleName: "PaymentMade",
                                    afterSaveEvent: 'reloadListing',
                                    is_default_values: true,
                                    contact_id: row.contact_id,
                                    adjusting_transaction: {
                                        _id: row._id,
                                        amount: row.balance,
                                    },
                                    total_amount: row.balance
                                }
                            })
                        } else {
                            navigationFn('New', {
                                moduleName: "PaymentMade",
                                goBackAfterSave: true,
                                is_default_values: true,
                                contact_id: row.contact_id,
                                total_amount: row.balance
                            });
                        }
                    }
                })
            }

            options = [
                ...options,
            ]
        }

        options = [
            ...options.map((item) => {
                return { ...item, ignore_permission: true }
            }),
        ]

        return options;
    },
    customColumnRender: {
        status: (value, row, column) => {
            let color = '#007bff';

            if (row.status) {
                if (row.status == 'Draft') {
                    color = '#909090';
                } else if (row.status == 'Pending') {
                    color = '#ffa600';
                } else if (row.status == 'Delivered') {
                    color = '#007bff';
                }
            }

            const tinycolor = require("tinycolor2");
            const lighterColor = tinycolor(color).lighten(45).toString();

            return <View left>
                <View paddingH-8 paddingV-3 style={{ backgroundColor: lighterColor, borderRadius: 5 }}>
                    <Text
                        monoTableBody={false}
                        monoTableHeading={false}
                        style={[
                            column.customCellStyle,
                            { maxWidth: '100%' },
                            { color: color },
                            { fontFamily: 'SourceSansProSemiBold' }
                        ]} ellipsizeMode={'tail'} numberOfLines={1}>
                        {value}
                    </Text>
                </View>
            </View>
        },
        balance: (value, row, column) => {
            let color = null

            if (row.balance > 0) {
                color = '#ff0000';
            } else if (row.balance < 0) {
                color = '#ff0000';
            } else {
                // dark greenish color tag
                color = '#239523';
            }

            const tinycolor = require("tinycolor2");
            const lighterColor = tinycolor(color).lighten(45).toString();

            return <View left>
                <View paddingH-8 paddingV-3 style={{ backgroundColor: lighterColor, borderRadius: 5 }}>
                    <Text
                        monoTableBody={false}
                        monoTableHeading={false}
                        style={[
                            column.customCellStyle,
                            { maxWidth: '100%' },
                            { color: color },
                            { fontFamily: 'SourceSansProSemiBold' }
                        ]} ellipsizeMode={'tail'} numberOfLines={1}>
                        {value}
                    </Text>
                </View>
            </View>
        }
    }
}

function itemChangeFn(value, response, moduleName, getObj, setObj, setArrValue, tKey, tIndex, isEdit) {
    const item_id = value?.value || value;

    const item = response.Items.find((item) => item._id === item_id);
    const TaxGroups = response.TaxGroups || [];
    const tax_group = TaxGroups.find((tax_group) => tax_group._id === item.tax_group_id);

    const old_item = getObj()[tKey][tIndex];
    const old_item_start_date = old_item?.start_date || null;
    let new_item_end_date = null;

    if (old_item_start_date) {
        const duration_string = "" + item.subscription_duration;
        const endsWithOne = duration_string[duration_string.length - 1] == 1;
        const endsWithTwo = duration_string[duration_string.length - 1] == 2;

        if (endsWithOne) {
            const subscription_duration = item.subscription_duration - 1;
            const end_date = new Date(old_item_start_date);
            const days = subscription_duration / 86400000;
            const months = days / 30;
            end_date.setMonth(end_date.getMonth() + months);
            new_item_end_date = end_date.toISOString();
        } else if (endsWithTwo) {
            // this is for years
            const subscription_duration = item.subscription_duration - 2;
            const end_date = new Date(old_item_start_date);
            const days = subscription_duration / 86400000;
            const years = days / 365;
            end_date.setFullYear(end_date.getFullYear() + years);
            new_item_end_date = end_date.toISOString();
        } else {
            const subscription_duration = item.subscription_duration;
            const end_date = new Date(old_item_start_date);
            // const days = subscription_duration / 86400000;
            end_date.setTime(end_date.getTime() + subscription_duration);
            new_item_end_date = end_date.toISOString();
        }
    }


    const itemMap = {
        "item_id": item._id,
        "subscription_duration": item.subscription_duration,
        "product_type": item.product_type,
        "start_date": old_item_start_date,
        "end_date": new_item_end_date,
        "hsn_or_sac": item.hsn_or_sac,
        "description": item.description,
        "account_id": item.sales_account_id,
        "track_inventory": item.track_inventory,
        "track_serial_number": item.track_serial_number,
        "track_batch_number": item.track_batch_number,
        "usage_unit": item.usage_unit,
        "tax_group_id": item.tax_group_id,
        "tax_group": tax_group,
        "quantity": 1,
        "item_price": item.sales_rate || 0,
        "item_gross_total": item.sales_rate || 0,
        "item_total": item.sales_rate || 0,
        "discount_percentage": 0,
        "discount_amount": 0,
        "status": moduleName == 'Invoices' ? 'Pending' : moduleName == 'Bills' ? 'Pending' : 'Pending'
    };

    const subscription_item_usage = (getObj()['subscription_item_usage'] || []);

    const mapped_items = item.mapped_items || [];
    const new_subscription_item_usage = [];

    for (const mapped_item of mapped_items) {
        const subscription_item = subscription_item_usage.find((subscription_item) => subscription_item.item_id == mapped_item.item_id);
        if (subscription_item) {
            mapped_item.used_quantity = subscription_item.used_quantity;
        } else {
            mapped_item.used_quantity = 0;
        }
        mapped_item.package_item_id = item_id;
        new_subscription_item_usage.push(mapped_item);
    }

    setObj({ subscription_item_usage: new_subscription_item_usage });
    setArrValue(tKey, tIndex, itemMap);
    invoiceItemAdjustment(getObj(), setObj, setArrValue, response);

    if (Platform.OS == 'web' && (!value?.disable_auto_line_item && !isEdit)) {
        const line_items = getObj()[tKey];
        if (line_items[line_items.length - 1].item_id) {
            line_items.push({});
            setObj({ [tKey]: line_items });
        }
    }
}

export function invoiceItemAdjustment(formObject, setFormObject, setArrValue, response) {
    let INV = { ...formObject };

    let line_items = [...(formObject.line_items || [])];

    let total_adjustments = 0;
    let total_before_tax_and_adjustments = 0;
    let sub_total = 0;
    let total_quantity = 0;
    let total = 0;
    let tax_map = {};
    let inter_state = false;
    const location_id = INV.location_id;
    let adjustments = 0;

    if (!response.OrgPrefs?.discount_after_tax) {
        adjustments = RND(adjustments - +(INV.discount_amount || 0));
        adjustments = RND(adjustments + +(INV.shipping_charge || 0));
    }

    for (let i = 0; i < line_items.length; i++) {
        let IN_I = line_items[i];
        if (!IN_I?.item_id) {
            continue;
        }
        total_quantity += +(IN_I.quantity || 0);
    }

    for (let i = 0; i < line_items.length; i++) {
        let ITEM = { ...line_items[i] };

        if (!ITEM?.item_id) {
            continue;
        }

        let item_gross_total = +(ITEM.quantity) * +(ITEM.item_price);

        if (ITEM.discount_value == null || ITEM.discount_value == undefined) {
            ITEM.discount_value = 0;
        }

        ITEM.discount_value = RND(+(ITEM.discount_value));

        let item_level_adjustment = 0;

        if (ITEM.discount_type === 'percent') {
            const discount = RND((item_gross_total * (ITEM.discount_value / 100)));
            item_level_adjustment = RND(0 - (discount || 0));
            item_gross_total = RND(item_gross_total - discount);
        } else {
            item_level_adjustment = RND(0 - +(ITEM.discount_value || 0));
            item_gross_total = RND(item_gross_total - +(ITEM.discount_value || 0));
        }

        let taxable_amount = 0;
        let before_adjustment_amount = item_gross_total;
        let after_adjustment_amount = +(before_adjustment_amount);

        after_adjustment_amount += +(adjustments * +(ITEM.quantity)) / +(total_quantity);

        if (ITEM.is_tax_inclusive) {
            taxable_amount = RND((after_adjustment_amount * 100) / (100 + +(ITEM.item_tax_percentage)));
            before_adjustment_amount = RND(taxable_amount - ((adjustments * +(ITEM.quantity)) / +(total_quantity)));
        } else {
            taxable_amount = RND(+((ITEM.quantity) * +(ITEM.item_price) + (adjustments * +(ITEM.quantity)) / +(total_quantity)) + +(item_level_adjustment));
        }

        total_before_tax_and_adjustments = RND(total_before_tax_and_adjustments + before_adjustment_amount);

        const tax_group = ITEM.tax_group || {};

        let tax_amount = 0;
        let tax_details = [];

        if ((response.OrgPrefs?.place_of_supply || "Madhya Pradesh") == (INV.shipping_state || "Madhya Pradesh")) {
            const CGST_Taxes = tax_group.taxes?.filter((tax) => tax.tax_type == 'CGST') || [];
            const SGST_Taxes = tax_group.taxes?.filter((tax) => tax.tax_type == 'SGST') || [];

            for (let j = 0; j < CGST_Taxes.length; j++) {
                const new_value = RND((taxable_amount * +(CGST_Taxes[j].tax_percentage)) / 100);
                tax_amount += new_value;
                tax_map[CGST_Taxes[j].name] = tax_map[CGST_Taxes[j].name] ? tax_map[CGST_Taxes[j].name] + new_value : new_value;

                tax_details.push({
                    tax_id: CGST_Taxes[j]._id,
                    tax_name: CGST_Taxes[j].name,
                    tax_percentage: CGST_Taxes[j].tax_percentage,
                    tax_type: CGST_Taxes[j].tax_type,
                    tax_amount: new_value,
                    output_account_id: CGST_Taxes[j].output_account_id,
                    input_account_id: CGST_Taxes[j].input_account_id
                });
            }

            for (let j = 0; j < SGST_Taxes.length; j++) {
                const new_value = RND((taxable_amount * +(SGST_Taxes[j].tax_percentage)) / 100);
                tax_amount += new_value;
                tax_map[SGST_Taxes[j].name] = tax_map[SGST_Taxes[j].name] ? tax_map[SGST_Taxes[j].name] + new_value : new_value;

                tax_details.push({
                    tax_id: SGST_Taxes[j]._id,
                    tax_name: SGST_Taxes[j].name,
                    tax_percentage: SGST_Taxes[j].tax_percentage,
                    tax_type: SGST_Taxes[j].tax_type,
                    tax_amount: new_value,
                    output_account_id: SGST_Taxes[j].output_account_id,
                    input_account_id: SGST_Taxes[j].input_account_id
                });
            }

            inter_state = false;
        } else {
            const IGST_Taxes = tax_group.taxes?.filter((tax) => tax.tax_type == 'IGST') || [];

            for (let j = 0; j < IGST_Taxes.length; j++) {
                const new_value = RND((taxable_amount * +(IGST_Taxes[j].tax_percentage)) / 100);
                tax_amount += new_value;
                tax_map[IGST_Taxes[j].name] = tax_map[IGST_Taxes[j].name] ? tax_map[IGST_Taxes[j].name] + new_value : new_value;

                tax_details.push({
                    tax_id: IGST_Taxes[j]._id,
                    tax_name: IGST_Taxes[j].name,
                    tax_percentage: IGST_Taxes[j].tax_percentage,
                    tax_type: IGST_Taxes[j].tax_type,
                    tax_amount: new_value,
                    output_account_id: IGST_Taxes[j].output_account_id,
                    input_account_id: IGST_Taxes[j].input_account_id
                });
            }

            inter_state = true;
        }

        ITEM.item_tax_amount = tax_amount;
        ITEM.taxable_amount = taxable_amount;

        try {
            setArrValue('line_items', i, 'item_gross_total', item_gross_total);
            setArrValue('line_items', i, 'item_tax_amount', tax_amount);
            setArrValue('line_items', i, 'taxable_amount', taxable_amount);
            setArrValue('line_items', i, 'tax_details', tax_details);
            setArrValue('line_items', i, 'location_id', location_id);
        } catch (e) {
        }

        total_adjustments = adjustments;
        total = total + taxable_amount + tax_amount;
    }

    var after_tax_adjustments = 0;

    if (response.OrgPrefs?.discount_after_tax) {
        after_tax_adjustments = RND(after_tax_adjustments - (INV.discount_amount));
        after_tax_adjustments = RND(after_tax_adjustments + (INV.shipping_charge));
    }

    let final_total = total + after_tax_adjustments;
    let new_final_total = final_total;
    // new_final_total = +((final_total).toFixed(0));

    const rounded_total = +(new_final_total.toFixed(0));
    const round_off = +(rounded_total - new_final_total).toFixed(2);
    INV.adjustment = round_off;
    after_tax_adjustments = RND(after_tax_adjustments + (INV.adjustment));
    new_final_total = RND(final_total + (INV.adjustment));

    setFormObject({
        "sub_total": total_before_tax_and_adjustments,
        "total_before_tcs": total_before_tax_and_adjustments,
        "summary": total_before_tax_and_adjustments,
        "tax_map": tax_map,
        "total": new_final_total,
        "adjustment": INV.adjustment,
    });
}