import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";

export const batchNumberTrackingfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
    const formFields = [
        {
            label: 'Batch Number',
            key: 'batch_number',
            type: 'text',
            placeholder: 'Enter the batch number',
            visible: true,
            width: '25%',
        },
        {
            label: 'Transaction Type',
            key: 'transaction_type',
            type: 'text',
            placeholder: 'Enter the transaction type',
            visible: true,
            width: '25%',
        },
        {
            label: 'Location',
            key: 'location_id',
            type: 'select',
            placeholder: 'Select the location',
            visible: true,
            width: '25%',
            options: response.locations || [],
        },
        {
            label: 'Module Type',
            key: 'module_type',
            type: 'text',
            placeholder: 'Enter the module type',
            visible: true,
            width: '25%',
        },
        {
            label: 'Module ID',
            key: 'module_id',
            type: 'text',
            placeholder: 'Enter the module ID',
            visible: true,
            width: '25%',
        },
        {
            label: 'Module Number',
            key: 'module_number',
            type: 'text',
            placeholder: 'Enter the module number',
            visible: true,
            width: '25%',
        },
        {
            label: 'Narration',
            key: 'narration',
            type: 'text',
            placeholder: 'Enter the narration',
            visible: true,
            width: '25%',
        },
        {
            label: 'Quantity',
            key: 'quantity',
            type: 'number',
            placeholder: 'Enter the quantity',
            visible: true,
            width: '25%',
        },
        {
            label: 'Is Opening',
            key: 'isOpening',
            type: 'checkbox',
            placeholder: 'Select if it is an opening',
            visible: true,
            width: '25%',
        },
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                viewField: true,
            });
        });
    }

    return formFields;
}

export const batchNumberTrackingMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const batchNumberTrackingMain = {
    fields: batchNumberTrackingfields,
    mobileCard: null
}
