import React, { useEffect } from "react";
import { View, Card, Text, TouchableOpacity, Image } from "react-native-ui-lib";
import { addButton } from "../hrms/shiftModel";
import { CustomNumberInput, CustomSelect } from "../../mycomponents/DynamicForm";
import { Icon } from "react-native-eva-icons";
import { Alert, Platform } from "react-native";
import DynamicTable from "../../mycomponents/DynamicTable";
import { api } from "../../services/api";

export const manualJournalfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData, message) => {

    const DebitComponent = ({ field, useForm, tKey, tIndex }) => {
        const getFormObject = useForm((state) => state.getFormObject);
        const setFormObjectArray = useForm((state) => state.setFormObjectArray);
        const setFormObject = useForm((state) => state.setFormObject);

        return <View row center>
            {!view && <>
                <CustomNumberInput value={getFormObject()[tKey][tIndex]['debit']}
                    onChange={(value) => {
                        setFormObjectArray(tKey, tIndex, 'debit', value);
                        setFormObjectArray(tKey, tIndex, 'credit', 0);
                    }}
                    // onBlur={(value) => {
                    //     const lineItem = getFormObject()[tKey];
                    //     const debitTotal = lineItem.reduce((acc, item) => {
                    //         return acc + (item.debit || 0);
                    //     }, 0);
                    //     const creditTotal = lineItem.reduce((acc, item) => {
                    //         return acc + (item.credit || 0);
                    //     }, 0);
                    //     const diff = debitTotal - creditTotal;
                    //     if (diff > 0) {
                    //         setFormObjectArray(tKey, tIndex, 'credit', diff);
                    //     } else {
                    //         setFormObjectArray(tKey, tIndex, 'debit', -diff);
                    //     }
                    // }}
                    onFocus={(value) => {
                        console.log("onFocus", value);
                        const lineItems = getFormObject()[tKey];
                        const debitTotal = lineItems.reduce((acc, item) => {
                            return acc + (item.debit || 0);
                        }, 0);
                        const creditTotal = lineItems.reduce((acc, item) => {
                            return acc + (item.credit || 0);
                        }, 0);
                        const diff = debitTotal - creditTotal;

                        const credit_value = getFormObject()[tKey][tIndex]['credit'];

                        if (!value && diff < 0 && !credit_value) {
                            setFormObjectArray(tKey, tIndex, 'debit', Math.abs(diff));
                        }
                    }}
                ></CustomNumberInput>
            </>}
            {view && <Text value style={{
                lineHeight: 39
            }}>
                ₹ {getFormObject()[tKey][tIndex].debit || 0}
            </Text>}
        </View>
    }

    const CreditComponent = ({ field, useForm, tKey, tIndex }) => {
        const getFormObject = useForm((state) => state.getFormObject);
        const setFormObjectArray = useForm((state) => state.setFormObjectArray);
        const setFormObject = useForm((state) => state.setFormObject);

        return <View row center>
            {!view && <>
                <CustomNumberInput independent label="Discount" value={getFormObject()[tKey][tIndex]['credit']}
                    onChange={(value) => {
                        setFormObjectArray(tKey, tIndex, 'credit', value);
                        setFormObjectArray(tKey, tIndex, 'debit', 0);
                    }}
                    onFocus={(value) => {
                        const lineItem = getFormObject()[tKey];
                        const debitTotal = lineItem.reduce((acc, item) => {
                            return acc + (item.debit || 0);
                        }, 0);
                        const creditTotal = lineItem.reduce((acc, item) => {
                            return acc + (item.credit || 0);
                        }, 0);
                        const diff = debitTotal - creditTotal;
                        const debit_value = getFormObject()[tKey][tIndex]['debit'];

                        if (!value && diff > 0 && !debit_value) {
                            setFormObjectArray(tKey, tIndex, 'credit', diff);
                        }
                    }}
                ></CustomNumberInput>
            </>}
            {view && <Text value style={{
                lineHeight: 39
            }}>
                ₹ {getFormObject()[tKey][tIndex].credit || 0}
            </Text>}
        </View>
    }

    const formFields = [
        {
            label: 'Entry ID',
            key: 'entry_id',
            type: 'text',
            placeholder: 'Enter the entry ID',
            visible: false,
            width: '25%',
        },
        {
            label: 'Type',
            key: 'display_type',
            type: 'select',
            options: [
                { label: 'Journal', value: 'Journal' },
                { label: 'Sales', value: 'Sales' },
                { label: 'Purchase', value: 'Purchase' },
                { label: 'Receipt', value: 'Receipt' },
                { label: 'Payment', value: 'Payment' },
                { label: 'Credit Note', value: 'Credit Note' },
                { label: 'Debit Note', value: 'Debit Note' },
            ],
            placeholder: 'Enter the date',
            visible: true,
            width: '25%',
            value: "Journal",
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                setObj({
                    updateVisiblity: Math.random(),
                });
            },
            autoFocus: isEdit ? false : true,
        },
        {
            label: 'Date',
            key: 'date',
            type: 'date',
            placeholder: 'Enter the date',
            visible: true,
            width: '25%',
            value: new Date(),
        },
        {
            label: 'Narration',
            key: 'narration',
            type: 'text',
            placeholder: 'Enter the narration',
            visible: true,
            width: '25%',
            value: "Manual Journal Entry"
        },
        {
            label: 'Internal Notes (If Any)',
            key: 'reference_number',
            type: 'text',
            placeholder: 'Enter the Internal Notes',
            visible: true,
            width: '25%',
        },
        {
            label: 'Line Items',
            key: 'line_items',
            type: 'table',
            placeholder: 'Enter the line items',
            visible: true,
            width: '100%',
            editable: true,
            columns: [
                {
                    label: 'Account',
                    field: 'account_id',
                    type: 'select',
                    options: (response.Accounts || []),
                    editable: true,
                    visible: true,
                    onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                        const display_type = getObj().display_type;
                        const line_items = getObj()[tKey];
                        if (line_items[line_items.length - 1].account_id) {
                            line_items.push({});
                            setObj({ [tKey]: line_items });
                        }

                        const account = response.Accounts.find((item) => item._id === value);

                        if (account.type == "Cash" && display_type == "Journal") {
                            if (Platform.OS == "web") {
                                message.info("Please update the TRANSACTION TYPE!");
                            } else {
                                Alert.alert("Please update the TRANSACTION TYPE!");
                            }
                        }

                        if (account.type == "Personal Account" && display_type == "Journal") {
                            if (Platform.OS == "web") {
                                message.info("Please update the TRANSACTION TYPE!");
                            } else {
                                Alert.alert("Please update the TRANSACTION TYPE!");
                            }
                        }
                    },
                    extraRenderForSelectedOption: (props) => {
                        const value = props.value;
                        const account = response.Accounts.find((item) => item._id === value);
                        const showTracking = moduleName == 'Invoices' || moduleName == 'Bills';
                        // field={props.field} useForm={props.useForm} tKey={props.tKey} tIndex={props.tIndex}
                        const { field, useForm, tKey, tIndex } = props;

                        const employee_id = useForm((state) => state.formObject?.[tKey]?.[tIndex]?.['employee_id']);
                        const debit = useForm((state) => state.formObject?.[tKey]?.[tIndex]?.['debit']);
                        const credit = useForm((state) => state.formObject?.[tKey]?.[tIndex]?.['credit']);
                        const manual_journal_id = useForm((state) => state.formObject['_id']);

                        const setFormObjectArray = useForm((state) => state.setFormObjectArray);
                        const setFormObject = useForm((state) => state.setFormObject);
                        const getFormObject = useForm((state) => state.getFormObject)
                        const [transactions, setTransactions] = React.useState([]);

                        useEffect(() => {
                            if (credit > 0) {
                                const getTransactions = async () => {
                                    const response2 = await api.customRoute("PaymentReceived" + '/action', {
                                        action: 'getTransactions',
                                        contact_id: account.contact_id,
                                        contact_payment_id: null,
                                        manual_journal_id: manual_journal_id,
                                    });

                                    let amount = credit;

                                    const sortedTransactions = response2;

                                    sortedTransactions.sort((a, b) => {
                                        return new Date(a.date).getTime() - new Date(b.date).getTime();
                                    });

                                    sortedTransactions.forEach((transaction, index) => {
                                        if (amount > 0) {
                                            if (amount >= transaction.balance) {
                                                amount = amount - transaction.balance;
                                                transaction.amount = transaction.balance;
                                            } else {
                                                transaction.amount = amount;
                                                amount = 0;
                                            }
                                        } else {
                                            transaction.amount = 0;
                                        }
                                    });

                                    console.log("sortedTransactions", sortedTransactions);

                                    setTransactions(sortedTransactions)
                                }
                                getTransactions();
                            } else if (debit > 0) {
                                const getTransactions = async () => {
                                    const response2 = await api.customRoute("PaymentMade" + '/action', {
                                        action: 'getTransactions',
                                        contact_id: account.contact_id,
                                        contact_payment_id: null,
                                        manual_journal_id: manual_journal_id,
                                    });

                                    let amount = debit;

                                    const sortedTransactions = response2;

                                    sortedTransactions.sort((a, b) => {
                                        return new Date(a.date).getTime() - new Date(b.date).getTime();
                                    });

                                    sortedTransactions.forEach((transaction, index) => {
                                        if (amount > 0) {
                                            if (amount >= transaction.balance) {
                                                amount = amount - transaction.balance;
                                                transaction.amount = transaction.balance;
                                            } else {
                                                transaction.amount = amount;
                                                amount = 0;
                                            }
                                        } else {
                                            transaction.amount = 0;
                                        }
                                    });

                                    setTransactions(sortedTransactions)
                                }
                                getTransactions();
                            }
                        }, [debit, credit]);

                        return account ? <View>
                            {account.name == "Cash" && <View style={{
                                width: '100%',
                                marginBottom: 10,
                                marginTop: 20
                            }}>
                                <CustomSelect
                                    value={employee_id}
                                    onChange={(value) => {
                                        setFormObjectArray(tKey, tIndex, 'employee_id', value);
                                    }}
                                    options={response.Employees || []}
                                    placeholder='Select the employee'
                                />
                            </View>}
                            {/* {account.type == "Personal Account" && transactions.length > 0 && <View style={{
                                width: '100%',
                                paddingLeft: 15,
                                marginTop: 15,
                                marginBottom: 10
                            }}>
                                <DynamicTable
                                    editable={true}
                                    columns={[
                                        {
                                            "field": "date",
                                            "accessor": "date",
                                            "label": "Date",
                                            "Header": "Date",
                                            "type": "date",
                                            "editable": false,
                                            "visible": true,
                                        }, {
                                            "field": "number",
                                            "accessor": "number",
                                            "label": "Voucher No",
                                            "Header": "Voucher No",
                                            "editable": false,
                                            "visible": true,
                                            "type": "moduleLink",
                                        }, {
                                            "field": "balance",
                                            "accessor": "balance",
                                            "label": "Old Balance",
                                            "Header": "Old Balance",
                                            "type": "number",
                                            "editable": false,
                                            "visible": true,
                                        }, {
                                            "field": "amount",
                                            "accessor": "amount",
                                            "label": "Adjusted Amount",
                                            "Header": "Adjusted Amount",
                                            "type": "number",
                                            "editable": true,
                                            "visible": true,
                                            "onChange": ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                                                const total_amount = getObj().total_amount || 0;
                                                let new_total = 0;
                                                const transactions = [...(getObj().transactions ?? [])];

                                                for (let i = 0; i < transactions.length; i++) {
                                                    if (transactions[i].amount > 0) {
                                                        new_total += transactions[i].amount;
                                                    }
                                                }

                                                if (new_total > total_amount) {
                                                    setObj({
                                                        total_amount: new_total
                                                    });
                                                } else if (!total_amount) {
                                                    setObj({
                                                        total_amount: new_total
                                                    });
                                                }
                                            }
                                        }
                                    ]}
                                    data={transactions}
                                    updateData={(newData) => {
                                        setTransactions(newData);
                                    }}
                                    compact={true}
                                    viewOnly={false}
                                    alignRowCenter={true}
                                />
                            </View>} */}
                        </View> : <></>;
                    },
                    "grow": true
                },
                {
                    label: 'Debit',
                    field: 'debit',
                    type: 'custom',
                    editable: true,
                    visible: true,
                    component: DebitComponent,
                    width: 250,
                    value: 0,
                },
                {
                    label: 'Credit',
                    field: 'credit',
                    type: 'custom',
                    editable: true,
                    visible: true,
                    component: CreditComponent,
                    width: 250,
                    value: 0,
                }, {
                    "field": "action_column",
                    "label": "",
                    "editable": true,
                    "type": "custom",
                    "width": 80,
                    "component": ({ field, useForm, tKey, tIndex }) => {
                        const getObj = useForm((state) => state.getFormObject);
                        const setObj = useForm((state) => state.setFormObject);
                        const setArrValue = useForm((state) => state.setFormObjectArray);
                        const line_items = getObj()[tKey];

                        return (Platform.OS == 'web' && (line_items.length - 1) != tIndex) ? <View row centerV style={{
                            height: 40
                        }}>
                            <button className="focusable" style={{
                                paddingTop: 5,
                                paddingBottom: 5,
                            }} onClick={() => {
                                const obj = getObj();
                                const arr = [...obj[tKey]];
                                arr.splice(tIndex, 1);
                                setObj({
                                    [tKey]: arr
                                });
                            }}>
                                <Image source={{ uri: `https://inkapps.pages.dev/icons/delete.png` }} style={{ width: 16, height: 16, marginLeft: 10, marginRight: 8 }} />
                            </button>
                        </View> : <></>;
                    },
                    "visible": true,
                    "order": 22
                }
            ].map((col) => { return { ...col, accessor: col.field, Header: col.label } }),
            value: [{}],
        },
        // addButton("Add Line Item", "add_line_items", "line_items", {}),
        {
            label: 'Line Item Total',
            key: 'line_item_total',
            type: 'number',
            placeholder: 'Enter the line item total',
            visible: false,
            width: '25%',
        },
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                viewField: true,
            });
        });
    }

    return formFields;
}

export const manualJournalMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const manualJournalMain = {
    fields: manualJournalfields,
    mobileCard: null
}




