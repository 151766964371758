import * as FaceDetector from "expo-face-detector"
import React, { useEffect, useReducer, useRef, useState } from "react"
import { StyleSheet, Text, View, Dimensions, PixelRatio, Alert, TouchableWithoutFeedback, Platform } from "react-native"
import { Camera, FaceDetectionResult } from "expo-camera"
import { AnimatedCircularProgress } from "react-native-circular-progress"
import { useNavigation } from "@react-navigation/native"
import Svg, { Path, SvgProps } from "react-native-svg"
import { TouchableOpacity } from "react-native-ui-lib"
import mime from 'mime';
import * as FileSystem from 'expo-file-system';

const { width: windowWidth } = Dimensions.get("window")

const detections = {
  BLINK: { promptText: "Blink both eyes", minProbability: 0.4 },
  SMILE: { promptText: "Smile", minProbability: 0.4 }
}

const promptsText = {
  noFaceDetected: "No face detected",
  performActions: "Perform the following actions:"
}

const detectionsList = [
  "BLINK",
  "SMILE"
]

const initialState = {
  faceDetected: false,
  promptText: promptsText.noFaceDetected,
  detectionsList,
  currentDetectionIndex: 0,
  progressFill: 0,
  processComplete: false
}

export default function FaceAuth(props) {
  const { employee, onAuthenticated } = props;

  const navigation = useNavigation()
  const [hasPermission, setHasPermission] = useState(false)
  const [state, dispatch] = useReducer(detectionReducer, initialState)
  const rollAngles = useRef([])
  const rect = useRef(null)
  const cameraRef = useRef(null);

  // // Screen Ratio and image padding
  const [imagePadding, setImagePadding] = useState(0);
  const [ratio, setRatio] = useState('4:3');
  const { height, width } = Dimensions.get('window');
  const screenRatio = height / width;
  const [isRatioSet, setIsRatioSet] = useState(false);

  useEffect(() => {
    const requestPermissions = async () => {
      const { status } = await Camera.requestCameraPermissionsAsync()
      setHasPermission(status === "granted")
    }

    requestPermissions()
  }, [])

  const drawFaceRect = (face) => {
    rect.current?.setNativeProps({
      width: face.bounds.size.width,
      height: face.bounds.size.height,
      top: face.bounds.origin.y,
      left: face.bounds.origin.x
    })
  }

  const onFacesDetected = async (result) => {
    if (result.faces.length !== 1) {
      dispatch({ type: "FACE_DETECTED", value: "no" })
      return
    }

    const face = result.faces[0]

    // offset used to get the center of the face, instead of top left corner
    const midFaceOffsetY = face.bounds.size.height / 2
    const midFaceOffsetX = face.bounds.size.width / 2

    drawFaceRect(face)
    // make sure face is centered
    const faceMidYPoint = face.bounds.origin.y + midFaceOffsetY
    if (
      // if middle of face is outside the preview towards the top
      faceMidYPoint <= PREVIEW_MARGIN_TOP ||
      // if middle of face is outside the preview towards the bottom
      faceMidYPoint >= PREVIEW_SIZE + PREVIEW_MARGIN_TOP
    ) {
      dispatch({ type: "FACE_DETECTED", value: "no" })
      return
    }

    const faceMidXPoint = face.bounds.origin.x + midFaceOffsetX
    if (
      // if face is outside the preview towards the left
      faceMidXPoint <= windowWidth / 2 - PREVIEW_SIZE / 2 ||
      // if face is outside the preview towards the right
      faceMidXPoint >= windowWidth / 2 + PREVIEW_SIZE / 2
    ) {
      dispatch({ type: "FACE_DETECTED", value: "no" })
      return
    }

    // drawFaceRect(face)

    if (!state.faceDetected) {
      dispatch({ type: "FACE_DETECTED", value: "yes" })
    }

    const detectionAction = state.detectionsList[state.currentDetectionIndex]

    switch (detectionAction) {
      case "BLINK":
        // lower probabiltiy is when eyes are closed
        const leftEyeClosed =
          face.leftEyeOpenProbability <= detections.BLINK.minProbability
        const rightEyeClosed =
          face.rightEyeOpenProbability <= detections.BLINK.minProbability
        if (leftEyeClosed && rightEyeClosed) {
          dispatch({ type: "NEXT_DETECTION", value: null })
        }
        return
      case "SMILE":
        if (face.smilingProbability >= detections.SMILE.minProbability) {
          const photo = await cameraRef.current.takePictureAsync({
            quality: 0.3,
            base64: true
          });
          let base64File = null;

          if (Platform.OS != 'web') {
            const uri = photo.uri || photo;
            base64File = await FileSystem.readAsStringAsync(uri, { encoding: 'base64' });
            const mimeType = mime.getType(uri);
            base64File = 'data:' + mimeType + ';base64,' + base64File;
          } else {
            base64File = photo.base64 || photo.uri;
          }

          const extension = base64File.split(';')[0].split('/')[1];

          const data = {
            file: base64File,
            extension: extension,
          }

          onAuthenticated(data)
          dispatch({ type: "NEXT_DETECTION", value: null })
        }
        return
    }
  }

  if (hasPermission === false) {
    return <Text>No access to camera</Text>
  }

  const prepareRatio = async () => {
    let desiredRatio = '4:3';
    if (Platform.OS === 'android') {
      const ratios = await cameraRef.current.getSupportedRatiosAsync();
      let distances = {};
      let realRatios = {};
      let minDistance = null;
      for (const ratio of ratios) {
        const parts = ratio.split(':');
        const realRatio = parseInt(parts[0]) / parseInt(parts[1]);
        realRatios[ratio] = realRatio;
        const distance = screenRatio - realRatio;
        distances[ratio] = realRatio;
        if (minDistance == null) {
          minDistance = ratio;
        } else {
          if (distance >= 0 && distance < distances[minDistance]) {
            minDistance = ratio;
          }
        }
      }
      desiredRatio = minDistance;
      const remainder = Math.floor((height - realRatios[desiredRatio] * width) / 2);
      setImagePadding(remainder);
      setRatio(desiredRatio);
      setIsRatioSet(true);
    }
  };

  const setCameraReady = async () => {
    if (!isRatioSet) {
      await prepareRatio();
    }
  };

  return (
    <View style={styles.container}>
      <View
        style={{
          position: "absolute",
          top: 0,
          width: "100%",
          height: PREVIEW_MARGIN_TOP,
          backgroundColor: "white",
          zIndex: 10
        }}
      />
      <View
        style={{
          position: "absolute",
          top: PREVIEW_MARGIN_TOP,
          left: 0,
          width: (windowWidth - PREVIEW_SIZE) / 2,
          height: PREVIEW_SIZE,
          backgroundColor: "white",
          zIndex: 10
        }}
      />
      <View
        style={{
          position: "absolute",
          top: PREVIEW_MARGIN_TOP,
          right: 0,
          width: (windowWidth - PREVIEW_SIZE) / 2 + 1,
          height: PREVIEW_SIZE,
          backgroundColor: "white",
          zIndex: 10
        }}
      />
      <Camera
        ref={cameraRef}
        style={[styles.cameraPreview, { marginBottom: imagePadding }]}
        onCameraReady={setCameraReady}
        ratio={ratio}
        type={Camera.Constants.Type.front}
        onFacesDetected={onFacesDetected}
        faceDetectorSettings={{
          mode: FaceDetector.FaceDetectorMode.fast,
          detectLandmarks: FaceDetector.FaceDetectorLandmarks.none,
          runClassifications: FaceDetector.FaceDetectorClassifications.all,
          minDetectionInterval: 0,
          tracking: false
        }}
      >
        <CameraPreviewMask width={"100%"} style={styles.circularProgress} />
        <AnimatedCircularProgress
          style={styles.circularProgress}
          size={PREVIEW_SIZE}
          width={5}
          backgroundWidth={7}
          fill={state.progressFill}
          tintColor="#3485FF"
          backgroundColor="#e8e8e8"
        />

      </Camera>
      <View
        ref={rect}
        style={{
          position: "absolute",
          borderWidth: 2,
          borderColor: "pink",
          zIndex: 10
        }}
      />
      <View style={styles.promptContainer}>
        <Text style={styles.faceStatus}>
          {!state.faceDetected && promptsText.noFaceDetected}
        </Text>

        {Platform.OS != 'android' && <TouchableOpacity
          center
          onPress={async () => {
            const photo = await cameraRef.current.takePictureAsync({
              quality: 0.3
            });
            let base64File = null;

            if (Platform.OS != 'web') {
              const uri = photo.uri || photo;
              base64File = await FileSystem.readAsStringAsync(uri, { encoding: 'base64' });
              const mimeType = mime.getType(uri);
              base64File = 'data:' + mimeType + ';base64,' + base64File;
            } else {
              base64File = photo.uri;
            }

            const extension = base64File.split(';')[0].split('/')[1];

            const data = {
              file: base64File,
              extension: extension,
            }

            onAuthenticated(data);
          }}>
          <Text style={{
            textAlign: 'center',
          }}>
            Take Selfie and Authenticate
          </Text>
        </TouchableOpacity>}

        <Text style={styles.actionPrompt}>
          {state.faceDetected && promptsText.performActions}
        </Text>
        <Text style={styles.action}>
          {state.faceDetected &&
            detections[state.detectionsList[state.currentDetectionIndex]]
              .promptText}
        </Text>
      </View>
    </View>
  )
}


const detectionReducer = (state, action) => {
  const numDetections = state.detectionsList.length
  const newProgressFill = (100 / (numDetections + 1)) * (state.currentDetectionIndex + 1);

  console.log("state.detectionsList", state.detectionsList);
  console.log("action.type", action.type);
  console.log("newProgressFill", newProgressFill);
  console.log("state.currentDetectionIndex", state.currentDetectionIndex);

  // state.detectionsList ["BLINK", "SMILE"]
  // LOG  action.type FACE_DETECTED
  // LOG  newProgressFill 33.333333333333336
  // LOG  state.currentDetectionIndex 0
  // LOG  state.detectionsList ["BLINK", "SMILE"]
  // LOG  action.type FACE_DETECTED
  // LOG  newProgressFill 33.333333333333336
  // LOG  state.currentDetectionIndex 0
  // LOG  state.detectionsList ["BLINK", "SMILE"]
  // LOG  action.type NEXT_DETECTION
  // LOG  newProgressFill 33.333333333333336
  // LOG  state.currentDetectionIndex 0
  // LOG  Getting Permissions

  switch (action.type) {
    case "FACE_DETECTED":
      if (action.value === "yes") {
        return { ...state, faceDetected: true, progressFill: newProgressFill }
      } else {
        // Reset
        return initialState
      }
    case "NEXT_DETECTION":
      const nextIndex = state.currentDetectionIndex + 1
      if (nextIndex === numDetections) {
        // success
        return { ...state, processComplete: true, progressFill: 100 }
      }
      // next
      return {
        ...state,
        currentDetectionIndex: nextIndex,
        progressFill: newProgressFill
      }
    default:
      throw new Error("Unexpeceted action type.")
  }
}

const CameraPreviewMask = (props) => (
  <Svg width={300} height={300} viewBox="0 0 300 300" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M150 0H0v300h300V0H150zm0 0c82.843 0 150 67.157 150 150s-67.157 150-150 150S0 232.843 0 150 67.157 0 150 0z"
      fill="#fff"
    />
  </Svg>
)

const PREVIEW_MARGIN_TOP = 50
const PREVIEW_SIZE = 300

const styles = StyleSheet.create({
  actionPrompt: {
    fontSize: 20,
    textAlign: "center"
  },
  container: {
    flex: 1,
    backgroundColor: "#fff"
  },
  promptContainer: {
    position: "absolute",
    alignSelf: "center",
    top: PREVIEW_MARGIN_TOP + PREVIEW_SIZE,
    height: "100%",
    width: "100%",
    backgroundColor: "white"
  },
  faceStatus: {
    fontSize: 24,
    textAlign: "center",
    marginTop: 10
  },
  cameraPreview: {
    flex: 1
  },
  circularProgress: {
    position: "absolute",
    width: PREVIEW_SIZE,
    height: PREVIEW_SIZE,
    top: PREVIEW_MARGIN_TOP,
    alignSelf: "center"
  },
  action: {
    fontSize: 24,
    textAlign: "center",
    marginTop: 10,
    fontWeight: "bold"
  }
})