import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";

export const equipmentfields = (response) => {
    const formFields = [
        {
            type: 'divider',
            heading: 'Equipment Details',
            visible: true,
            span: 24,
        },

        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter equipment Name',
            visible: true,
            width: '25%',
        },

        {
            label: 'Description',
            key: 'description',
            type: 'text',
            placeholder: 'Enter equipment Description',
            visible: true,
            width: '25%',
        },

        {
            label: 'Image',
            key: 'image',
            type: 'text',
            placeholder: 'Enter equipment Image',
            visible: true,
            width: '25%',
        },

        {
            label: 'Video',
            key: 'video',
            type: 'text',
            placeholder: 'Enter equipment Video',
            visible: true,
            width: '25%',
        },

        {
            label: 'Body Parts',
            key: 'body_parts',
            type: 'select',
            placeholder: 'Enter equipment Body Parts',
            visible: true,
            width: '25%',
            options: [
                { value: 'Biceps', label: 'Biceps' },
                { value: 'Triceps', label: 'Triceps' },
                { value: 'Shoulders', label: 'Shoulders' },
                { value: 'Chest', label: 'Chest' },
                { value: 'Back', label: 'Back' },
                { value: 'Abs', label: 'Abs' },
                { value: 'Glutes', label: 'Glutes' },
                { value: 'Quadriceps', label: 'Quadriceps' },
                { value: 'Hamstrings', label: 'Hamstrings' },
                { value: 'Calves', label: 'Calves' },
                { value: 'Cardio', label: 'Cardio' },
                { value: 'Full Body', label: 'Full Body' },
            ],
            multiple: true,
        },

        {
            label: 'Difficulty Level',
            key: 'difficulty_level',
            type: 'select',
            placeholder: 'Enter equipment Difficulty Level',
            visible: true,
            width: '25%',
            options: [
                { value: 'Beginner', label: 'Beginner' },
                { value: 'Intermediate', label: 'Intermediate' },
                { value: 'Advanced', label: 'Advanced' },
            ]
        },
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%'
            });
        });
    }

    return formFields;
}

export const equipmentMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const equipmentMain = {
    fields: equipmentfields,
    mobileCard: null
}