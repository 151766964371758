"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GlobalLoaderStore = void 0;
var _asyncStorage = _interopRequireDefault(require("@react-native-async-storage/async-storage"));
var _reactNative = require("react-native");
var _zustand = require("zustand");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var GlobalLoaderStore = exports.GlobalLoaderStore = (0, _zustand.create)(function (set, get) {
  return {
    loading: false,
    setLoading: function setLoading(value) {
      set(function (state) {
        return {
          loading: value
        };
      });
    }
  };
});