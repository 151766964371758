import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";
import { Col, CustomCheckbox, Row } from "../../mycomponents/DynamicForm";

export const rolefields = (response) => {

  const PermissionsComponent = ({ field, useForm, tKey, tIndex }) => {
    const defaultPermissions = {
      // NotificationTemplates: {
      //   view: true,
      //   create: true,
      //   update: true,
      //   delete: true
      // },
      // DocumentTemplates: {
      //   view: true,
      //   create: true,
      //   update: true,
      //   delete: true
      // },
      Accounts: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      // BankTransactions: {
      //   view: true,
      //   create: true,
      //   update: true,
      //   delete: true
      // },
      // Categories: {
      //   view: true,
      //   create: true,
      //   update: true,
      //   delete: true
      // },
      Contacts: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Appointments: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Subscriptions: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      PaymentReceived: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      MoneyTransfers: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      PaymentMade: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      // Expenses: {
      //   view: true,
      //   create: true,
      //   update: true,
      //   delete: true
      // },
      // ContactLedgers: {
      //   view: true,
      //   create: true,
      //   update: true,
      //   delete: true
      // },
      // CustomFields: {
      //   view: true,
      //   create: true,
      //   update: true,
      //   delete: true
      // },
      Items: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      ManualJournals: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Inquiries: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Quotations: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      SalesOrders: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      DeliveryOrders: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Invoices: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      SalesReturns: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      PurchaseOrders: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Bills: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      PurchaseReturns: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Locations: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Taxes: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      TaxGroups: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Tcs: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      "report/AttendanceByDate": {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      "report/AttendanceByEmployee": {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Employees: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Jobs: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      LeaveCategories: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      ReimbursementAdvances: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      ReimbursementCategories: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      ReimbursementClaims: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      PayrollSalaryItems: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      PayrollDetails: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Payrolls: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      PayrollStatutoryComponents: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Shifts: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      ShiftSchedulerDays: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      LeaveRequests: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Transfers: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      ItemAdjustments: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Projects: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Tasks: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Units: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Brands: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Companies: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Departments: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Designations: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Teams: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Roles: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Positions: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Holidays: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      VisibleFields: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      LeaveBalanceOverrides: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      ApprovalRequests: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      ApprovalRules: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      ApprovalWorkflows: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      Courses: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      AccountingReports: {
        view: true,
        create: true,
        update: true,
        delete: true
      },
      ItemTracing: {
        view: true,
        create: true,
      },
      BarcodePrinting: {
        view: true,
        create: true,
      },
      table: {
        view: true,
        // create: true,
      },
      POS: {
        view: true,
        create: true,
      },
      AttendanceByEmployee: {
        view: true,
      },
      AttendanceByDate: {
        view: true,
      },
      PaymentCollectionByEmployee: {
        view: true,
      },
      PaymentCollectionByEmployee: {
        view: true,
      },
      UnsoldSerialNumbersByLocation: {
        view: true,
      },
      PendingDeliveriesByLocation: {
        view: true,
      },
      CollectionSummaryCard: {
        view: true,
      },
      GlobalCollectionSummaryCard: {
        view: true,
      },
      BalanceCard: {
        view: true,
      },
      CheckInCard: {
        view: true,
      }
    };

    let permissions = useForm((state) => state.formObject['permissions']) || defaultPermissions;
    const setFormObject = useForm((state) => state.setFormObject);

    permissions = { ...defaultPermissions, ...permissions };

    for (let key in permissions) {
      permissions[key] = { ...defaultPermissions[key], ...permissions[key] };
    }

    const formattedText = (text) => {
      return text.split('_').map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }).join(' ');
    }

    const formattedTitle = (text) => {
      text = text.replace('report/', '');

      return text.split(/(?=[A-Z])/).map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }).join(' ');
    }

    return <View style={{ width: '100%' }}>
      <Row style={{ width: '100%' }}>
        {Object.keys(permissions).map((key, index) => {
          return <Col width={"20%"} key={index}>
            <View key={index} style={{ width: '100%' }}>

              <View row spread paddingB-3 marginB-8 style={{ width: '100%', borderBottomColor: '#d9d9d9', borderBottomWidth: 1, borderStyle: 'dashed' }}>
                <Text h1>{formattedTitle(key)}</Text>

                <View row>
                  {/* all checkbox so that all sub permissions are checked */}
                  <CustomCheckbox
                    value={Object.keys(permissions[key]).every((subKey) => permissions[key][subKey])}
                    indeterminate={Object.keys(permissions[key]).some((subKey) => permissions[key][subKey])}
                    onChange={(value) => {
                      const newPermissions = { ...permissions };
                      newPermissions[key] = { ...newPermissions[key] };
                      Object.keys(newPermissions[key]).forEach((subKey) => {
                        newPermissions[key][subKey] = value;
                      });
                      setFormObject({
                        permissions: newPermissions
                      });
                    }} />
                </View>
              </View>

              {Object.keys(permissions[key]).map((subKey, subindex) => {
                return <View row key={subindex}>
                  <View marginT-3>
                    <CustomCheckbox value={permissions[key][subKey]} onChange={(value) => {
                      const newPermissions = { ...permissions };
                      newPermissions[key] = { ...newPermissions[key], [subKey]: value };
                      setFormObject({
                        permissions: newPermissions
                      });
                    }} />
                  </View>
                  <Text>{formattedText(subKey)}</Text>
                </View>
              })}
            </View>
          </Col>
        })}
      </Row>
    </View >
  }

  const formFields = [
    {
      type: 'divider',
      heading: 'Role Details',
      visible: true,
      span: 24,
    },
    {
      label: 'Name',
      key: 'name',
      type: 'text',
      placeholder: 'Enter role Name',
      visible: true,
      width: '25%',
      required: true,
    },

    {
      width: '100%',
      key: 'permissions',
      type: 'custom',
      visible: true,
      component: PermissionsComponent,
    }
  ];

  const CustomFields = response.CustomFields;

  if (CustomFields && CustomFields.length > 0) {
    CustomFields.forEach((field) => {
      formFields.push({
        label: field.name,
        key: 'cf.' + field.key,
        type: field.type,
        placeholder: field.placeholder,
        visible: true,
        width: '25%'
      });
    });
  }

  return formFields;
}

export const roleMobileCard = (item, index, getFieldValue) => {
  return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
    <View>
      <Text>TEST</Text>
      <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
    </View>
  </Card>;
}

export const roleMain = {
  fields: rolefields,
  mobileCard: null
}