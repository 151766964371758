// 
import { observer } from "mobx-react-lite"
import React, { useRef, FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, TouchableOpacity } from 'react-native-ui-lib';
import DynamicForm, { Col, CustomModal, CustomNumberInput, CustomSelect, DangerButton, Label, PrimaryButton, Row, SecondaryButton } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../mycomponents/state"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import LayoutScreen from "../../../../../mycomponents/CRUD/LayoutScreen"
import FormScreen from "../../../../../mycomponents/CRUD/FormScreen"
import {
    LineChart,
    BarChart,
    PieChart,
    ProgressChart,
    ContributionGraph,
    StackedBarChart
} from "react-native-chart-kit";
import { QueryBuilder } from 'react-querybuilder';
import { QueryBuilderNative } from '@react-querybuilder/native';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { QRCodeSVG } from 'qrcode.react';
import Barcode from 'react-barcode';
import { App } from 'antd';

class Barcodes extends React.PureComponent {
    constructor(props) {
        super(props);
    }



    render() {
        return <div id="printable" style={{ margin: 20 }}>
            <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
                {this.props.pendingSerialNumbers.map((serialNumber, index) => {
                    return Array.from({ length: this.props.noOfCopies }, (_, i) => {
                        return (
                            <div
                                key={serialNumber.serial_number + i}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: this.props.widthPercentage + '%'
                                }}
                            >
                                <div
                                    style={{
                                        height: this.props.height + 'px',
                                        width: '100%',
                                        border: '1px dotted #ccc',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'column'
                                    }}
                                >
                                    {this.props.barcodeType == 'QR Code' ? <QRCodeSVG value={serialNumber.serial_number} /> :
                                        <Barcode value={serialNumber.serial_number} format={this.props.barcodeType} />}
                                    <Text>{serialNumber.serial_number}</Text>
                                </div>
                            </div>
                        );
                    });
                })}
            </div>
        </div>;
    }
}

const BarcodePrintingScreen = observer(function BarcodePrintingScreen(props) {
    let navigation = useNavigation();
    let navigate = null;

    const [tabs, setTabs] = useState([
        { label: "Print Pending Serial Numbers", value: "printSerialNumbers" },
        { label: "Print Pending Batch Numbers", value: "printBatchNumbers" },
        // { label: "Print SKU", value: "printSKU" },
    ]);
    const [tabFilter, setTabFilter] = useState("printSerialNumbers");

    const [height, setHeight] = useState(150);
    const [widthPercentage, setWidthPercentage] = useState(50);
    const [noOfCopies, setNoOfCopies] = useState(1);
    const [barcodeType, setBarcodeType] = useState("QR Code");
    const { message } = App.useApp();

    const barcodeTypes = [
        { label: "QR Code", value: "QR Code" },
        { label: "CODE128", value: "CODE128" },
        { label: "CODE39", value: "CODE39" },
        { label: "EAN13", value: "EAN13" },
        { label: "EAN8", value: "EAN8" },
        { label: "UPCA", value: "UPCA" },
        { label: "UPCE", value: "UPCE" },
    ];

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const [pendingSerialNumbers, setPendingSerialNumbers] = useState([]);
    const [pendingBatchNumbers, setPendingBatchNumbers] = useState([]);

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.navigate("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.navigate(path, params);
            }
        }
    };

    const getPendingPrintNumbers = async () => {
        let response = await api.customRoute("SerialNumbers/action", {
            action: 'getPendingPrintNumbers',
        });

        setPendingSerialNumbers(response.data);
    }

    const markNumbersAsPrinted = async () => {
        let response = await api.customRoute("SerialNumbers/action", {
            action: 'markNumbersAsPrinted',
        });

        if (Platform.OS === 'web') {
            message.success("Serial numbers marked as printed!")
        } else {
            Alert.alert("Serial numbers marked as printed!")
        }

        getPendingPrintNumbers();
    }

    const getPendingPrintBatchNumbers = async () => {
        let response = await api.customRoute("BatchNumbers/action", {
            action: 'getPendingPrintBatchNumbers',
        });

        setPendingBatchNumbers(response.data);
    }

    const markBatchNumbersAsPrinted = async () => {
        await api.customRoute("BatchNumbers/action", {
            action: 'markBatchNumbersAsPrinted',
        });

        if (Platform.OS === 'web') {
            message.success("Batch numbers marked as printed!")
        } else {
            Alert.alert("Batch numbers marked as printed!")
        }

        getPendingPrintBatchNumbers();
    }

    useEffect(() => {
        getPendingPrintNumbers();
        getPendingPrintBatchNumbers();
    }, []);

    return (
        <LayoutScreen>
            <View flex>
                <ScrollView style={{
                    height: Dimensions.get('window').height - 200
                }}>
                    <Card margin-20>
                        <View padding-15 margin-10 marginB-0 style={{ borderRadius: 10, borderBottomEndRadius: 0, borderBottomStartRadius: 0 }}>
                            <View spread row>
                                <Text h1>Barcode Printing</Text>
                                <View row>

                                </View>
                            </View>
                        </View>
                        <View marginL-10>
                            <ScrollView horizontal showsHorizontalScrollIndicator={false}>
                                <View style={
                                    { flexDirection: 'row', alignItems: 'center', flex: 1 }
                                }>
                                    {tabs.map((tab, index) => (
                                        <TouchableOpacity
                                            key={index.toString()}
                                            style={[
                                                tabFilter == tab.value ?
                                                    { padding: 10, borderBottomColor: 'rgb(17, 104, 191)', borderBottomWidth: 3 } :
                                                    { padding: 10 }
                                            ]}
                                            onPress={() => {
                                                setTabFilter(tab.value);
                                            }}
                                        >
                                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                <View style={{ marginLeft: 5 }}>
                                                    <Text style={{ color: tabFilter == tab.value ? 'rgb(44, 126, 208)' : 'black' }}>{tab.label}</Text>
                                                </View>
                                            </View>
                                        </TouchableOpacity>
                                    ))}
                                </View>
                            </ScrollView>

                            {tabFilter == "printSerialNumbers" && (<View padding-15>
                                <Row>
                                    <Col width="25%">
                                        {Label({ label: "Height (px)" })}
                                        <CustomNumberInput value={height} onChange={(value) => {
                                            setHeight(value);
                                        }} />
                                    </Col>
                                    <Col width="25%">
                                        {Label({ label: "Width Percentage (%)" })}
                                        <CustomNumberInput value={widthPercentage} onChange={(value) => {
                                            setWidthPercentage(value);
                                        }} />
                                    </Col>
                                    <Col width="25%">
                                        {Label({ label: "No of Copies" })}
                                        <CustomNumberInput value={noOfCopies} onChange={(value) => {
                                            setNoOfCopies(value);
                                        }} />
                                    </Col>
                                    <Col width="25%">
                                        {Label({ label: "Barcode Type" })}
                                        <CustomSelect value={barcodeType} onChange={(value) => {
                                            setBarcodeType(value);
                                        }} options={barcodeTypes} />
                                    </Col>
                                </Row>

                                <Barcodes pendingSerialNumbers={pendingSerialNumbers} widthPercentage={widthPercentage}
                                    height={height} ref={componentRef} noOfCopies={noOfCopies} barcodeType={barcodeType} />

                                {pendingSerialNumbers.length > 0 ? <View row centerV>
                                    <PrimaryButton label="Print" onPress={() => { handlePrint() }} />
                                    <SecondaryButton style={{
                                        marginLeft: 10
                                    }} label="Mark As Printed" onPress={markNumbersAsPrinted} />
                                </View> : <View center style={{
                                    height: 150,
                                    width: '100%'
                                }}>
                                    <Text>No serial numbers available for printing!</Text>
                                </View>}
                            </View>)}

                            {tabFilter == "printBatchNumbers" && (<View padding-15>
                                <Row>
                                    <Col width="25%">
                                        {Label({ label: "Height (px)" })}
                                        <CustomNumberInput value={height} onChange={(value) => {
                                            setHeight(value);
                                        }} />
                                    </Col>
                                    <Col width="25%">
                                        {Label({ label: "Width Percentage (%)" })}
                                        <CustomNumberInput value={widthPercentage} onChange={(value) => {
                                            setWidthPercentage(value);
                                        }} />
                                    </Col>
                                    <Col width="25%">
                                        {Label({ label: "No of Copies" })}
                                        <CustomNumberInput value={noOfCopies} onChange={(value) => {
                                            setNoOfCopies(value);
                                        }} />
                                    </Col>
                                    <Col width="25%">
                                        {Label({ label: "Barcode Type" })}
                                        <CustomSelect value={barcodeType} onChange={(value) => {
                                            setBarcodeType(value);
                                        }} options={barcodeTypes} />
                                    </Col>
                                </Row>

                                <Barcodes pendingSerialNumbers={pendingBatchNumbers} widthPercentage={widthPercentage}
                                    height={height} ref={componentRef} noOfCopies={noOfCopies} barcodeType={barcodeType} />

                                {pendingBatchNumbers.length > 0 ? <View row centerV>
                                    <PrimaryButton label="Print" onPress={() => { handlePrint() }} />
                                    <SecondaryButton style={{
                                        marginLeft: 10
                                    }} label="Mark As Printed" onPress={markBatchNumbersAsPrinted} />
                                </View> : <View center style={{
                                    height: 150,
                                    width: '100%'
                                }}>
                                    <Text>No batch numbers available for printing!</Text>
                                </View>}
                            </View>)}
                        </View>
                    </Card>
                </ScrollView>
            </View>
        </LayoutScreen>
    )
})

export default BarcodePrintingScreen

const $container = {
    flex: 1,
}