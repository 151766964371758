import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";
import { addButton } from "../hrms/shiftModel";
// import { GrapesjsReact } from 'grapesjs-react';
// import 'grapesjs/dist/css/grapes.min.css';
// import 'grapesjs-preset-webpage';
// import webpageplugin from 'grapesjs-preset-webpage';
// import blocksplugin from 'grapesjs-blocks-basic';
import { Dimensions, Platform } from "react-native";
import { Col, CustomNumberInput, CustomSelect, CustomTextField, DangerButton, Label, Row } from "../../mycomponents/DynamicForm";

export const itemfields = (response, view = false, moduleName = null, OpenModalCustom) => {

    const batchNumbersColumns = [
        {
            "field": "batch_number",
            "label": "Batch Number",
            "editable": true,
            "type": "text",
            "visible": true,
            "order": 1
        },
        {
            "field": "expiry_date",
            "label": "Expiry Date",
            "editable": true,
            "type": "date",
            "visible": true,
            "order": 2
        }, {
            "field": "quantity",
            "label": "Quantity",
            "editable": true,
            "type": "number",
            "visible": true,
            "order": 3
        }
    ];

    batchNumbersColumns && batchNumbersColumns.map((column) => {
        column.Header = column.label;
        column.accessor = column.field;
    });

    const SubscriptionComponent = ({ field, useForm, tKey, tIndex }) => {
        const subscription_plans = useForm((state) => state.formObject['subscription_plans']);
        const setFormObject = useForm((state) => state.setFormObject);
        const getFormObject = useForm((state) => state.getFormObject);

        return <>
            <View flex>
                {subscription_plans && subscription_plans.map((subscription_plan, index) => {
                    return <Row key={index} style={{ marginBottom: index + 1 != subscription_plans.length ? 10 : 0, alignItems: 'center' }} >
                        <Col style={{ marginBottom: 0 }} width={Platform.OS != 'web' ? '100%' : '25%'}>
                            <View centerV style={{ width: '100%' }}>
                                {Label({ label: 'Subsciption Type' })}
                                <CustomTextField value={subscription_plan.name} onChange={(value) => {
                                    const newObject = { ...getFormObject() };
                                    const newArray = [...newObject.subscription_plans];
                                    newArray[index].name = value;
                                    newObject.subscription_plans = newArray;
                                    setFormObject(newObject);
                                }} disabled={true} />
                            </View>
                        </Col>
                        <Col style={{ marginBottom: 0 }} width={Platform.OS != 'web' ? '100%' : '25%'}>
                            <View centerV style={{ width: '100%' }}>
                                {Label({ label: "Amount" })}
                                <CustomNumberInput value={subscription_plan.amount} onChange={(value) => {
                                    const newObject = { ...getFormObject() };
                                    const newArray = [...newObject.subscription_plans];
                                    newArray[index].amount = value;
                                    newObject.subscription_plans = newArray;
                                    setFormObject(newObject);
                                }} />
                            </View>
                        </Col>
                        <Col style={{ marginBottom: 0 }} width={Platform.OS != 'web' ? '100%' : '25%'}>
                            <View centerV style={{ width: '100%' }}>
                                {Label({ label: "Hide From User" })}
                                <CustomSelect value={subscription_plan.hide_from_user} onChange={(value) => {
                                    const newObject = { ...getFormObject() };
                                    const newArray = [...newObject.subscription_plans];
                                    newArray[index].hide_from_user = value;
                                    newObject.subscription_plans = newArray;
                                    setFormObject(newObject);
                                }} options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]} />
                            </View>
                        </Col>

                        {/* <View style={{ marginTop: 20 }}>
                            <DangerButton label="Delete" onPress={() => {
                                const newObject = { ...getFormObject() };
                                const newArray = [...newObject.subscription_plans];
                                newArray.splice(index, 1);
                                newObject.subscription_plans = newArray;
                                setFormObject(newObject);
                            }} />
                        </View> */}
                    </Row>
                })}
            </View>
        </>
    }

    const formFields = [
        {
            label: 'Product Type',
            key: 'product_type',
            type: 'select',
            placeholder: 'Select the product type',
            visible: false,
            width: '25%',
            options: [
                { label: 'Goods', value: 'Goods' },
                { label: 'Service', value: 'Service' },
                { label: 'Subscription', value: 'Subscription' },
                { label: 'Rental', value: 'Rental' },
            ],
            tab: 'General',
            viewField: true,
            value: 'Subscription'
        },
        {
            label: 'Featured Image',
            key: 'image',
            type: 'upload',
            placeholder: 'Enter the image of the item',
            visible: true,
            tab: 'General',
        },
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter the name of the item',
            visible: true,
            width: '25%',
            tab: 'General',
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                const sku = value.replace(/[^a-zA-Z0-9-_]/g, '').toUpperCase();
                setObj({ sku: sku });
            }
        },
        {
            label: 'Training Type',
            key: 'training_type',
            type: 'select',
            placeholder: 'Select the training type',
            visible: true,
            width: '25%',
            options: [
                { label: 'Online Training', value: 'Online Training' },
                { label: 'General Training', value: 'General Training' },
                { label: 'Personal Training', value: 'Personal Training' },
            ],
            tab: 'General',
        },
        // {
        //     label: 'Sales Rate',
        //     key: 'sales_rate',
        //     type: 'number',
        //     placeholder: 'Enter the sales rate',
        //     visible: true,
        //     width: '25%',
        //     tab: 'General'
        // },
        {
            label: 'SKU',
            key: 'sku',
            type: 'text',
            placeholder: 'Enter the SKU of the item',
            visible: false,
            width: '25%',
            tab: 'General',
        },

        {
            'label': 'Subscriptions Plans',
            'key': 'subscription_plans',
            'type': 'custom',
            'placeholder': 'Select the subscriptions',
            'visible': true,
            'width': '100%',
            'tab': 'General',
            'component': SubscriptionComponent,
            value: [{
                name: 'Monthly',
                amount: 0,
                hide_from_user: 'No',
            }, {
                name: 'Quarterly',
                amount: 0,
                hide_from_user: 'No',
            }, {
                name: 'Half Yearly',
                amount: 0,
                hide_from_user: 'No',
            }, {
                name: 'Yearly',
                amount: 0,
                hide_from_user: 'No',
            }]
        },

        {
            label: 'Description',
            key: 'description',
            type: 'editor',
            placeholder: 'Enter the description of the item',
            visible: true,
            width: '100%',
            tab: 'General'
        },
        {
            label: 'HSN/SAC',
            key: 'hsn_or_sac',
            type: 'text',
            placeholder: 'Enter the HSN/SAC',
            visible: false,
            width: '25%',
            tab: 'General'
        },
        {
            label: 'Unit',
            key: 'unit_id',
            type: 'select',
            placeholder: 'Enter the unit of the item',
            visible: false,
            width: '25%',
            tab: 'General',
            value: '',
            options: response.units || [],
            creatableAction: () => {
                OpenModalCustom({ newModule: "Units", field_key: 'unit_id' })
            }
        },
        {
            label: 'Brand',
            key: 'brand_id',
            type: 'select',
            placeholder: 'Enter the brand of the item',
            visible: false,
            width: '25%',
            tab: 'General',
            options: response.brands || [],
            creatableAction: () => {
                OpenModalCustom({ newModule: "Brands", field_key: 'brand_id' })
            },
        },
        {
            label: 'Item Category',
            key: 'item_category_id',
            type: 'select',
            placeholder: 'Select the item category',
            visible: false,
            width: '25%',
            options: response.itemCategories || [],
            tab: 'General',
            creatableAction: () => {
                OpenModalCustom({ newModule: "Categories", field_key: 'item_category_id' })
            },
        },
        {
            label: 'Is Taxable',
            key: 'is_taxable',
            type: 'switch',
            placeholder: 'Enter the is taxable',
            visible: false,
            width: '25%',
            tab: 'General',
            value: false
        },
        // {
        //     label: 'Tax Exemption',
        //     key: 'tax_exemption_id',
        //     type: 'select',
        //     placeholder: 'Select the tax exemption',
        //     visible: (connectedObject) => connectedObject.is_taxable != true,
        //     width: '25%',
        //     options: response.taxExemptions || [],
        //     tab: 'General',
        //     creatableAction: () => {
        //         OpenModalCustom({ newModule: "TaxExemptions", field_key: 'tax_exemption_id' })
        //     }
        // },
        // {
        //     label: 'Tax Group',
        //     key: 'tax_group_id',
        //     type: 'select',
        //     placeholder: 'Select the tax group',
        //     visible: (connectedObject) => connectedObject.is_taxable == true,
        //     width: '25%',
        //     options: response.taxGroups || [],
        //     tab: 'General',
        //     creatableAction: () => {
        //         OpenModalCustom({ newModule: "TaxGroups", field_key: 'tax_group_id' })
        //     }
        // },

        // {
        //     label: 'Sales Account',
        //     key: 'sales_account_id',
        //     type: 'select',
        //     placeholder: 'Select the sales account',
        //     visible: true,
        //     width: '25%',
        //     options: response.salesAccounts || [],
        //     tab: 'General',
        //     creatableAction: () => {
        //         const params = { "is_default_values": true, "type": "Sales", "base_type": "Income", "opening_balance": 0 };
        //         OpenModalCustom({ newModule: "Accounts", field_key: 'sales_account_id', params })
        //     }
        // },
        // {
        //     label: 'Purchase Rate',
        //     key: 'purchase_rate',
        //     type: 'number',
        //     placeholder: 'Enter the purchase rate',
        //     visible: true,
        //     width: '25%',
        //     tab: 'General'
        // },
        // {
        //     label: 'Purchase Account',
        //     key: 'purchase_account_id',
        //     type: 'select',
        //     placeholder: 'Select the purchase account',
        //     visible: true,
        //     width: '25%',
        //     options: response.purchasesAccounts || [],
        //     tab: 'General',
        //     creatableAction: () => {
        //         OpenModalCustom({ newModule: "Accounts", field_key: 'purchase_account_id' })
        //     }
        // },
        // {
        //     label: 'Track Inventory',
        //     key: 'track_inventory',
        //     type: 'switch',
        //     placeholder: 'Enter the track inventory',
        //     visible: true,
        //     width: '100%',
        //     tab: 'Inventory Details',
        //     onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
        //         setObj({ updateVisiblity: Math.random() })
        //     }
        // },
        // {
        //     label: 'Inventory Account',
        //     key: 'inventory_account_id',
        //     type: 'select',
        //     placeholder: 'Select the inventory account',
        //     visible: (connectedObject) => connectedObject.track_inventory == true,
        //     width: '25%',
        //     options: response.inventoryAccounts || [],
        //     tab: 'Inventory Details',
        //     creatableAction: () => {
        //         OpenModalCustom({ newModule: "Accounts", field_key: 'inventory_account_id' })
        //     }
        // },
        // {
        //     label: 'Initial Stock',
        //     key: 'initial_stock',
        //     type: 'number',
        //     placeholder: 'Enter the initial stock',
        //     visible: (connectedObject) => connectedObject.track_inventory == true,
        //     width: '25%',
        //     tab: 'Inventory Details'
        // },
        // {
        //     label: 'Initial Stock Rate',
        //     key: 'initial_stock_rate',
        //     type: 'number',
        //     placeholder: 'Enter the initial stock rate',
        //     visible: (connectedObject) => connectedObject.track_inventory == true,
        //     width: '25%',
        //     tab: 'Inventory Details'
        // },
        // {
        //     label: 'Reorder Level',
        //     key: 'reorder_level',
        //     type: 'number',
        //     placeholder: 'Enter the reorder level',
        //     visible: (connectedObject) => connectedObject.track_inventory == true,
        //     width: '25%',
        //     tab: 'Inventory Details'
        // },
        // {
        //     label: 'Quantity In Hand',
        //     key: 'quantity_in_hand',
        //     type: 'number',
        //     placeholder: 'Enter the quantity in hand',
        //     visible: false,
        //     width: '25%',
        //     tab: 'Inventory Details'
        // },
        // {
        //     label: 'Track Serial Number',
        //     key: 'track_serial_number',
        //     type: 'switch',
        //     placeholder: 'Enter the track serial number',
        //     visible: (connectedObject) => connectedObject.track_inventory == true,
        //     width: '100%',
        //     tab: 'Inventory Details',
        //     onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
        //         if (value == true) {
        //             setObj({
        //                 track_batch_number: false,
        //                 updateVisiblity: Math.random(),
        //             })
        //         } else {
        //             setObj({
        //                 initital_serial_numbers: [],
        //                 updateVisiblity: Math.random(),
        //             })
        //         }
        //     }
        // },
        // {
        //     label: 'Serial Numbers',
        //     key: 'initital_serial_numbers',
        //     type: 'tags_text_area',
        //     placeholder: 'Enter the track serial number',
        //     visible: (connectedObject) => connectedObject.track_inventory == true && connectedObject.track_serial_number == true,
        //     width: '100%',
        //     tab: 'Inventory Details',
        //     value: []
        // },
        // {
        //     label: 'Track Batch Number',
        //     key: 'track_batch_number',
        //     type: 'switch',
        //     placeholder: 'Enter the track batch number',
        //     visible: (connectedObject) => connectedObject.track_inventory == true,
        //     width: '100%',
        //     tab: 'Inventory Details',
        //     onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
        //         if (value == true) {
        //             setObj({
        //                 track_serial_number: false,
        //                 updateVisiblity: Math.random(),
        //             })
        //         } else {
        //             setObj({
        //                 initital_batches: [],
        //                 updateVisiblity: Math.random(),
        //             })
        //         }
        //     }
        // },
        // {
        //     label: 'Batch Numbers',
        //     key: 'initital_batches',
        //     type: 'table',
        //     placeholder: 'Enter the batch numbers',
        //     visible: (connectedObject) => connectedObject.track_inventory == true && connectedObject.track_batch_number == true,
        //     width: '100%',
        //     tab: 'Inventory Details',
        //     editable: true,
        //     columns: batchNumbersColumns,
        //     actions: true,
        //     actionFunctions: (row) => ([{
        //         label: 'Delete',
        //         key: 'delete',
        //         fn: ({ data, updateData, row, row_index }) => {
        //             let newData = [...data];
        //             newData.splice(row_index, 1);
        //             updateData(newData);
        //         }
        //     }]),
        //     value: []
        // },
        // addButton("Add Batch Number", "add_batch_number", "initital_batches", {}, "Inventory Details", (connectedObject) => connectedObject.track_inventory == true && connectedObject.track_batch_number == true),
        // {
        //     label: 'Is Combo Product',
        //     key: 'is_combo_product',
        //     type: 'switch',
        //     placeholder: 'Enter the is combo product',
        //     visible: (connectedObject) => connectedObject.track_inventory == true,
        //     width: '100%',
        //     tab: 'Inventory Details',
        //     onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
        //         if (value == true) {
        //             setObj({
        //                 mapped_items: [],
        //                 updateVisiblity: Math.random(),
        //             })
        //         } else {
        //             setObj({
        //                 mapped_items: [],
        //                 updateVisiblity: Math.random(),
        //             })
        //         }
        //     }
        // },
        // {
        //     label: 'Mapped Items',
        //     key: 'mapped_items',
        //     type: 'table',
        //     placeholder: 'Select the mapped items',
        //     visible: (connectedObject) => connectedObject.track_inventory == true && connectedObject.is_combo_product == true,
        //     width: '100%',
        //     tab: 'Inventory Details',
        //     editable: true,
        //     columns: [{
        //         "field": "item_id",
        //         "label": "Item",
        //         "editable": true,
        //         "type": "select",
        //         "visible": true,
        //         "order": 1,
        //         "Header": "Item",
        //         "accessor": "item_id",
        //         "placeholder": "Select the item",
        //         "options": response.other_items || [],
        //     }, {
        //         "field": "quantity",
        //         "label": "Quantity",
        //         "editable": true,
        //         "type": "number",
        //         "visible": true,
        //         "order": 2,
        //         "Header": "Quantity",
        //         "accessor": "quantity",
        //         "placeholder": "Enter the quantity",
        //     }],
        //     actions: true,
        //     actionFunctions: (row) => ([{
        //         label: 'Delete',
        //         key: 'delete',
        //         fn: ({ data, updateData, row, row_index }) => {
        //             let newData = [...data];
        //             newData.splice(row_index, 1);
        //             updateData(newData);
        //         }
        //     }]),
        //     value: [],
        // },
        // addButton("Add Mapped Item", "add_mapped_item", "mapped_items", {}, "Inventory Details", (connectedObject) => connectedObject.track_inventory == true && connectedObject.is_combo_product == true),
        // {
        //     label: 'Status',
        //     key: 'status',
        //     type: 'select',
        //     placeholder: 'Select the status',
        //     visible: true,
        //     width: '25%',
        //     options: [
        //         { label: 'Active', value: 'ACTIVE' },
        //         { label: 'Inactive', value: 'INACTIVE' },
        //     ],
        //     tab: 'General'
        // },
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                tab: 'General 2'
            });
        });
    }

    const defaults = response.defaults;

    if (defaults) {
        for (let key in defaults) {
            const field = formFields.find(field => field.key == key);
            if (field) {
                field.value = defaults[key];
            }
        }
    }

    return formFields;
}

export const itemMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View row spread style={{ width: '100%' }}>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
            <View style={{ backgroundColor: 'red', borderRadius: 6, paddingHorizontal: 10, paddingVertical: 5 }}>
                <Text style={{ marginBottom: 5, color: 'white' }}>{getFieldValue(item, 'sku') ? "" + getFieldValue(item, 'sku') : ""}</Text>
            </View>
        </View>
        {/* 1 px dashed line */}
        <View style={{ width: '100%', height: 1, backgroundColor: '#000', borderStyle: 'dashed', borderWidth: 1, borderColor: '#000', marginBottom: 5 }} />
        <View row spread style={{ width: '100%' }}>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'sales_rate') ? "Price333: " + getFieldValue(item, 'sales_rate') : ""}</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'quantity_in_hand') ? "Quantity: " + getFieldValue(item, 'quantity_in_hand') : ""}</Text>
        </View>
    </Card>;
}

export const gypPlansMain = {
    fields: itemfields,
    mobileCard: null,
    tabs: [{
        label: 'All',
        key: 'All',
        value: {}
    }, {
        label: 'Goods',
        key: 'Goods',
        value: {
            product_type: 'Goods',
        }
    }, {
        label: 'Services',
        key: 'Services',
        value: {
            product_type: 'Services',
        }
    }, {
        label: 'Memberships',
        key: 'Memberships',
        value: {
            product_type: 'Memberships',
        }
    }, {
        label: 'Packages',
        key: 'Packages',
        value: {
            product_type: 'Packages',
        }
    }, {
        label: 'Vehicles',
        key: 'Vehicles',
        value: {
            product_type: 'Vehicles',
        }
    }]
}