import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";

export const serialNumberfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
    const formFields = [
        {
            label: 'Serial Number',
            key: 'serial_number',
            type: 'text',
            placeholder: 'Enter the serial number',
            visible: true,
            width: '25%',
        },
        {
            label: 'Item',
            key: 'item_id',
            type: 'select',
            placeholder: 'Select the item',
            visible: true,
            width: '25%',
            options: response.items || [],
        },
        {
            label: 'Location',
            key: 'location_id',
            type: 'select',
            placeholder: 'Select the location',
            visible: true,
            width: '25%',
            options: response.locations || [],
        },
        {
            label: 'Price',
            key: 'price',
            type: 'number',
            placeholder: 'Enter the price',
            visible: true,
            width: '25%',
        },
        {
            label: 'Active',
            key: 'active',
            type: 'checkbox',
            visible: true,
            width: '25%',
        },
        {
            label: 'Is Opening',
            key: 'isOpening',
            type: 'checkbox',
            visible: true,
            width: '25%',
        },
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                viewField: true,
            });
        });
    }

    return formFields;
}

export const serialNumberMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const serialNumberMain = {
    fields: serialNumberfields,
    mobileCard: null
}




