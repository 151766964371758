// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, TouchableOpacity, KeyboardAwareScrollView } from 'react-native-ui-lib';
import DynamicForm, { CustomModal, CustomNumberInput, CustomTextField, DangerButton, Label, PrimaryButton } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../mycomponents/state"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import LayoutScreen from "../../../../../mycomponents/CRUD/LayoutScreen"
import FormScreen from "../../../../../mycomponents/CRUD/FormScreen"
import {
    LineChart,
    BarChart,
    PieChart,
    ProgressChart,
    ContributionGraph,
    StackedBarChart
} from "react-native-chart-kit";
import { App } from "antd"
import { GlobalMasterStore } from "../../../../../mycomponents/state/PermissionStore"
import AsyncStorage from "@react-native-async-storage/async-storage"

const LoginScreen = observer(function LoginScreen(props) {
    let navigation = useNavigation();
    let navigate = null;
    const { message, modal } = App.useApp();
    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const permissions = GlobalMasterStore((state) => state.permissions);
    const setPermissions = GlobalMasterStore((state) => state.setPermissions);

    const token = GlobalMasterStore((state) => state.token);
    const setToken = GlobalMasterStore((state) => state.setToken);

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.navigate("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.navigate(path, params);
            }
        }
    };

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
    const [sendingOTP, setSendingOTP] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [forgotEmail, setForgotEmail] = useState("");
    const [forgotOTP, setForgotOTP] = useState("");
    const [forgotPassword, setForgotPassword] = useState("");
    const setGlobalData = GlobalMasterStore((state) => state.setGlobalData);

    return (<View center style={{
        backgroundColor: "#fff",
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height,
    }}>
        <SafeAreaView style={{
            width: "100%",
            height: Dimensions.get('window').height,
        }}>
            <KeyboardAwareScrollView style={{
                width: "100%",
                height: Dimensions.get('window').height,
            }}>
                <View centerH centerV={Platform.OS == 'web'} flexG padding-20
                    style={{
                        width: "100%",
                        height: Platform.OS == 'web' ? Dimensions.get('window').height : undefined,
                    }}
                >
                    <View centerH style={{
                        width: "100%",
                        maxWidth: 500,
                    }}>
                        <View style={{
                            width: "100%",
                        }}>
                            {Label({ label: "Email" })}
                            <CustomTextField value={email} onChange={(value) => {
                                setEmail(value);
                            }} />
                        </View>
                        <View marginT-5 style={{
                            width: "100%",
                        }}>
                            {Label({ label: "Password" })}
                            <CustomTextField value={password} onChange={(value) => {
                                setPassword(value);
                            }} />
                        </View>
                        <View marginT-10 style={{
                            width: "100%",
                        }}>
                            <PrimaryButton label="Login" onPress={async () => {
                                const res = await api.login({
                                    email,
                                    password
                                });

                                if (res.message) {
                                    if (Platform.OS === 'web') {
                                        message.success(res.message);
                                    } else {
                                        Alert.alert(res.message);
                                    }

                                    if (res.success) {
                                        await AsyncStorage.setItem('token', res.token);
                                        const user_id = res.user._id;
                                        const organizations = res.user.organizations;

                                        api.setTokenToAPI(res.token, organizations[0].id);

                                        setToken(res.token);
                                        setGlobalData({
                                            user_id: user_id,
                                            organizations: organizations,
                                            organization_id: organizations[0].id,
                                            employee_id: organizations[0].employee_id,
                                            // user: res.user,
                                        })

                                        navigationFn("dashboard");

                                        setTimeout(() => {
                                            window.location.reload();
                                        }, 100);
                                    }
                                } else {
                                    if (Platform.OS === 'web') {
                                        message.success("Something went wrong");
                                    } else {
                                        Alert.alert("Something went wrong");
                                    }
                                }
                            }} />
                        </View>
                        <View center marginT-10>
                            <TouchableOpacity onPress={() => {
                                setShowForgotPasswordModal(true);
                            }}>
                                <Text monoRegular style={{
                                    fontSize: 12,
                                    color: 'rgb(146, 146, 146)'
                                }}>Forgot Password</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </KeyboardAwareScrollView>
        </SafeAreaView>

        <CustomModal width={'500px'} visible={showForgotPasswordModal} onClose={() => {
            setShowForgotPasswordModal(false);
        }}>
            <View center>
                <View centerH style={{
                    width: "100%",
                    maxWidth: 500,
                }}>
                    <View style={{
                        width: "100%",
                    }}>
                        {Label({ label: "Email" })}
                        <CustomTextField value={forgotEmail} onChange={(value) => {
                            setForgotEmail(value);
                        }} />
                    </View>
                    {!otpSent && <View marginT-10 style={{
                        width: "100%",
                    }}>
                        <PrimaryButton label="Send OTP" disabled={sendingOTP} onPress={async () => {
                            setSendingOTP(true);
                            const res = await api.customRoute("forgot-password", {
                                email: forgotEmail,
                            });

                            if (res.message) {
                                if (Platform.OS === 'web') {
                                    message.success(res.message);
                                } else {
                                    Alert.alert(res.message);
                                }
                            } else {
                                if (Platform.OS === 'web') {
                                    message.success("Something went wrong");
                                } else {
                                    Alert.alert("Something went wrong");
                                }
                            }
                            setSendingOTP(false);
                            setOtpSent(true);
                        }} />
                    </View>}
                    {otpSent && <View marginT-10 style={{
                        width: "100%",
                    }}>
                        {Label({ label: "OTP" })}
                        <CustomTextField value={forgotOTP} onChange={(value) => {
                            setForgotOTP(value);
                        }} />
                    </View>}
                    {otpSent && <View marginT-10 style={{
                        width: "100%",
                    }}>
                        {Label({ label: "New Password" })}
                        <CustomTextField value={forgotPassword} onChange={(value) => {
                            setForgotPassword(value);
                        }} />
                    </View>}
                    {otpSent && <View marginT-10 style={{
                        width: "100%",
                    }}>
                        <PrimaryButton label="Reset Password" onPress={async () => {
                            const res = await api.customRoute("reset-password", {
                                email: forgotEmail,
                                otp: forgotOTP,
                                password: forgotPassword,
                            })

                            if (res.message) {
                                if (Platform.OS === 'web') {
                                    message.success(res.message);
                                } else {
                                    Alert.alert(res.message);
                                }

                                if (res.success) {
                                    setShowForgotPasswordModal(false);
                                }
                            } else {
                                if (Platform.OS === 'web') {
                                    message.success("Something went wrong");
                                } else {
                                    Alert.alert("Something went wrong");
                                }
                            }
                        }} />
                    </View>}
                </View>
            </View>

        </CustomModal>
    </View >)
})

export default LoginScreen

const $container = {
    flex: 1,
}