// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions, TouchableOpacity } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController } from 'react-native-ui-lib';
import DynamicForm, { Col, CustomModal, CustomNumberInput, CustomSelect, CustomTextField, CustomUpload, DangerButton, Label, PrimaryButton, Row } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../mycomponents/state"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import LayoutScreen from "../../../../../mycomponents/CRUD/LayoutScreen"
import FormScreen from "../../../../../mycomponents/CRUD/FormScreen"
import {
    LineChart,
    BarChart,
    PieChart,
    ProgressChart,
    ContributionGraph,
    StackedBarChart
} from "react-native-chart-kit";
import ListingScreen from "../../../../../mycomponents/CRUD/ListingScreen"
import { App } from "antd"
import { GlobalMasterStore } from "../../../../../mycomponents/state/PermissionStore"


const OrganizationSettings = observer(function OrganizationSettings(props) {
    let navigation = useNavigation();
    let navigate = null;
    const employee = GlobalMasterStore((state) => state.employee);

    console.log("employee", employee);

    const [prefs, setPrefs] = useState({});
    const [activeTab, setActiveTab] = useState("General");

    const { message } = App.useApp();

    const [tabs, setTabs] = useState(employee.name == "System Admin" ? [
        {
            label: "General"
        },
        {
            label: "Document Templates"
        },
        {
            label: "Notification Templates"
        },
        {
            label: "Enabled Features"
        }
    ] : [
        {
            label: "General"
        },
        {
            label: "Document Templates"
        },
        {
            label: "Notification Templates"
        },
    ]);

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);

    useEffect(() => {
        api.customRoute("Contacts/action", {
            action: "getCountries"
        }).then((data) => {
            setCountries(data);
        })
    }, [])

    useEffect(() => {
        if (prefs.country) {
            api.customRoute("Contacts/action", {
                action: "getStates",
                country_id: prefs.country
            }).then((data) => {
                setStates(data);
            })
        }
    }, [prefs.country])

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.navigate("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.navigate(path, params);
            }
        }
    };

    const getOrganizationSettings = async () => {
        const data = await api.customRoute("getOrganizationPrefs", {
            module_name: "Organization"
        });

        setPrefs(data);
    };

    useEffect(() => {
        getOrganizationSettings()
    }, [])

    return (
        <LayoutScreen>
            <View flex>
                <ScrollView>
                    <Card margin-20>
                        <View padding-15 margin-10 marginB-0 style={{ backgroundColor: '#f4f7f9', borderRadius: 10, borderBottomEndRadius: 0, borderBottomStartRadius: 0 }}>
                            <View spread row>
                                <Text h1>Organization Settings</Text>
                            </View>
                        </View>
                        <View flex w-100 row>
                            <View flex marginV-0>
                                <View style={{
                                    borderBottomColor: '#d0d3e5d4',
                                    borderBottomWidth: 1,
                                    marginBottom: 0,
                                    marginTop: 0,
                                    borderStyle: 'dashed',
                                    marginHorizontal: 10
                                }} />

                                <View>
                                    <View row margin-10 marginB-0 marginT-0 backgroundColor="rgb(244, 247, 249)">
                                        {tabs.map((tab, tab_index) => (
                                            <TouchableOpacity
                                                key={tab_index.toString()}
                                                style={[
                                                    activeTab == tab?.label ?
                                                        { padding: 10, borderBottomColor: 'rgb(17, 104, 191)', borderBottomWidth: 3 } :
                                                        { padding: 10 }
                                                ]}
                                                onPress={() => {
                                                    setActiveTab(tab.label);
                                                }}
                                            >
                                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                    <View style={{ marginLeft: 5 }}>
                                                        <Text style={
                                                            [activeTab == tab?.label ?
                                                                { color: 'rgb(17, 104, 191)', fontFamily: 'SourceSansProSemiBold', letterSpacing: 0.4 } :
                                                                { color: 'black', fontFamily: 'SourceSansProSemiBold', letterSpacing: 0.4 }
                                                            ]}>{tab.label}</Text>
                                                    </View>
                                                </View>
                                            </TouchableOpacity>
                                        ))}
                                    </View>
                                </View>

                                <View style={{
                                    borderBottomColor: '#e0e3f2bf',
                                    borderBottomWidth: 1,
                                    marginBottom: 0,
                                    marginTop: 0,
                                    borderStyle: 'solid',
                                    marginHorizontal: 10
                                }} />
                            </View>
                        </View>

                        {activeTab == "General" && <View marginL-10 paddingH-15 paddingT-5>
                            <Row>
                                <Col width={Platform.OS === 'web' ? '100%' : '100%'}>
                                    {Label({ label: "Logo" })}
                                    <CustomUpload
                                        mimeType={"image/*"}
                                        moduleName="Invoices"
                                        value={prefs.logo}
                                        onChange={(value) => {
                                            setPrefs({
                                                ...prefs,
                                                logo: value
                                            })
                                        }} />
                                </Col>
                                <Col width={Platform.OS === 'web' ? '25%' : '100%'}>
                                    {Label({ label: "Organization Name" })}
                                    <CustomTextField value={prefs.name} onChange={(value) => {
                                        setPrefs({
                                            ...prefs,
                                            name: value
                                        })
                                    }} />
                                </Col>
                                <Col width={Platform.OS === 'web' ? '25%' : '100%'}>
                                    {Label({ label: "Tax Number" })}
                                    <CustomTextField value={prefs.tax_number} onChange={(value) => {
                                        setPrefs({
                                            ...prefs,
                                            tax_number: value
                                        })
                                    }} />
                                </Col>
                                <Col width={Platform.OS === 'web' ? '25%' : '100%'}>
                                    {Label({ label: "Country" })}
                                    <CustomSelect value={prefs.country} onChange={(value) => {
                                        setPrefs({
                                            ...prefs,
                                            country: value
                                        })
                                    }} options={countries} />
                                </Col>
                                <Col width={Platform.OS === 'web' ? '25%' : '100%'}>
                                    {Label({ label: "State" })}
                                    <CustomSelect value={prefs.state} onChange={(value) => {
                                        setPrefs({
                                            ...prefs,
                                            state: value
                                        })
                                    }} options={states} />
                                </Col>
                                <Col width={Platform.OS === 'web' ? '25%' : '100%'}>
                                    {Label({ label: "Date Format" })}
                                    <CustomSelect value={prefs.date_format} onChange={(value) => {
                                        setPrefs({
                                            ...prefs,
                                            date_format: value
                                        })
                                    }} options={[
                                        {
                                            label: "DD-MM-YYYY",
                                            value: "DD-MM-YYYY"
                                        },
                                        {
                                            label: "MM-DD-YYYY",
                                            value: "MM-DD-YYYY"
                                        },
                                        {
                                            label: "YYYY-MM-DD",
                                            value: "YYYY-MM-DD"
                                        },
                                        {
                                            label: "DD/MM/YYYY",
                                            value: "DD/MM/YYYY"
                                        },
                                        {
                                            label: "MM/DD/YYYY",
                                            value: "MM/DD/YYYY"
                                        },
                                        {
                                            label: "YYYY/MM/DD",
                                            value: "YYYY/MM/DD"
                                        },
                                    ]} />
                                </Col>
                                <Col width={Platform.OS === 'web' ? '25%' : '100%'}>
                                    {Label({ label: "Time Format" })}
                                    <CustomSelect value={prefs.time_format} onChange={(value) => {
                                        setPrefs({
                                            ...prefs,
                                            time_format: value
                                        })
                                    }} options={[
                                        {
                                            label: "12 Hours",
                                            value: "12 Hours"
                                        },
                                        {
                                            label: "24 Hours",
                                            value: "24 Hours"
                                        },
                                    ]} />
                                </Col>
                            </Row>


                            <Row style={{ marginTop: 10 }}>
                                <Col width={Platform.OS === 'web' ? '100%' : '100%'}>
                                    <PrimaryButton label="Save" onPress={async () => {
                                        const data = await api.customRoute("saveOrganizationPrefs", {
                                            module_name: "Organization",
                                            prefs: prefs
                                        });

                                        if (data.message) {
                                            if (Platform.OS === 'web') {
                                                message.success(data.message);
                                            } else {
                                                Alert.alert("Success", "Organization settings saved successfully");
                                            }
                                        } else {
                                            if (Platform.OS === 'web') {
                                                message.error(data.error);
                                            } else {
                                                Alert.alert("Error", data.error);
                                            }
                                        }
                                    }} />
                                </Col>
                            </Row>
                        </View>}

                        {activeTab == "Document Templates" && <View marginL-10 paddingH-15 paddingT-5>
                            <View w-100 flex marginT-10>
                                <ListingScreen {... {
                                    moduleName: 'DocumentTemplates',
                                    hideWrapper: true,
                                }} />
                            </View>
                        </View>}

                        {activeTab == "Notification Templates" && <View marginL-10 paddingH-15 paddingT-5>
                            <View w-100 flex marginT-10>
                                <ListingScreen {... {
                                    moduleName: 'NotificationTemplates',
                                    hideWrapper: true,
                                }} />
                            </View>
                        </View>}

                    </Card>
                </ScrollView>
            </View>
        </LayoutScreen>
    )
})

export default OrganizationSettings

const $container = {
    flex: 1,
}