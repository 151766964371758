import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";

export const fifoProductOutfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
    const formFields = [
        {
            label: 'Date',
            key: 'date',
            type: 'date',
            placeholder: 'Enter the date',
            visible: true,
            width: '25%',
        },
        {
            label: 'Lot No',
            key: 'lot_no',
            type: 'number',
            placeholder: 'Enter the lot number',
            visible: true,
            width: '25%',
        },
        {
            label: 'Transaction Type',
            key: 'transaction_type',
            type: 'text',
            placeholder: 'Enter the transaction type',
            visible: true,
            width: '25%',
        },
        {
            label: 'Transaction ID',
            key: 'transaction_id',
            type: 'select',
            placeholder: 'Select the transaction ID',
            visible: true,
            width: '25%',
            options: response.transactions || [],
        },
        {
            label: 'Transaction Number',
            key: 'transaction_number',
            type: 'text',
            placeholder: 'Enter the transaction number',
            visible: true,
            width: '25%',
        },
        {
            label: 'Contact',
            key: 'contact_id',
            type: 'select',
            placeholder: 'Select the contact',
            visible: true,
            width: '25%',
            options: response.contacts || [],
        },
        {
            label: 'Item',
            key: 'item_id',
            type: 'select',
            placeholder: 'Select the item',
            visible: true,
            width: '25%',
            options: response.items || [],
        },
        {
            label: 'Serial Number',
            key: 'serial_number',
            type: 'text',
            placeholder: 'Enter the serial number',
            visible: true,
            width: '25%',
        },
        {
            label: 'Location',
            key: 'location_id',
            type: 'select',
            placeholder: 'Select the location',
            visible: true,
            width: '25%',
            options: response.locations || [],
        },
        {
            label: 'Location Name',
            key: 'location_name',
            type: 'text',
            placeholder: 'Enter the location name',
            visible: true,
            width: '25%',
        },
        {
            label: 'Quantity Out',
            key: 'quantity_out',
            type: 'number',
            placeholder: 'Enter the quantity out',
            visible: true,
            width: '25%',
        },
        {
            label: 'Selling Price',
            key: 'selling_price',
            type: 'number',
            placeholder: 'Enter the selling price',
            visible: true,
            width: '25%',
        },
        {
            label: 'Entry ID',
            key: 'entry_id',
            type: 'select',
            placeholder: 'Select the entry ID',
            visible: true,
            width: '25%',
            options: response.journals || [],
        },
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                viewField: true,
            });
        });
    }

    return formFields;
}

export const fifoProductOutMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const fifoProductOutMain = {
    fields: fifoProductOutfields,
    mobileCard: null
}
